/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import Breadcrumb from "../../../components/common/breadcrumb";
import { isMobileOnly } from "react-device-detect";
import Datatable from "../../../components/datatable";
import { useUser } from "../../../hooks";
import { USER_CARDS_QUERY } from "../../../graphql";
import currency from "currency.js";

var images = require.context("../../../assets/images", true);

const Cards = () => {
  const me = useUser();

  const [balanceAmount, setBalanceAmount] = React.useState(0);

  const isAdmin =
    me.permissions.includes("ADMIN") || me.permissions.includes("ADJUDICATOR");
  const { loading, data } = useQuery(USER_CARDS_QUERY, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if(!loading && data){
      let initialValue = 0;
     const c =  data.cardApplications.reduce((p, c) => {
        const balance = c.cardBalance;
        const creditAvailable = currency(parseInt(c.cardLimit)).subtract(balance);
        const old = currency(p);
        console.log(creditAvailable)
        return old.add(creditAvailable.value).value

      }, initialValue)
      setBalanceAmount(c);
    }
  }, [data, loading])
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Credit Card Applications" />
      <div className="container-fluid">
        <div className="card cuanimated bounceInLeft" id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
              {" "}
              {!loading && data.cardApplications
                ? "Found " + data.cardApplications.length + " Record(s)"
                : "Loading..."}{" "}
            </h5>
          </div>
          <div className="card-body">
            {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: "inherit" }}
              >
                <span className="rotate dotted" />
              </div>
            ) : data.cardApplications.length > 0 ? (
              <div>
                {isAdmin && "Total available credit:" + balanceAmount}
                 <Datatable
                class="-striped -highlight"
                myData={data.cardApplications.map((app) => {
                  let row = {
                    Date: app.createdAt,
                  };

                  row["ID"] = app.id;

                  if (isAdmin) {
                    row["User"] = app.name;
                  }

                  row["Status"] = app.status;
                  row["Campaign"] = app.campaign;
                  row["Phone"] = app.phone;
                  row["Card Number"] = app.cardNumber ? app.cardNumber : app.tempNumber;
 
                  return row;
                })}
                pageSize={15}
                pagination
                routeUrl="card"
              />
              </div>
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: "300px", marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images("./search-not-found.png")}
                    style={{ marginTop: "-40px" }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Cards;
