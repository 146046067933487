import React from 'react';

import Wizard from './Wizard.js';
import { Breadcrumb } from '../../components';

const Apply = () => {
  return (
    <div className="step-progress">
      <Breadcrumb hideBtn parent="Applications" title="Request Funds" />
      <div className="container-fluid">
        <Wizard />
      </div>
    </div>
  );
};

export default Apply;
