import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import Select from "react-select";
import ReactDatePicker from "./ReactDatePicker";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  LOAN_ADD_PAYMENT,
  USER_LOAN_QUERY,
  USER_LOAN_QUERY_BYID,
  USER_PROFILE_QUERY,
} from "../graphql";

const AddLoanPayment = ({ loan, component: button }) => {
  const { errors, register, handleSubmit, control } = useForm({
    defaultValues: {
      loan: loan.id,
      user: loan.user.id,
    },
  });

  const [modal, toggleModal] = useState(false);
  const [validateClass, setValidateClass] = useState(false);
  const paymentsMade = loan.payments.map(pay => pay.paymentDate);

  const [addPayment, { loading }] = useMutation(LOAN_ADD_PAYMENT);

  const handleAddPayment = async (data) => {
    if (data !== "") {
      try {
        await addPayment({
          variables: {
            loanId: data.loan,
            userId: data.user,
            amount: data.amount,
            status: data.paymentStatus.value,
            paymentDate: data.paymentDate
          },
          refetchQueries: [
            {
              query: USER_LOAN_QUERY,
            },
            {
              query: USER_PROFILE_QUERY,
            },
            {
              query: USER_LOAN_QUERY_BYID,
              variables: {
                id: data.loan,
              },
            },
          ],
        });
        toast.success("Successfully added a payment");
        toggleModal(!modal);
      } catch (error) {
        toast.error(error.message.replace("GraphQL error: ", ""));
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} toggle={toggleModal}>
        <form
          className={`theme-form needs-validation tooltip-validation ${
            validateClass ? "validateClass" : ""
          }`}
          noValidate=""
          onSubmit={handleSubmit(handleAddPayment)}
        >
          <ModalHeader toggle={toggleModal}>Add Payment</ModalHeader>
          <ModalBody>
            {loading && (
              <div className="loader-box loader-form-wrapper">
                <span className="rotate dotted" />
              </div>
            )}
            <fieldset aria-busy={loading} disabled={loading}>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  User ID:
                </label>
                <input
                  className="form-control"
                  disabled
                  name="user"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  Loan ID:
                </label>
                <input
                  className="form-control"
                  disabled
                  name="loan"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  Payment Schedule Date:
                </label>
               <ReactDatePicker
                  control={control}
                  name="paymentDate"
                  placeholderText={
                    "Assign a date to this payment"
                  }
                  rules={{ required: true }}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  Payment Status:
                </label>
                <Controller
                  as={Select}
                  control={control}
                  name="paymentStatus"
                  options={[{
                    value: "made",
                    label: "On Schedule"
                  },
                  {
                    value: "late",
                    label: "Late Payment"
                  }]}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  Amount:
                </label>
                <input
                  className="form-control"
                  name="amount"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setValidateClass(!validateClass)}
              type="submit"
            >
              Submit Payment
            </Button>
            <Button color="secondary" onClick={() => toggleModal(!modal)}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <button onClick={() => toggleModal(!modal)}>Add Payment</button>
    </React.Fragment>
  );
};

export default AddLoanPayment;
