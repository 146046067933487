/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import Breadcrumb from "../../../components/common/breadcrumb";
import { isMobileOnly } from "react-device-detect";
import Datatable from "../../../components/datatable";
import { useUser } from "../../../hooks";
import { ADMIN_CARD_LEADS } from "../../../graphql";

var images = require.context("../../../assets/images", true);

const MastercardLeads = () => {
  const me = useUser();
  const isAdmin =
    me.permissions.includes("ADMIN") || me.permissions.includes("ADJUDICATOR");
  const { loading, data } = useQuery(ADMIN_CARD_LEADS, {
    fetchPolicy: "cache-and-network",
  });
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Credit Card Applications" />
      <div className="container-fluid">
        <div className="card cuanimated bounceInLeft" id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
              {" "}
              {!loading && data.cardLeads
                ? "Found " + data.cardLeads.length + " Record(s)"
                : "Loading..."}{" "}
            </h5>
          </div>
          <div className="card-body">
            {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: "inherit" }}
              >
                <span className="rotate dotted" />
              </div>
            ) : data.cardLeads.length > 0 ? (
              <Datatable
                class="-striped -highlight"
                myData={data.cardLeads.map((app) => {
                  let row = {
                    Date: app.createdAt,
                  };

                  row["ID"] = app.id;

                  if (isAdmin) {
                    row["User"] = app.name;
                  }

                  row["Credit Card Limit"] = app.cardType;
                  row["Annual Fee"] = "$120"

                  return row;
                })}
                pageSize={15}
                pagination
                routeUrl="card/leads"
              />
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: "300px", marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images("./search-not-found.png")}
                    style={{ marginTop: "-40px" }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MastercardLeads;
