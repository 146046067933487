import React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";

const inputComponent = React.forwardRef((props, ref) => {
  return <input ref={ref} {...props} className="form-control mb-2" maxLength={15} />;
});

const FormPhoneInput = ({ control, rules, name }) => (
  <Controller
    as={PhoneInput}
    country="CA"
    defaultCountry="CA"
    placeholder="Enter phone number"
    control={control}
    name={name || "phone"}
    rules={rules}
    inputComponent={inputComponent}
  />
);
export default FormPhoneInput;
