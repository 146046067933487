import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useMutation } from '@apollo/client';
import {
  USER_NOTIFICATION_MARK_READ,
  USER_NOTIFICATIONS_QUERY,
} from "../graphql";

import Breadcrumb from "../components/common/breadcrumb";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CKEditors from "react-ckeditor-component";

const Email = ({ data, id }) => {
  const [markNotificationRead] = useMutation(USER_NOTIFICATION_MARK_READ);
  const [singleMailRecord, setSingleMailRecord] = useState({
    name: "john",
  });
  const [compose, setCompose] = useState(true);
  const [dropdownOpen, setOpen] = useState(false);

  useEffect(() => {
    if (id) {
      const findMessage = data.filter((message) => message.id === id);
      if (findMessage.length > 0) {
        setCompose(false);
        setSingleMailRecord({
          from: "Lendle Staff",
          to: findMessage[0].user.name,
          message: findMessage[0].message,
          date: findMessage[0].createdAt,
          name: "John M.",
          title: findMessage[0].title,
          email: "staff@lendle.com",
        });
      }
      if (findMessage[0].read === false) {
        // update our notification
        markNotificationRead({
          variables: {
            id: findMessage[0].id,
          },
          refetchQueries: [
            {
              query: USER_NOTIFICATIONS_QUERY,
            },
          ],
        });
      }
    }
  }, [data, id, markNotificationRead]);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <Fragment>
      <Breadcrumb parent="Inbox" title="Inbox" />
      <div className="container-fluid">
        <div className="email-wrap">
          <div className="row">
            <div
              className="col-xl-3 col-md-6 cuanimated bounceInLeft"
              id="animated-example"
            >
              <div className="email-right-aside">
                <div className="card email-body">
                  <div className="pr-0 b-r-light">
                    <div className="email-top">
                      <div className="row">
                        <div className="col">
                          <h5>Inbox</h5>
                        </div>
                        <div className="col text-right">
                          <div className="dropdown">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                              <DropdownToggle
                                caret
                                className="dropbtn"
                                color="primary"
                              >
                                {" "}
                                More{" "}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>Draft</DropdownItem>
                                <DropdownItem>Trash</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inbox custom-scrollbar">
                      {data.map((notif, index) => (
                        <div
                          key={index}
                          className="media"
                          onClick={async () => {
                            setCompose(false);
                            setSingleMailRecord({
                              from: "Lendle Staff",
                              to: notif.user.name,
                              message: notif.message,
                              date: notif.createdAt,
                              name: "John M.",
                              title: notif.title,
                              email: "staff@lendle.com",
                            });
                            // mark as read
                            if (notif.read === false) {
                              // update our notification
                              await markNotificationRead({
                                variables: {
                                  id: notif.id,
                                },
                                refetchQueries: [
                                  {
                                    query: USER_NOTIFICATIONS_QUERY,
                                  },
                                ],
                              });
                            }
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <label className="d-block" htmlFor="chk-ani">
                            <input
                              className="checkbox_animated"
                              id="chk-ani"
                              name="chk-ani"
                              type="checkbox"
                            />
                          </label>
                          <div className="media-size-email">
                            <img
                              alt=""
                              className="mr-3 rounded-circle"
                              src={
                                "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=John+M"
                              }
                            />
                          </div>
                          <div className="media-body">
                            <h6>{notif.title} </h6>
                            <p>{notif.message.substr(0, 150)}...</p>
                            <small>
                              <span className="digits">
                                {moment(notif.createdAt).format("LLL")}
                              </span>
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-md-12 cuanimated bounceInRight"
              id="animated-example"
            >
              <div className="email-right-aside">
                <div className="card email-body radius-left">
                  <div className="pl-0">
                    <div className="tab-content">
                      <div
                        aria-labelledby="pills-darkhome-tab"
                        className={`tab-pane fade ${
                          compose ? "active show" : ""
                        }`}
                        id="pills-darkhome"
                        role="tabpanel"
                      >
                        <div className="email-compose">
                          <div className="email-top compose-border">
                            <div className="row">
                              <div className="col-sm-8 xl-50">
                                <h4 className="mb-0">New Message</h4>
                              </div>
                              <div className="col-sm-4 btn-middle xl-50">
                                <button
                                  className="btn btn-primary btn-block btn-mail text-center mb-0 mt-0"
                                  type="button"
                                >
                                  <i className="fa fa-paper-plane mr-2" /> SEND
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="email-wrapper">
                            <form className="theme-form">
                              <div className="form-group">
                                <label
                                  className="col-form-label pt-0"
                                  htmlFor="exampleInputEmail1"
                                >
                                  To
                                </label>
                                <input
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  type="email"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">
                                  Subject
                                </label>
                                <input
                                  className="form-control"
                                  id="exampleInputPassword1"
                                  type="text"
                                />
                              </div>
                              <div className="form-group mb-0">
                                <label className="text-muted">Message</label>
                                <CKEditors activeclassName="p10" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        aria-labelledby="pills-darkprofile-tab"
                        className={`tab-pane fade ${
                          compose !== true ? "active show" : ""
                        }`}
                        id="pills-darkprofile"
                        role="tabpanel"
                      >
                        <div className="email-content">
                          <div className="email-top">
                            <div className="row">
                              <div className="col-md-6 xl-100 col-sm-12">
                                <div className="media">
                                  <img
                                    alt=""
                                    className="mr-3 rounded-circle img-50"
                                    src={
                                      "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=John+M"
                                    }
                                  />
                                  <div className="media-body">
                                    <h6>
                                      {singleMailRecord.name} <br />
                                      <small>john@lendle.com</small>
                                      <br />
                                      <small>
                                        <span className="digits">
                                          {moment(singleMailRecord.date).format(
                                            "LLL"
                                          )}
                                        </span>{" "}
                                      </small>
                                    </h6>
                                    <p>{singleMailRecord.cc}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="float-right d-flex">
                                  <p className="user-emailid">
                                    <span className="digits">
                                      {moment(singleMailRecord.date).fromNow()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="email-wrapper">
                            <p>Hello {singleMailRecord.to},</p>
                            <h5>{singleMailRecord.title}</h5>
                            <p className="m-b-20">{singleMailRecord.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Email;
