import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import AppLayout from '../components/AppLayout';
import { useUser } from '../hooks/index';

import CompleteSignup from '../pages/completesignup';

function AuthRoute({ component: Component, ...rest }) {
  const user = useUser();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={`${process.env.PUBLIC_URL}/login`}
            />
          );
        }
        const needsToCompleteSignUp =
          user.city === false ||
          user.residentialAddress === null ||
          user.gender === null ||
          user.active === false;

        if (needsToCompleteSignUp) {
          return <CompleteSignup />;
        }

        return (
          <AppLayout>
            <Component {...props} />
          </AppLayout>
        );
      }}
    />
  );
}

export default AuthRoute;
