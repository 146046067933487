import React from "react";
import CreditCard from "./CreditCard";

import { useWindowResize } from "../hooks";

const FormCardSelect = ({
  options,
  setValue,
  input,
  defaultValue,
}) => {
  const onChange = (label) => {
    setValue(input, label);
  };

  const size = useWindowResize();

  return options.map((option, index) => {
    return (
        <CreditCard key={index} fullWidth={size.width < 600 ? true : false } onClick={() => onChange(option.label,`radio-${option.id}` )} selected={defaultValue === option.label} amount={option.label} fee={option.fee}/>

    );
  });
};

export default FormCardSelect;
