import React from 'react';
import ChartistGraph from 'react-chartist';
import * as Chartist from 'chartist';

const primary = localStorage.getItem('primary_color') || '#4466f2';
const secondary = localStorage.getItem('secondary_color') || '#1ea6ec';

const labels = ['01', '02', '03', '04', '05', '06'];
const series = [[0, 2, 1.2, 4, 2, 3, 0]];

const chart = {
  showArea: true,
  fullWidth: !0,
  legend: {
    display: false,
  },
  axisX: {
    low: 0,
    showLabel: false,
    showGrid: false,
  },
  axisY: {
    showLabel: false,
    showGrid: false,
  },
  lineSmooth: Chartist.Interpolation.simple({
    divisor: 2,
  }),
  scales: {
    yAxes: [
      {
        display: false,
      },
    ],
    xAxes: [
      {
        display: false,
      },
    ],
  },
  chartPadding: {
    right: 0,
    left: -40,
    bottom: -30,
  },
};

const MetricItem = ({ children }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="chart-widget-dashboard">
          <div className="media">
            <div className="media-body">{children}</div>
          </div>
          <div className="dashboard-chart-container">
            <div className="small-chart-gradient-1">
              <ChartistGraph
                className="ct-chart-line"
                data={{
                  labels,
                  series,
                }}
                key="1"
                listener={{
                  created: function (data) {
                    var defs = data.svg.elem('defs');
                    defs
                      .elem('linearGradient', {
                        id: 'gradient1',
                        x1: 0,
                        y1: 0,
                        x2: 1,
                        y2: 1,
                      })
                      .elem('stop', {
                        offset: 0,
                        'stop-color': secondary,
                      })
                      .parent()
                      .elem('stop', {
                        offset: 1,
                        'stop-color': primary,
                      });
                  },
                }}
                options={chart}
                type={'Line'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricItem;
