import React from "react";
import CommentsList from "./CommentsList";
import AddComment from "./AddComment";
import { withRouter } from "react-router";

import { useUser } from "../../../hooks";

// This is a component
const Comments = ({ match }) => {

    const me = useUser();
    const id = match.params.id;
        
    return (
      <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-4">
            <AddComment
              id={id}
              userid={me.id}
            />
        
          </div>
          <div className="col-sm-8">
            <CommentsList
              id={id}
              userid={me.id}
            />
          </div>
        </div>
      </div>
      </>
    );

}

export default Comments;