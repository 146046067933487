import React from "react";
import { useMutation } from '@apollo/client';
import { confirmAlert } from "react-confirm-alert"; // Import

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ADMIN_DELETE_PAYMENT } from "../graphql";

const DeletePaymentButton = ({ id, onSuccess }) => {
  const [deletePaymentItem, { loading, errors }] = useMutation(
    ADMIN_DELETE_PAYMENT
  );
  const handleDeletePayment = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deletePaymentItem({
              variables: {
                id,
              },
            });
            onSuccess();
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  return (
    <button
      className="btn btn-square btn-danger"
      onClick={() => {
        handleDeletePayment(id);
      }}
      disabled={loading}
      type="button"
    >
      {loading ? "..." : "X"}
    </button>
  );
};

export default DeletePaymentButton;
