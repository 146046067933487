import React, { useState } from "react";

// Handle our form
import { useForm } from "react-hook-form";

// Dom
import { Link } from "react-router-dom";

// Alerts
import { toast } from "react-toastify";

// HOC to get apollo client
import { useMutation } from '@apollo/client';

// Auth queries
import {
  USER_PROFILE_QUERY,
  USER_LOAN_QUERY,
  AUTH_SIGNIN_MUTATION,
} from "../../../graphql";

const SignInForm = ({ onSuccess }) => {
  // Initialize our form hooks
  const { register, handleSubmit, errors } = useForm();
  const [validateClass, setValidateClass] = useState(false);

  // Signin mutation
  const [performSignIn, { loading }] = useMutation(AUTH_SIGNIN_MUTATION);

  // Handle form submission
  const onSubmit = async (data) => {
    // Data returns empty if there are errors
    if (data === "") {
      errors.showMessages();
      return;
    }

    // Queries that we need to refetch after sign in
    const refetchQueries = [
      { query: USER_PROFILE_QUERY },
      { query: USER_LOAN_QUERY },
    ];

    // Attempt the sign in process
    try {
      await performSignIn({
        variables: { ...data },
        refetchQueries,
      });
      onSuccess();
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  return (
    <form
      className={`theme-form needs-validation tooltip-validation ${
        validateClass ? "validateClass" : ""
      }`}
      noValidate=""
      onSubmit={handleSubmit(onSubmit)}
    >
      {loading && (
        <div className="loader-box loader-form-wrapper">
          <span className="rotate dotted" />
        </div>
      )}
      <fieldset aria-busy={loading} disabled={loading}>
        <div className="form-group">
          <label className="col-form-label pt-0" htmlFor="validationTooltip01">
            Your Email
          </label>
          <input
            className="form-control"
            id="validationTooltip01"
            name="email"
            ref={register({ required: true })}
            type="text"
          />
          {errors.email && <span>Email is required</span>}
        </div>
        <div className="form-group">
          <label className="col-form-label" htmlFor="validationTooltip02">
            Password
          </label>
          <input
            className="form-control"
            id="validationTooltip02"
            name="password"
            ref={register({ required: true })}
            type="password"
          />
          {errors.password && <span>Password is required</span>}
        </div>
        <div className="form-group form-row mt-3 mb-0">
          <button
            className="btn btn-primary btn-block"
            onClick={() => setValidateClass(!validateClass)}
            type="submit"
          >
            Login
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default SignInForm;
