import React from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

const RangeSlider = ({ control, ...rest }) => {
  return <Controller as={Slider} control={control} {...rest} />;
};

RangeSlider.propTypes = {
  control: PropTypes.object.isRequired,
};
export default RangeSlider;
