import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { purposeOptions } from "../constant/constants";

const FormBorrowPurposeSelect = ({ control, rules, errors }) => {
  return (
    <>
      <div className="col-sm-12 col-md-6 mt-2 mb-2">
        <Controller
          as={<Select placeholder="Select Reason" />}
          control={control}
          name="purposeOfBorrow"
          options={purposeOptions}
          placeholder="Select Reason"
          rules={{ validate: (select) => select.value !== undefined, ...rules }}
        />
        {errors.purposeOfBorrow && <span>Borrow reason is required</span>}
      </div>

      <div className="col-sm-12 col-md-6 mt-2 mb-2">
        <Controller
          as={<input />}
          defaultValue=""
          className="form-control"
          control={control}
          name="borrowReason"
          placeholder={
            "Explain in detail why you would like to borrow for this purpose"
          }
          rules={rules}
          type="text"
        />

        {errors.borrowReason && <span>Borrow description is required</span>}
      </div>
    </>
  );
};

export default FormBorrowPurposeSelect;
