import React, { useState } from "react";
import moment from "moment";
import currency from "currency.js";

import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";

import loanCalc from "../../../services/loanCalc";
import { LOAN_REQUEST_MUTATION } from "../../../graphql";

import Success from "../steps/Success";

import "./review.css";

const Result = ({ wizardData, navigation: { previous, next } }) => {
  const [agree, setAgree] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [showAgreeError, setShowAgreeError] = useState(false);
  const [appSubmitted, setAppSubmitted] = useState(false);
  const loanData = loanCalc(
    wizardData.loanAmount,
    parseInt(wizardData.paymentCycleDuration),
    wizardData.paymentCycleFrequency,
    wizardData.paymentCycleStartDate.toString()
  );

  const [performLoanRequest, { loading }] = useMutation(LOAN_REQUEST_MUTATION);

  const onSubmit = async () => {
    if (!agree) {
      toast.error(
        "You must agree to the terms and conditions before submitting"
      );
      setShowAgreeError(true);
      return;
    }

    try {
      const query = await performLoanRequest({
        variables: {
          ...wizardData,
          ...loanData,
          name: `${wizardData.firstname} ${wizardData.lastname}`,
          dob: `${wizardData.dobDay}-${wizardData.dobMonth}-${wizardData.dobYear}`,
          employmentAndIncome: wizardData.employmentAndIncome.map((emp) => ({
            title: emp.type,
            desc: emp.desc,
            meta: emp.name ? emp.name : "",
          })),
          bankStatement: wizardData.govIssuedIdFront.file,
          proofOfResidence: wizardData.govIssuedIdBack.file,
          country: "Canada",
          govIssuedIdFront: null,
          govIssuedIdBack: null,
        },
      });

      setLoanId(query.data.requestLoan.id);
      setAppSubmitted(true);
    } catch (error) {
      setTimeout(() => {
        toast.error(error.message.replace("GraphQL error: ", ""));
      }, 200);
    }
  };

  return (
    <React.Fragment>
      {!appSubmitted ? (
        <div className="card">
          <div className="card-header text-center">
            <h5 className="mb-2">You Are Almost There!</h5>
            <p className="text-center mt-3 mb-3">
              Below is your loan terms and fees, please review everything and
              click submit when you are ready.
            </p>
          </div>
          <div className="card-body">
            <div class="content">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-md-8 col-sm-7 col-xs-12">
                    <div class="box">
                      <h3 class="box-title">Loan Terms</h3>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question4">Borrow Amount</label>
                          <span class="plan-price term-price">
                            ${wizardData.loanAmount}
                          </span>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question5">Term Duration</label>
                          <span class="plan-price term-price">
                            {wizardData.paymentCycleDuration}
                          </span>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question6">Payments Begin</label>
                          <span class="plan-price term-price">
                            {moment(wizardData.paymentCycleStartDate).format(
                              "LL"
                            )}
                          </span>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question6">Payments Frequency</label>
                          <span
                            class="plan-price term-price"
                            style={{ textTransform: "capitalize" }}
                          >
                            {wizardData.paymentCycleFrequency}
                          </span>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question6">Purpose Of Borrow</label>
                          <p class="plan-text">
                            <strong>{wizardData.purposeOfBorrow}</strong> |{" "}
                            {wizardData.borrowReason}
                          </p>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question6">Employment And Income</label>
                          <ul>
                            {wizardData.employmentAndIncome.map(
                              (item, index) => (
                                <li key={index}>
                                  <strong>{item.type}</strong> | {item.desc} |{" "}
                                  {item.name ? item.name : ""}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="box">
                      <h3 class="box-title">User Info</h3>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question1">Personal</label>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Name</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.firstname} {wizardData.lastname}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Date Of Birth</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.dobDay}-{wizardData.dobMonth}-
                              {wizardData.dobYear}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Gender</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.gender}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question2">Contact</label>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Phone</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.phone}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Email</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.email}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="question3">Address</label>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Address</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.street}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>City</strong>
                            </div>
                            <div className="col-md-auto">{wizardData.city}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Province</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.province}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Postal</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.postal}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Suite</strong>
                            </div>
                            <div className="col-md-auto">
                              {wizardData.suite}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <strong>Country</strong>
                            </div>
                            <div className="col-md-auto">Canada</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box">
                      <h3 class="box-title">Identification</h3>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="box1">Front Side</label>
                          <div className="row">
                            <div className="col-md-3">
                              <img
                                alt="ID Front"
                                src={
                                  wizardData.govIssuedIdFront.imagePreviewUrl
                                }
                                width={150}
                                height={90}
                              />
                            </div>
                            <div className="col-md-auto">
                              {wizardData.govIssuedIdFront.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="plan-selection">
                        <div class="plan-data">
                          <label for="box2">Back Side</label>
                          <div className="row">
                            <div className="col-md-3">
                              <img
                                alt="ID Front"
                                src={wizardData.govIssuedIdBack.imagePreviewUrl}
                                width={150}
                                height={90}
                              />
                            </div>
                            <div className="col-md-auto">
                              {wizardData.govIssuedIdBack.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
                    <div class="widget">
                      <h4 class="widget-title">Calculation & Fees</h4>
                      <div class="summary-block">
                        <div class="summary-content">
                          <div class="summary-head">
                            <h5 class="summary-title">Admin Fee</h5>
                          </div>
                          <div class="summary-price">
                            <p class="summary-text">
                              ${loanData.loanFee.value}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="summary-block">
                        <div class="summary-content">
                          <div class="summary-head">
                            {" "}
                            <h5 class="summary-title">Total Payback</h5>
                          </div>
                          <div class="summary-price">
                            <p class="summary-text">
                              $
                              {
                                currency(wizardData.loanAmount).add(
                                  loanData.loanFee
                                ).value
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="summary-block">
                        <div class="summary-content">
                          <div class="summary-head">
                            {" "}
                            <h5 class="summary-title">Payments</h5>
                          </div>
                          <div class="summary-price">
                            <p class="summary-text">
                              ${loanData.paymentCycleAmount.value} /{" "}
                              {wizardData.paymentCycleFrequency}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-row justify-content-center align-items-center p-3 mb-4"
                      style={{
                        border: showAgreeError
                          ? "1px solid red"
                          : "1px solid #ececec",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        checked={agree}
                        id="checkbox"
                        onClick={() => setAgree((state) => !state)}
                        type="checkbox"
                        style={{}}
                      />
                      <label className="ml-2 mb-0" htmlFor="checkbox">
                        By checking this box I agree to the{" "}
                        <strong>
                          <a
                            href="https://lendle.com/terms.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            terms and conditions
                          </a>
                        </strong>{" "}
                        of use outlined on the TOS page.
                      </label>
                    </div>
                    <button
                      onClick={onSubmit}
                      disabled={loading}
                      className="btn btn-prev btn-success btn-lg pull-right"
                      id="next-button"
                      style={{
                        width: "100%",
                      }}
                    >
                      {loading
                        ? "Submitting Your Request..."
                        : "Submit Application"}
                    </button>
                    <button
                      onClick={previous}
                      disabled={loading}
                      type="button"
                      className="btn btn-prev btn-default btn-lg pull-left mt-3"
                      id="next-button"
                      style={{
                        width: "100%",
                      }}
                    >
                      Previous
                    </button>
                    {loading && (
                      <div className="loader-box loader-form-wrapper">
                        <span className="rotate dotted" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Success loanId={loanId} />
      )}
    </React.Fragment>
  );
};

export default Result;
