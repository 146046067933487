import Apply from "./Apply";
import CardApply from "./Card/Apply";
import CardApplications from "./Card/Applications";
import DashboardView from "./Dashboard";
import User from "./User";
import Users from "./Users";
import Loan from "./Marketing/Lead";
import Loans from "./Marketing/Lead";
import LeadItem from "./Marketing/Lead";
import LeadsList from "./Marketing/Leads";
import Me from "./Me";
import MyCard from "./Card";
import MastercardSignup from "./Auth/MastercardSignup";
import MastercardLeads from"./Card/Leads";
import MastercardLead from"./Card/Lead";
import MyReferrals from"./Card/Referral";

import LoanTape from"./Tape";
import LoanTapes from"./Tape/LoanTapes";


import SignIn from "./Auth/SignIn";

export {
  Apply,
  CardApply,
  CardApplications,
  MastercardSignup,
  MastercardLeads,
  MastercardLead,
  MyReferrals,
  DashboardView,
  User,
  Users,
  LeadsList,
  LeadItem,
  Loan,
  Loans,
  Me,
  MyCard,
  SignIn,
  LoanTape,
  LoanTapes
};
