/* eslint-disable no-undef */
import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { User, Mail, Lock, LogOut } from 'react-feather';
import man from '../../../assets/images/dashboard/user.png';
import { Link } from 'react-router-dom';

import {
  AUTH_SIGNOUT_MUTATION,
  USER_PROFILE_QUERY,
} from '../../../graphql';

import { useUser } from '../../../hooks';

const UserMenu = ({ client }) => {
  // eslint-disable-next-line no-unused-vars
  const [profile, setProfile] = useState('');
  const me = useUser();
  

  const fname = me && me.name && me.name.split[0] ? me.name.split[0] : '';
  const lname = me && me.name && me.name.split[1] ? me.name.split[1] : '';

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
  }, []);

  const logOut = async () => {
    await client.mutate({
      mutation: AUTH_SIGNOUT_MUTATION,
      refetchQueries: [{ query: USER_PROFILE_QUERY }],
    });
    return client.cache.reset();
  };

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            alt="header-user"
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={
              me && me.name.split(' ').length > 0
              ? `https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=${fname}+${lname}`
              : ''
            }
          />
          <div className="dotted-animation">
            <span className="animate-circle" />
            <span className="main-circle" />
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          {/* <li><Link to="/settings/profile" ><i data-feather="user"></i>Edit Profile</Link></li> */}
          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/me`}>
              <User />
              Edit Profile
            </Link>
          </li>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/inbox`}>
              <Mail />
              Inbox
            </Link>
          </li>
          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/pages/unlockUser`}>
              <Lock />
              Lock Screen
            </Link>
          </li>
          <li>
            <strong>
              #{me && me.id.substr(0, 8).toUpperCase()}
            </strong>
          </li>
          <li>
            <a onClick={logOut}>
              <LogOut /> Log out
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default withApollo(withRouter(UserMenu));
