/* eslint-disable no-undef */
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Datatable from "../../components/datatable";
import moment from "moment";
import currency from "currency.js";

import ActionMenu from "../Card/ActionMenu";

import {useUser } from "../../hooks";

var images = require.context("../../assets/images", true);


const DCBank = ({ data, match }) => {
 
  const me = useUser();
  const id = match.params.id;

  const [modal, setModal] = useState();
  const [modalMeta, setModalMeta] = useState({
    url: [],
    title: null,
    campaign: "",
  });

  const toggle = (url, title, campaign) => {
    setModalMeta({
      url,
      title,
      campaign,
    });
    setModal(!modal);
  };

  const available = data.cardApplication.cardBalance
  const balance = currency(data.cardApplication.cardLimit).subtract(available);
  

  return (
    <Fragment>
    
          <div className="container-fluid">
            {
              <div
                className="email-top"
                style={{
                  top: "48px",
                  position: "absolute",
                  right: 0,
                  width: "auto",
                  float: "right",
                }}
              >
                <div className="col text-right">
                 <ActionMenu meId={me.id} data={data} />

                </div>
              </div>
            }
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div
                  className="card cuanimated bounceInLeft"
                  data-intro="This is University Earning Chart"
                  id="animated-example"
                >
                  <div className="card-header b-l-primary">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>Credit Card Application Details</h5>
                      </div>
                      <div className="col-sm-6 text-right pull-right">
                        <small>
                          {/* DCBank Customer Uniqe ID:{" "}
                          <strong>#{data.cardApplication.user.customerId}</strong> */}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <div className="form-group">
                            <label className="form-label">
                              <strong>DC Customer Number</strong>
                            </label>
                            <br />
                            <span
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {data.cardApplication.user.customerNumber}
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>DC Customer Id</strong>
                          </label>
                          <br />
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                              {data.cardApplication.user.customerId}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Customer Status</strong>
                          </label>
                          <br />
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                              {data.cardApplication.user.customerStatus}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Card Status</strong>
                          </label>
                          <br />
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                              {data.cardApplication.cardStatus}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Card Id </strong>
                          </label>
                          <br />
                          {data.cardApplication.cardId}

                          <br />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Card Number</strong>
                          </label>
                          <br />
                          <span>
                          {data.cardApplication.cardNumber}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Card Type</strong>
                          </label>
                          <br />
                          <span>
                           Lendle Secured Mastercard
                          </span>
                          <br />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Credit Available</strong>
                          </label>
                          <br />
                          <span>
                           ${available}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Outstanding Balance</strong>
                          </label>
                          <br />
                          <span>
                          ${balance.value}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Cashback </strong>
                          </label>
                          <br />
                          <span>
                         0 
                          </span>
                          <br />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="col-sm-12">
            <div
              className="card height-equal cuanimated bounceInLeft bounceInRight"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>CARD TRANSACTIONS</h5>
              </div>
              <div className="card-body">
                <div className="user-status table-responsive">
  
                  {
                    data.cardApplication && data.cardApplication.transactions && data.cardApplication.transactions.length > 0 ? <Datatable
                    class="-striped -highlight"
                    myData={data.cardApplication.transactions.map((transaction) => {
                      let row = {};
    
                      row["ID"] = transaction.id;
                      row["Transaction Name"] = transaction.Description;
                      row["Transaction Amount"] = transaction.ProvisionAmount;
                     
                      if(transaction.TerminalType === "1"){
                        row["Terminal Type"] = "Online";
                      } 
                      if(transaction.TerminalType === "2"){
                        row["Terminal Type"] = "POS";
                      }

                      if(transaction.TerminalType === "3"){
                        row["Terminal Type"] = "ATM";
                      }

                      row["Date"] = moment(transaction.createdAt).format("LL");

    
                      return row;
                    })}
                    pageSize={15}
                    pagination
                    routeUrl="users"
                  /> :   <div
                    className="search-not-found text-center ng-star-inserted"
                    style={{
                      height: "300px",
                      marginTop: 0,
                    }}
                  >
                    <div className="">
                      <img
                        className="second-search"
                        src={images("./search-not-found.png")}
                        style={{ marginTop: "-50px" }}
                      />
                      <p className="mb-0">No Transactions Found</p>
                    </div>
                  </div>
                  }
  
                </div>
              </div>
            </div>
          </div>

              </div>
  
    
              
            </div>
          </div>
        </Fragment>
  );
};

export default withRouter(DCBank);
