/* eslint-disable no-undef */
import React, { useState, Fragment } from "react";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";

import ActionMenu from "../ActionMenu";

import { CARD_REQUEST_BYID_QUERY } from "../../../graphql";
import { useUser } from "../../../hooks";
import formatMoney from "../../../services/formatMoney";

import { CheckCircle, XCircle, MoreHorizontal } from "react-feather";
import ScrollBars from "react-scrollbar";

import {
  CreditCard,
  UserReferrals,
  DeletePaymentButton,
} from "../../../components";

var images = require.context("../../../assets/images", true);

const Application = ({ data, refetch }) => {
  const me = useUser();

  const isAdmin = me.permissions.includes("ADMIN");
  const isAdjudicator = me.permissions.includes("ADJUDICATOR");

  const [modal, setModal] = useState();
  const [modalMeta, setModalMeta] = useState({
    url: [],
    title: null,
    campaign: "",
  });

  const toggle = (url, title, campaign) => {
    setModalMeta({
      url,
      title,
      campaign,
    });
    setModal(!modal);
  };


  return (
    <Fragment>

<Modal fade={false} isOpen={modal} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalMeta.title}</ModalHeader>
        <ModalBody>
          <ScrollBars autoHide={false} horizontal>
            <div>
              {modalMeta &&
                modalMeta.url.length > 0 &&
                modalMeta.url.map((url, index) => (
                  <img
                    alt="document"
                    className="img-fluid"
                    src={`${process.env.REACT_APP_BASE_URL_PROD}/${modalMeta.url[index]}`.replace(
                      "https://api.lendle.com/https://lendle.com",
                      "https://lendle.com"
                    )}
                  />
                ))}
            </div>
          </ScrollBars>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container-fluid">
        {
          <div
            className="email-top"
            style={{
              top: "48px",
              position: "absolute",
              right: 0,
              width: "auto",
              float: "right",
            }}
          >
            <div className="col text-right">
              {(isAdmin || isAdjudicator) && (
                <ActionMenu meId={me.id} data={data} />
              )}
            </div>
          </div>
        }
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-8">
            <div
              className="card cuanimated bounceInLeft"
              data-intro="This is University Earning Chart"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <div className="row">
                  <div className="col-sm-6">
                    <h5>Credit Card Application Details</h5>
                  </div>
                  <div className="col-sm-6 text-right pull-right">
                    <small>
                      Mastercard Number:{" "}
                      <strong>#{data.cardApplication.tempNumber}</strong>
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <div className="form-group">
                        <label className="form-label">
                          <strong>Name</strong>
                        </label>
                        <br />
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.cardApplication.name}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Email Address</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.cardApplication.email.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Phone Number</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.cardApplication.phone.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Gender</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.cardApplication.gender.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Address</strong>
                      </label>
                      <br />
                      {data.cardApplication.residentialAddress !== null && (
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.cardApplication.residentialAddress.street},{" "}
                          {data.cardApplication.residentialAddress.city},{" "}
                          {data.cardApplication.residentialAddress.suite},{" "}
                          {data.cardApplication.residentialAddress.postal},{" "}
                          {data.cardApplication.residentialAddress.province},{" "}
                          {data.cardApplication.residentialAddress.country}
                        </span>
                      )}
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Monthly Income</strong>
                      </label>
                      <br />
                      <span>
                        ${data.cardApplication.monthlyIncome.replace("$", "")}
                      </span>
                      <br />
                    </div>
                  </div>
                  
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Employer's Name</strong>
                      </label>
                      <br />
                      <span>{data.cardApplication.employerName}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Employer's Phone Number</strong>
                      </label>
                      <br />
                      <span>{data.cardApplication.employerPhone}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Job Title / Position</strong>
                      </label>
                      <br />
                      <span>{data.cardApplication.employerJobTitle}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Credit Details</strong>
                      </label>
                      <br />
                      <span>
                        {data.cardApplication.cardLimit} {data.cardApplication.cardType}
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xs-12 col-sm-6 col-md-4 cuanimated bounceInRight"
            id="animated-example"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="media feather-main">
                      <div
                        className="feather-icon-block"
                        style={{
                          backgroundColor: (function () {
                            switch (data.cardApplication.status.toLowerCase()) {
                              case "approved":
                                return "#07cc07";
                              case "denied":
                                return "#f30202";
                              case "inreview":
                                return "#ff9f40";
                              case "withdraw":
                                return "#969dff";
                              default:
                                return "#b7b7b7";
                            }
                          })(),
                        }}
                      >
                        {data.cardApplication.status.toLowerCase() ===
                          "approved" && <CheckCircle />}
                        {data.cardApplication.status.toLowerCase() ===
                          "denied" && <XCircle />}
                        {data.cardApplication.status.toLowerCase() ===
                          "pending" && <MoreHorizontal />}
                        {data.cardApplication.status.toLowerCase() ===
                          "archive" && <MoreHorizontal />}
                        {data.cardApplication.status.toLowerCase() ===
                          "inreview" && <MoreHorizontal />}
                        {data.cardApplication.status.toLowerCase() ===
                          "withdraw" && <XCircle />}
                      </div>
                      <div className="media-body align-self-center">
                        <h6
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.cardApplication.status === "INREVIEW"
                            ? "In Review"
                            : data.cardApplication.status.toLowerCase()}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="media feather-main">
                      <div
                        className="feather-icon-block"
                        style={{
                          backgroundColor: "#07cc07",
                        }}
                      >
                        {data.cardApplication.status.toLowerCase() ===
                          "approved" && <CheckCircle />}
                      </div>
                      <div className="media-body align-self-center">
                        <h6
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.cardApplication.discountType}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-sm-12">
            <div
              className="card height-equal cuanimated bounceInLeft bounceInRight"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>CARD PAYMENTS</h5>
              </div>
              <div className="card-body">
                <div className="user-status table-responsive">
                  {data.cardApplication.payments.length > 0 ? (
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col-md-2">Date</th>
                          <th scope="col">Ref #</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Desc</th>
                          <th scope="col">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.cardApplication.payments
                          .map((pay, index) => (
                            <tr key={index}>
                              <td>{moment(pay.createdAt).format("LL")}</td>
                              <td className="font-primary">
                                {pay.id.substr(0, 8)}
                              </td>
                              <td className="digits">
                                {formatMoney(pay.amount)}
                              </td>
                              <td className="digits">{pay.desc}</td>
                              <td>
                                <DeletePaymentButton
                                  onSuccess={() => refetch()}
                                  key={index}
                                  id={pay.id}
                                />
                              </td>
                            </tr>
                          ))
                          .reverse()}
                      </tbody>
                    </table>
                  ) : (
                    <div
                      className="search-not-found text-center ng-star-inserted"
                      style={{
                        height: "300px",
                        marginTop: 0,
                      }}
                    >
                      <div className="">
                        <img
                          className="second-search"
                          src={images("./search-not-found.png")}
                          style={{ marginTop: "-50px" }}
                        />
                        <p className="mb-0">No Payments Found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card height-equal cuanimated bounceInRight"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>Gov Issued Id</h5>
              </div>
              <div className="card-body">
                <div className="new-users">
                  <div className="media">
                    {data.cardApplication.documents &&
                      data.cardApplication.documents.govIssuedId.length > 0 &&
                      data.cardApplication.documents.govIssuedId[0]
                        .split(".")
                        .pop()
                        .toLowerCase() !== "pdf" && (
                        <img
                          alt=""
                          className="rounded-circle image-radius m-r-15"
                          src={`${
                            data.cardApplication.campaign ===
                            "secured_mastercard"
                              ? "https://lendle.com/api/src"
                              : process.env.REACT_APP_BASE_URL_PROD
                          }/${data.cardApplication.documents.govIssuedId[0].replace("https://lendle.com/api/src/https://lendle.com/api/src","")}`}
                          style={{
                            height: "58px",
                          }}
                        />
                      )}
                    {data.cardApplication.documents &&
                      data.cardApplication.documents.govIssuedId.length > 0 &&
                      data.cardApplication.documents.govIssuedId[0]
                        .split(".")
                        .pop()
                        .toLowerCase() === "pdf" && (
                        <img
                          alt=""
                          className="rounded-circle image-radius m-r-15"
                          src={images("./pdf.png")}
                          style={{
                            height: "58px",
                          }}
                        />
                      )}
                    {data.cardApplication.documents &&
                      !data.cardApplication.documents.govIssuedId.length >
                        0 && (
                        <img
                          alt=""
                          className="rounded-circle image-radius m-r-15"
                          src={
                            "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=G"
                          }
                          style={{
                            height: "58px",
                          }}
                        />
                      )}
                    <div className="media-body">
                      <h6 className="mb-0 f-w-700">Card Front</h6>
                    </div>
                    <span className="pull-right">
                      {data.cardApplication.documents &&
                      data.cardApplication.documents.govIssuedId.length > 0 ? (
                        <button
                          className="btn btn-pill btn-outline-light"
                          onClick={() => {
                            if (
                              data.cardApplication.documents.govIssuedId[0]
                                .split(".")
                                .pop()
                                .toLowerCase() === "pdf"
                            ) {
                              window.location.href = `${
                                process.env.REACT_APP_BASE_URL_PROD
                              }/download?file=${encodeURI(
                                data.cardApplication.documents.govIssuedId[0].replace(
                                  "uploads/",
                                  ""
                                )
                              )}`;
                            } else {
                              toggle(
                                data.cardApplication.documents.govIssuedId,
                                "Gov Issued Id",
                                data.cardApplication.campaign
                              );
                            }
                          }}
                        >
                          View Doc
                        </button>
                      ) : (
                        <button className="btn btn-pill btn-outline-light">
                          Request / Upload
                        </button>
                      )}
                    </span>
                  </div>

                  <div className="media">
                    {data.cardApplication.documents &&
                      data.cardApplication.documents.proofOfResidence.length >
                        0 &&
                      data.cardApplication.documents.proofOfResidence[0]
                        .split(".")
                        .pop()
                        .toLowerCase() !== "pdf" && (
                        <img
                          alt=""
                          className="rounded-circle image-radius m-r-15"
                          src={`${
                            data.cardApplication.campaign ===
                            "secured_mastercard"
                              ? "https://lendle.com/api/src"
                              : process.env.REACT_APP_BASE_URL_PROD
                          }/${
                            data.cardApplication.documents.proofOfResidence[0].replace("https://lendle.com/api/src/https://lendle.com/api/src","")
                          }`}
                          style={{
                            height: "58px",
                          }}
                        />
                      )}
                    {data.cardApplication.documents &&
                      data.cardApplication.documents.proofOfResidence.length >
                        0 &&
                      data.cardApplication.documents.proofOfResidence[0]
                        .split(".")
                        .pop()
                        .toLowerCase() === "pdf" && (
                        <img
                          alt=""
                          className="rounded-circle image-radius m-r-15"
                          src={images("./pdf.png")}
                          style={{
                            height: "58px",
                          }}
                        />
                      )}
                    {data.cardApplication.documents &&
                      !data.cardApplication.documents.proofOfResidence.length >
                        0 && (
                        <img
                          alt=""
                          className="rounded-circle image-radius m-r-15"
                          src={
                            "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=R"
                          }
                          style={{
                            height: "58px",
                          }}
                        />
                      )}
                    <div className="media-body">
                      <h6 className="mb-0 f-w-700">Card Back</h6>
                    </div>
                    <span className="pull-right">
                      {data.cardApplication.documents &&
                      data.cardApplication.documents.proofOfResidence.length >
                        0 ? (
                        <button
                          className="btn btn-pill btn-outline-light"
                          onClick={() => {
                            if (
                              data.cardApplication.documents.proofOfResidence[0]
                                .split(".")
                                .pop()
                                .toLowerCase() === "pdf"
                            ) {
                              window.location.href = `${
                                process.env.REACT_APP_BASE_URL_PROD
                              }/download?file=${encodeURI(
                                data.cardApplication.documents.proofOfResidence[0].replace(
                                  "uploads/",
                                  ""
                                )
                              )}`;
                            } else {
                              toggle(
                                data.cardApplication.documents.proofOfResidence,
                                "Gov Issued Id",
                                data.cardApplication.campaign
                              );
                            }
                          }}
                        >
                          View Doc
                        </button>
                      ) : (
                        <button className="btn btn-pill btn-outline-light">
                          Request / Upload
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card height-equal cuanimated bounceInRight"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>Referrals</h5>
              </div>
              <div className="card-body">
                <UserReferrals
                  email={
                    isAdmin || isAdjudicator
                      ? data.cardApplication.email
                      : me.email
                  }
                  campaign="secured_mastercard"
                  hideAction={!isAdmin && !isAdjudicator}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Application);
