import React from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Home } from 'react-feather';

const DashboardTabs = ({ tabs }) => (
  <div className="row theme-tab">
    <Tabs className="col-sm-12">
      <TabList className="tabs tab-title">
        {tabs.map((tab, index) => (
          <Tab
            className={tab.current ? 'current' : ''}
            key={index}
            onClick={() => (tab.isButton === undefined ? '' : tab.onClick())}
          >
            <Home />
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <div className="tab-content-cls">
        {tabs.map((tab, index) => (
          <TabPanel key={index}>{tab.isButton ? '' : tab.panel}</TabPanel>
        ))}
      </div>
    </Tabs>
  </div>
);

export default DashboardTabs;
