/* TODO APP*/
export const GET_LIST = 'GET_LIST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const MARK_ALL_ITEMS = 'MARK_ALL_ITEMS';
export const ADD_NEW_ITEM = 'ADD_NEW_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SELECTED_ITEM = 'SELECTED_ITEM';

/* TODO FIREBASE APP*/
export const GET_LIST_FIREBASE = 'GET_LIST';
export const GET_LIST_SUCCESS_TASK = 'GET_LIST_SUCCESS_TASK';
export const MARK_ALL_TASK = 'MARK_ALL_TASK';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const REMOVE_TASK = 'REMOVE_TASK';
export const SELECTED_TASK = 'SELECTED_TASK';


/* ECOMMERRCE APP */

export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'
export const SEARCH_MEMBER_PRODUCT = 'SEARCH_MEMBER_PRODUCT';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const GET_SINGLE_ITEM = 'GET_SINGLE_ITEM';

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SORT_BY = 'SORT_BY';
export const SEARCH_BY = 'SEARCH_BY';

/* CHAT APP*/
export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_CHATS = 'GET_CHATS';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_ERROR = 'GET_CHATS_ERROR';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEARCH_MEMBER = 'SEARCH_MEMBER';
export const CHANGE_CHAT = 'CHANGE_CHAT';
export const CREATE_CHAT = 'CREATE_CHAT';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
export const REPLY_BY_SELECTED_USER = 'REPLY_BY_SELECTED_USER'

// EMAIL APP
export const GET_EMAILS = 'GET_EMAILS';
export const GET_ALL_EMAILS = 'GET_ALL_EMAILS';
export const GET_EMAIL_TYPES = 'GET_EMAIL_TYPES';
export const UPDATE_EMAIL_TYPES = 'UPDATE_EMAIL_TYPES';

// AUTHENTICATION APP
export const LOGIN = 'LOGIN';
export const INITIALIZE_FIREBASE = 'INITIALIZE_FIREBASE';

//CUSTOMIZER 
export const ADD_COSTOMIZER ='ADD_COSTOMIZER';
export const ADD_LAYOUT = 'ADD_LAYOUT';
export const ADD_SIDEBAR_TYPES = 'ADD_SIDEBAR_TYPES';
export const ADD_SIDEBAR_SETTINGS ='ADD_SIDEBAR_SETTINGS';
export const ADD_COLOR = 'ADD_COLOR';
export const ADD_MIXlAYOUT = 'ADD_MIXlAYOUT';

