import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { USER_LOAN_QUERY_BYID } from "../graphql";
import ApplicationTab from "../components/application";
import Calendar1 from "./calenderPage";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Home, FileText, Calendar, MessageCircle } from "react-feather";
import Breadcrumb from "../components/common/breadcrumb";
import Notes from "../components/notes";
import { useUser } from "../hooks";
import { UserLoans } from "../components";

const Application = (props) => {
  const me = useUser();

  const isAdmin =
    me.permissions.includes("ADMIN") || me.permissions.includes("ADJUDICATOR");
  const id = props.match.params.id;

  const { data, loading, refetch: refetchLoan } = useQuery(
    USER_LOAN_QUERY_BYID,
    {
      variables: {
        id: id,
      },
    }
  );

  if(loading){
    return "loading";
  }

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Overview" />
      <div className="container-fluid">
        <div className="row theme-tab">
          <Tabs className="col-sm-12">
            <TabList
              className="tabs tab-title"
              style={{ alignItems: "center" }}
            >
              <Tab className="current">
                <Home />
                Application
              </Tab>
              {isAdmin && (
                <Tab>
                  <MessageCircle />
                  Comment History
                </Tab>
              )}
              {!loading && isAdmin && data.loan.user !== null && (
                <Tab>
                  <FileText />
                  Previous Loans
                </Tab>
              )}
              {!loading && data && data.loan && data.loan.paymentCycleSchedule && (
                <Tab>
                  <Calendar />
                  Payment Schedule
                </Tab>
              )}
            </TabList>
            <div className="tab-content-cls">
              <TabPanel>
                {!loading && (
                  <ApplicationTab
                    data={data.loan}
                    embedded
                    isAdmin={isAdmin}
                    me={me}
                    refetchLoan={refetchLoan}
                  />
                )}
              </TabPanel>
              {!loading && isAdmin && (
                <TabPanel>
                  <Notes id={data.loan.id} userid={me.id} />
                </TabPanel>
              )}
              {!loading && isAdmin && data.loan.user !== null && (
                <TabPanel>
                  <UserLoans userId={data.loan.user.id} />
                </TabPanel>
              )}
              {!loading && data && data.loan && data.loan.paymentCycleSchedule && (
                <TabPanel>
                  <Calendar1 customLoan={data.loan} />
                </TabPanel>
              )}
            </div>
          </Tabs>
        </div>
      </div>
    </Fragment>
  );
};

export default Application;
