import React from "react";
import "./style.css";

import { useUser } from "../../hooks";

const CreditCard = ({ onClick, name, amount, fee, selected, fullWidth, noMarginNoPadding}) => {
    const me = useUser();
    return (
        <div  className={`card-card ${selected ? 'card-selected' : ''} ${fullWidth ? 'card-full' : ''} `} onClick={onClick} style={{marginTop: !noMarginNoPadding ? "1em" : "", marginBottom: !noMarginNoPadding ? "1em" : ""}}>
        <div className="card-details">
            <div className="card-amount" title="BestBank">{amount.replace("Card Limit:","")} Limit</div>
            <div className="card-fee" title="BestBank">{fee} annual fee</div>
        </div>
        <div className="chip">
            <div className="side left"></div>
            <div className="side right"></div>
            <div className="vertical top"></div>
            <div className="vertical bottom"></div>
        </div>
        <div className="data">
            <div className="pan" title="4123 4567 8910 1112">4123 4567 8910 1112</div>
            <div className="first-digits">4123</div>
            <div className="exp-date-wrapper">
                <div className="left-label">EXPIRES END</div>
                <div className="exp-date">
                    <div className="upper-labels">MONTH/YEAR</div>
                    <div className="date" title="01/17">01/24</div>
                </div>
            </div>
            <div className="name-on-card" title="John Doe">{me.name.toUpperCase()}</div>
        </div>
        <div className="lines-down"></div>
        <div className="lines-up"></div>
    </div>
    )
}
export default CreditCard;