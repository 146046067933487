/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { useUser } from '../../../hooks';

const UserPanel = (props) => {
  const me = useUser();
  const isAdmin = me && (me.permissions.includes('ADMIN') || me.permissions.includes('ADJUDICATOR'));
  const isPartner = me && (me.permissions.includes('PARTNER'));

    const fname = me && me.name && me.name.split[0] ? me.name.split[0] : '';
    const lname = me && me.name && me.name.split[1] ? me.name.split[1] : '';
  
  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div>
          <img
            alt="#"
            className="img-60 rounded-circle lazyloaded blur-up"
            src={
              me && me.name.split(' ').length > 0
                ? `https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=${fname}+${lname}`
                : ''
            }
          />
          <div className="profile-edit">
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/me`}>
              <Edit />
            </Link>
          </div>
        </div>
        <h6 className="mt-3 f-14">{me &&me.name}</h6>
        <p>{isAdmin ? 'Admin' : isPartner ? 'Partner': 'User' }</p>
      </div>
    </Fragment>
  );
};

export default UserPanel;
