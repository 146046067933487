/* eslint-disable no-undef */
import React, { useState, Fragment } from "react";
import currency from "currency.js";

import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AdminMenu from "./actionmenu/adminMenu";
import UserMenu from "./actionmenu/userMenu";
import StaffMenu from "./actionmenu/staffMenu";

import moment from "moment";

import { useUser } from "../hooks";

import {
  Navigation,
  DollarSign,
  CheckCircle,
  XCircle,
  MoreHorizontal,
} from "react-feather";

import { Breadcrumb, DeletePaymentButton } from "../components";
import CountUp from "react-countup";
import formatMoney from "services/formatMoney";
import ScrollBars from "react-scrollbar";

var images = require.context("../assets/images", true);

const Application = ({ data, embedded, history, refetchLoan }) => {
  const me = useUser();


  const isAdmin = me.permissions.includes("ADMIN");
  const isManager = me.permissions.includes("MANAGER");
  const isAdjudicator = me.permissions.includes("ADJUDICATOR");

  const [modal, setModal] = useState();

  const [modalMeta, setModalMeta] = useState({
    url: [],
    title: null,
  });

  const toggle = (url, title) => {
    setModalMeta({
      url,
      title,
    });
    setModal(!modal);
  };

  const loanAmountPaid =
    data.payments.length > 0
      ? data.payments.map((p) => p.amount).reduce((prev, curr) => prev + curr)
      : 0;

  const realBalanceRemaining = currency(data.loanAmount)
    .add(data.loanFee)
    .subtract(loanAmountPaid).value;

  const balanceBeforePay = currency(data.loanAmount).add(data.loanFee).value;

  return (
    <Fragment>
      {embedded !== true && (
        <Breadcrumb parent="Applications" title="View Application" />
      )}

      <Modal fade={false} isOpen={modal} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalMeta.title}</ModalHeader>
        <ModalBody>
          <ScrollBars autoHide={false} horizontal>
            <div>
              {modalMeta &&
                modalMeta.url.length > 0 &&
                modalMeta.url.map((url, index) => (
                  <img
                    key={index}
                    alt="document"
                    className="img-fluid"
                    src={`${process.env.REACT_APP_BASE_URL_PROD}/${modalMeta.url[index]}`.replace(
                      "https://api.lendle.com/https://lendle.com",
                      "https://lendle.com"
                    )}
                  />
                ))}
            </div>
          </ScrollBars>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div className="container-fluid">
        {
          <div
            className="email-top"
            style={{
              top: "-52px",
              position: "absolute",
              right: 0,
              width: "auto",
              float: "right",
            }}
          >
            <div className="col text-right">
              {isAdmin && <AdminMenu meId={me.id} data={data} />}
              {(isAdjudicator || isManager) && (
                <StaffMenu meId={me.id} data={data} />
              )}
              {!isAdmin && !isManager && !isAdjudicator && (
                <UserMenu data={data} />
              )}
            </div>
          </div>
        }
        <div className="row">
          <div className="col-xl-6 xl-100">
            <div
              className="card cuanimated bounceInLeft"
              data-intro="This is University Earning Chart"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <div className="row">
                  <div className="col-sm-6">
                    <h5>Application Details</h5>
                  </div>
                  <div className="col-sm-6 text-right pull-right">
                    <small>
                      Application ID: <strong>#{data.id.toUpperCase()}</strong>
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <div className="form-group">
                        <label className="form-label">
                          <strong>Name</strong>
                        </label>
                        <br />
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.name}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Email Address</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.email.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Phone Number</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.phone.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Gender</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.gender.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Address</strong>
                      </label>
                      <br />
                      {data.residentialAddress !== null && (
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.residentialAddress.street},{" "}
                          {data.residentialAddress.city},{" "}
                          {data.residentialAddress.suite},{" "}
                          {data.residentialAddress.postal},{" "}
                          {data.residentialAddress.province},{" "}
                          {data.residentialAddress.country}
                        </span>
                      )}
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Purpose Of Borrow</strong>
                      </label>
                      <br />
                      <span>{data.purposeOfBorrow}</span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Reason / Explanation</strong>
                      </label>
                      <br />
                      <span>{data.borrowReason}</span>
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Campaign</strong>
                      </label>
                      <br />
                      <span>{data.campaign}</span>
                    </div>
                  </div>
                  <div className="col-sm-12" style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "orange",
                    padding: "1rem",
                    backgroundColor: "cornsilk"
                  }}>
                       Warning: This is the user's information provided at the time of their application when it was submitted. User information that is changed by the customer on their profile page will not update this application's details about the user   
                 </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-6 xl-100 cuanimated bounceInRight"
            id="animated-example"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media feather-main">
                      <div
                        className="feather-icon-block"
                        style={{
                          backgroundColor: (function () {
                            switch (data.status.toLowerCase()) {
                              case "approved":
                                return "#07cc07";
                              case "denied":
                                return "#f30202";
                              case "inreview":
                                return "#ff9f40";
                              case "withdraw":
                                return "#969dff";
                              default:
                                return "#b7b7b7";
                            }
                          })(),
                        }}
                      >
                        {data.status.toLowerCase() === "approved" && (
                          <CheckCircle />
                        )}
                        {data.status.toLowerCase() === "denied" && <XCircle />}
                        {data.status.toLowerCase() === "pending" && (
                          <MoreHorizontal />
                        )}
                        {data.status.toLowerCase() === "archive" && (
                          <MoreHorizontal />
                        )}
                        {data.status.toLowerCase() === "inreview" && (
                          <MoreHorizontal />
                        )}
                        {data.status.toLowerCase() === "withdraw" && (
                          <XCircle />
                        )}
                      </div>
                      <div className="media-body align-self-center">
                        <h6
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.status === "INREVIEW"
                            ? "In Review"
                            : data.status.toLowerCase()}
                        </h6>
                        <p>
                          {data.status === "INREVIEW" && data.adj !== null
                            ? `Assigned ${
                                data.adj.id === me.id
                                  ? "to you"
                                  : " to: " + data.adj.name.split(" ")[0]
                              }`
                            : "status"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media feather-main">
                      <div className="feather-icon-block">
                        <Navigation />
                      </div>
                      <div className="media-body align-self-center">
                        <h6>{data.paymentCycleDuration}</h6>
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "black",
                          }}
                        >
                          Term - Ends:{" "}
                          <b>{moment(data.paymentCycleEndDate).format("LL")}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media chart-university">
                      <div className="media-body">
                        <h3 className="mb-0">
                          <DollarSign />
                          <span className="counter">
                            <CountUp decimals={2} end={realBalanceRemaining} />
                          </span>
                        </h3>
                        <p>Balance Remaining</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media chart-university">
                      <div className="media-body">
                        <h3 className="mb-0">
                          <DollarSign />
                          <span className="counter">
                            <CountUp decimals={2} end={data.loanAmount} />
                          </span>
                        </h3>
                        <p>Amount Requested</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media chart-university">
                      <div className="media-body">
                        <h3 className="mb-0">
                          <DollarSign />
                          <span className="counter">
                            <CountUp
                              decimals={2}
                              end={data.paymentCycleAmount}
                            />
                          </span>
                        </h3>
                        <p
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.paymentCycleFrequency} - starts:{" "}
                          <b>
                            {moment(data.paymentCycleStartDate).format("LL")}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media chart-university">
                      <div className="media-body">
                        <h3 className="mb-0">
                          <DollarSign />
                          <span className="counter">
                            <CountUp
                              decimals={2}
                              end={currency(data.loanFee).value}
                              start={0}
                            />
                          </span>
                        </h3>
                        <p>Admin Fee (tax incl.)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-7 xl-50">
            <div
              className="card height-equal cuanimated bounceInLeft bounceInRight"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>APPLICATION PAYMENTS</h5>
              </div>
              <div className="card-body">
                <div className="user-status table-responsive">
                  {data.payments.length > 0 ? (
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Entry date</th>
                          <th scope="col">Ref #</th>
                          <th scope="col">Amount</th>
                          {(isAdmin || isAdjudicator) && (
                            <th scope="col">Profit</th>
                          )}
                          <th scope="col">Scheduled Date</th> 
                          <th scope="col">Status</th> 
                          <th scope="col">#</th> 
                        </tr>
                      </thead>
                      <tbody>
                        {data.payments.map((pay, index) => (
                          <tr key={index}>
                            <td className="font-primary">
                              {moment(pay.createdAt).format("LL")}
                            </td>
                            <td className="font-primary">
                              {pay.id.substr(0, 8)}
                            </td>
                            <td className="digits">
                              {formatMoney(pay.amount)}
                            </td>
                            {(isAdmin || isAdjudicator) && (
                              <td>
                                $
                                {
                                  currency(data.loanFee)
                                    .divide(balanceBeforePay)
                                    .multiply(pay.amount).value
                                }
                              </td>
                            )}
                            <td>{pay.paymentDate !== null && moment(pay.paymentDate).format("LL")}</td>
                            <td>{pay.status === "made" && "On Time"}{pay.status === "late" && "Late"}</td>
                            <td>
                              <DeletePaymentButton
                                onSuccess={() => refetchLoan()}
                                key={index}
                                id={pay.id}
                              />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td style={{borderTop:"2px solid #e8ebf2"}}></td>
                          <td style={{borderTop:"2px solid #e8ebf2"}}></td>
                          <td style={{borderTop:"2px solid #e8ebf2"}}>${currency(data.payments.reduce((prev, current) => {
                            return {
                              amount: prev.amount + current.amount
                            }
                          }).amount).format()}</td>
                          <td style={{borderTop:"2px solid #e8ebf2"}}></td>
                          <td style={{borderTop:"2px solid #e8ebf2"}}></td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <div
                      className="search-not-found text-center ng-star-inserted"
                      style={{
                        height: "300px",
                        marginTop: 0,
                      }}
                    >
                      <div className="">
                        <img
                          className="second-search"
                          src={images("./search-not-found.png")}
                          style={{ marginTop: "-50px" }}
                        />
                        <p className="mb-0">No Payments Found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {moment(data.createdAt, "YYYY-MM-DD").isBefore("2020-08-05") && (
            <div className="col-xl-5 xl-100">
              <div
                className="card height-equal cuanimated bounceInRight"
                id="animated-example"
              >
                <div className="card-header b-l-primary">
                  <h5>Documents</h5>
                </div>
                <div className="card-body">
                  <div className="new-users">
                    <div className="media">
                      {data.documents &&
                        data.documents.bankStatement.length > 0 &&
                        data.documents.bankStatement[0]
                          .split(".")
                          .pop()
                          .toLowerCase() !== "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.bankStatement[0]}`.replace(
                              "https://api.lendle.com/https://lendle.com",
                              "https://lendle.com"
                            )}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.bankStatement.length > 0 &&
                        data.documents.bankStatement[0]
                          .split(".")
                          .pop()
                          .toLowerCase() === "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={images("./pdf.png")}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.bankStatement.length === 0 && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={
                              "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=B"
                            }
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      <div className="media-body">
                        <h6 className="mb-0 f-w-700">Bank Statements</h6>
                      </div>
                      <span className="pull-right">
                        {data.documents &&
                        data.documents.bankStatement.length > 0 ? (
                          <button
                            className="btn btn-pill btn-outline-light"
                            onClick={() => {
                              if (
                                data.documents.bankStatement[0]
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf"
                              ) {
                                window.location.href = `${
                                  process.env.REACT_APP_BASE_URL_PROD
                                }/download?file=${encodeURI(
                                  data.documents.bankStatement[0].replace(
                                    "uploads/",
                                    ""
                                  )
                                )}`;
                              } else {
                                toggle(
                                  data.documents.bankStatement,
                                  "Bank Statement"
                                );
                              }
                            }}
                          >
                            View Doc
                          </button>
                        ) : (
                          <button className="btn btn-pill btn-outline-light">
                            Request / Upload
                          </button>
                        )}
                      </span>
                    </div>

                    <div className="media">
                      {data.documents &&
                        data.documents.govIssuedId.length > 0 &&
                        data.documents.govIssuedId[0]
                          .split(".")
                          .pop()
                          .toLowerCase() !== "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.govIssuedId[0]}`.replace(
                              "https://api.lendle.com/https://lendle.com",
                              "https://lendle.com"
                            )}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.govIssuedId.length > 0 &&
                        data.documents.govIssuedId[0]
                          .split(".")
                          .pop()
                          .toLowerCase() === "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={images("./pdf.png")}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        !data.documents.govIssuedId.length > 0 && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={
                              "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=G"
                            }
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      <div className="media-body">
                        <h6 className="mb-0 f-w-700">Gov Issued ID</h6>
                      </div>
                      <span className="pull-right">
                        {data.documents &&
                        data.documents.govIssuedId.length > 0 ? (
                          <button
                            className="btn btn-pill btn-outline-light"
                            onClick={() => {
                              if (
                                data.documents.govIssuedId[0]
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf"
                              ) {
                                window.location.href = `${
                                  process.env.REACT_APP_BASE_URL_PROD
                                }/download?file=${encodeURI(
                                  data.documents.govIssuedId[0].replace(
                                    "uploads/",
                                    ""
                                  )
                                )}`;
                              } else {
                                toggle(
                                  data.documents.govIssuedId,
                                  "Gov Issued Id"
                                );
                              }
                            }}
                          >
                            View Doc
                          </button>
                        ) : (
                          <button className="btn btn-pill btn-outline-light">
                            Request / Upload
                          </button>
                        )}
                      </span>
                    </div>

                    <div className="media">
                      {data.documents &&
                        data.documents.proofOfResidence.length > 0 &&
                        data.documents.proofOfResidence[0]
                          .split(".")
                          .pop()
                          .toLowerCase() !== "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.proofOfResidence[0]}`.replace(
                              "https://api.lendle.com/https://lendle.com",
                              "https://lendle.com"
                            )}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.proofOfResidence.length > 0 &&
                        data.documents.proofOfResidence[0]
                          .split(".")
                          .pop()
                          .toLowerCase() === "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={images("./pdf.png")}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        !data.documents.proofOfResidence.length > 0 && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={
                              "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=R"
                            }
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      <div className="media-body">
                        <h6 className="mb-0 f-w-700">Proof Of Residence</h6>
                      </div>
                      <span className="pull-right">
                        {data.documents &&
                        data.documents.proofOfResidence.length > 0 ? (
                          <button
                            className="btn btn-pill btn-outline-light"
                            onClick={() => {
                              if (
                                data.documents.proofOfResidence[0]
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf"
                              ) {
                                window.location.href = `${
                                  process.env.REACT_APP_BASE_URL_PROD
                                }/download?file=${encodeURI(
                                  data.documents.proofOfResidence[0].replace(
                                    "uploads/",
                                    ""
                                  )
                                )}`;
                              } else {
                                toggle(
                                  data.documents.proofOfResidence,
                                  "Proof Of Residence"
                                );
                              }
                            }}
                          >
                            View Doc
                          </button>
                        ) : (
                          <button className="btn btn-pill btn-outline-light">
                            Request / Upload
                          </button>
                        )}
                      </span>
                    </div>

                    <div className="media">
                      {data.documents &&
                        data.documents.directDepositForm.length > 0 &&
                        data.documents.directDepositForm[0]
                          .split(".")
                          .pop()
                          .toLowerCase() !== "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.directDepositForm[0]}`}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.directDepositForm.length > 0 &&
                        data.documents.directDepositForm[0]
                          .split(".")
                          .pop()
                          .toLowerCase() === "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={images("./pdf.png")}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        !data.documents.directDepositForm.length > 0 && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={
                              "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=D"
                            }
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      <div className="media-body">
                        <h6 className="mb-0 f-w-700">Direct Deposit Form</h6>
                      </div>
                      <span className="pull-right">
                        {data.documents &&
                        data.documents.directDepositForm.length > 0 ? (
                          <button
                            className="btn btn-pill btn-outline-light"
                            onClick={() => {
                              if (
                                data.documents.directDepositForm[0]
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf"
                              ) {
                                window.location.href = `${
                                  process.env.REACT_APP_BASE_URL_PROD
                                }/download?file=${encodeURI(
                                  data.documents.directDepositForm[0].replace(
                                    "uploads/",
                                    ""
                                  )
                                )}`;
                              } else {
                                toggle(
                                  data.documents.directDepositForm,
                                  "Direct Deposit Form"
                                );
                              }
                            }}
                          >
                            View Doc
                          </button>
                        ) : (
                          <button className="btn btn-pill btn-outline-light">
                            Request / Upload
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {moment(data.createdAt, "YYYY-MM-DD").isSameOrAfter("2020-08-05") && (
            <div className="col-xl-5 xl-100">
              <div
                className="card height-equal cuanimated bounceInRight"
                id="animated-example"
              >
                <div className="card-header b-l-primary">
                  <h5>Gov Issued Id</h5>
                </div>
                <div className="card-body">
                  <div className="new-users">
                    <div className="media">
                      {data.documents &&
                        data.documents.govIssuedId.length > 0 &&
                        data.documents.govIssuedId[0]
                          .split(".")
                          .pop()
                          .toLowerCase() !== "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.govIssuedId[0]}`.replace(
                              "https://api.lendle.com/https://lendle.com",
                              "https://lendle.com"
                            )}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.govIssuedId.length > 0 &&
                        data.documents.govIssuedId[0]
                          .split(".")
                          .pop()
                          .toLowerCase() === "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={images("./pdf.png")}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        !data.documents.govIssuedId.length > 0 && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={
                              "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=G"
                            }
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      <div className="media-body">
                        <h6 className="mb-0 f-w-700">Card Front</h6>
                      </div>
                      <span className="pull-right">
                        {data.documents &&
                        data.documents.govIssuedId.length > 0 ? (
                          <button
                            className="btn btn-pill btn-outline-light"
                            onClick={() => {
                              if (
                                data.documents.govIssuedId[0]
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf"
                              ) {
                                window.location.href = `${
                                  process.env.REACT_APP_BASE_URL_PROD
                                }/download?file=${encodeURI(
                                  data.documents.govIssuedId[0].replace(
                                    "uploads/",
                                    ""
                                  )
                                )}`;
                              } else {
                                toggle(
                                  data.documents.govIssuedId,
                                  "Gov Issued Id"
                                );
                              }
                            }}
                          >
                            View Doc
                          </button>
                        ) : (
                          <button className="btn btn-pill btn-outline-light">
                            Request / Upload
                          </button>
                        )}
                      </span>
                    </div>

                    <div className="media">
                      {data.documents &&
                        data.documents.proofOfResidence.length > 0 &&
                        data.documents.proofOfResidence[0]
                          .split(".")
                          .pop()
                          .toLowerCase() !== "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.proofOfResidence[0]}`.replace(
                              "https://api.lendle.com/https://lendle.com",
                              "https://lendle.com"
                            )}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        data.documents.proofOfResidence.length > 0 &&
                        data.documents.proofOfResidence[0]
                          .split(".")
                          .pop()
                          .toLowerCase() === "pdf" && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={images("./pdf.png")}
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      {data.documents &&
                        !data.documents.proofOfResidence.length > 0 && (
                          <img
                            alt=""
                            className="rounded-circle image-radius m-r-15"
                            src={
                              "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=R"
                            }
                            style={{
                              height: "58px",
                            }}
                          />
                        )}
                      <div className="media-body">
                        <h6 className="mb-0 f-w-700">Card Back</h6>
                      </div>
                      <span className="pull-right">
                        {data.documents &&
                        data.documents.proofOfResidence.length > 0 ? (
                          <button
                            className="btn btn-pill btn-outline-light"
                            onClick={() => {
                              if (
                                data.documents.proofOfResidence[0]
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf"
                              ) {
                                window.location.href = `${
                                  process.env.REACT_APP_BASE_URL_PROD
                                }/download?file=${encodeURI(
                                  data.documents.proofOfResidence[0].replace(
                                    "uploads/",
                                    ""
                                  )
                                )}`;
                              } else {
                                toggle(
                                  data.documents.proofOfResidence,
                                  "Gov Issued Id"
                                );
                              }
                            }}
                          >
                            View Doc
                          </button>
                        ) : (
                          <button className="btn btn-pill btn-outline-light">
                            Request / Upload
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-sm-12">
            <div
              className="card height-equal cuanimated bounceInRight"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>Employment And Income</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Description</th>
                        <th scope="col">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.employmentAndIncome.length > 0 &&
                        data.employmentAndIncome.map((row, index) => (
                          <tr key={index}>
                            <th scope="row">{index}</th>
                            <td>{row.title}</td>
                            <td>{row.desc}</td>
                            <td>
                              {row.meta === null ||
                              row.meta === undefined ||
                              row.meta === ""
                                ? ""
                                : row.meta}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Application);
