import React, { Fragment, useEffect } from "react";

// Client
import { withApollo } from '@apollo/client/react/hoc';
// Auth hooks
import { useUser } from "../../../hooks";

// Components
import SignInForm from "./SignInForm";

// Assets
import comingsoon from "../../../assets/images/other-images/coming-soon-bg.jpg";
import authVideo from "../../../assets/video/auth-bg.mp4";
import brandLogo from "../../../assets/images/logo-3.png";

// Css
import "react-toastify/dist/ReactToastify.css";

const SignIn = ({ history, client }) => {
  const user = useUser();

  if (user !== undefined && user !== null) {
    history.push("/dashboard/overview");
  }

  useEffect(() => {
    if (user !== undefined && user !== null) {
      history.push("/dashboard/overview");
    }
    
  }, [user, history]);

  const onSuccess = () => {
    client.cache.reset();
    history.push(`${process.env.PUBLIC_URL}/dashboard/overview`);
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div className="auth-bg">
            <div className="authentication-box">
              <div className="text-center">
                <a href="https://lendle.com">
                  <img alt="Lendle Inc." src={brandLogo} />
                </a>
              </div>
              <div className="card mt-4">
                <div className="card-body">
                  <div className="text-center">
                    <h4>LOGIN</h4>
                    <h6>Enter your Username and Password </h6>
                  </div>
                  <SignInForm onSuccess={onSuccess} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withApollo(SignIn);
