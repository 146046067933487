/* eslint-disable react/no-set-state */
import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import SearchDatatable from "./searchDatatable";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";

export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      filteredData: [],
      searchInput: "",
    };
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleSetData = (myData) => {
    this.setState({ myData });
  };

  handleSetFilteredData = (filteredData) => {
    this.setState({ filteredData });
  };

  handleSetSearchInput = (searchInput) => {
    this.setState({ searchInput });
  };

  render() {
    const { pageSize, minRows, myClass, pagination, hideAction, hideSearch } = this.props;
    const { myData, filteredData, searchInput } = this.state;
    const dataToDisplay = searchInput.length ? filteredData : myData;
    const { searchComponent: SearchComponent } = this.props;

    const columns = [];
    for (var key in myData[0]) {
      if (key === "Status") {
        if (isMobileOnly) {
          columns.push({
            Header: <b>{this.Capitalize(key.toString())}</b>,
            accessor: key,
            Cell: (row) => row.original["Status"],
            style: {
              textAlign: "center",
            },
            sortable: false,
          });
    }else if(key === "Download Tape") {
      
    } else {
          columns.push({
            Header: <b>{this.Capitalize(key.toString())}</b>,
            accessor: key,
            Cell: (row) => {
              let btnClass = "";
              switch (row.original["Status"].toLowerCase()) {
                case "approved":
                  btnClass = "btn-success";
                  break;
                case "card_issued":
                  btnClass = "btn-success";
                  break;
                case "active":
                  btnClass = "btn-success";
                  break;
                case "denied":
                  btnClass = "btn-danger";
                  break;
                case "deleted":
                  btnClass = "btn-danger";
                  break;
                case "inreview":
                  btnClass = "btn-warning";
                  break;
                case "withdraw":
                  btnClass = "btn-purple";
                  break;
                default:
                  btnClass = "btn-light active txt-dark";
                  break;
              }
              return (
                <button className={`btn ${btnClass} btn-pill`} type="button">
                  {row.original["Status"]}
                </button>
              );
            },
            style: {
              textAlign: "center",
            },
            sortable: true,
          });
        }
      } else if (key === "Date") {
        columns.push({
          Header: <b>Date</b>,
          id: "date_created",
          accessor: (row) => moment(row["Date"]).format("x"),
          Cell: (row) => {
            return moment(row.original["Date"]).format("LL");
          },
          style: {
            textAlign: "center",
          },
        });
      } else {
        columns.push({
          Header: <b>{this.Capitalize(key.toString())}</b>,
          accessor: key,
          Cell: null,
          style: {
            textAlign: "center",
          },
        });
      }
    }

   if(!hideAction){
    columns.push({
      Header: <b>Action</b>,
      id: "delete",
      accessor: () => "delete",
      Cell: (row) => (
        <Link
          to={`${process.env.PUBLIC_URL}/dashboard/${this.props.routeUrl}/${row.original["ID"]}`}
        >
          <button className="btn btn-pill btn-outline-light">View</button>
        </Link>
      ),
      style: {
        textAlign: "center",
      },
      sortable: false,
    });
   }

    return (
      <Fragment>
        {
          SearchComponent ? 
          <SearchComponent columns={columns.filter((row) => row.id !== "delete")}
            data={this.state.myData}
            handleSetFilteredData={this.handleSetFilteredData}
            handleSetSearchInput={this.handleSetSearchInput} /> 
          
          : !hideSearch ? 
          
          <SearchDatatable
            columns={columns.filter((row) => row.id !== "delete")}
            data={this.state.myData}
            handleSetFilteredData={this.handleSetFilteredData}
            handleSetSearchInput={this.handleSetSearchInput}
        /> : ""
        }
        <ReactTable
          className={myClass}
          columns={columns}
          data={dataToDisplay}
          minRows={minRows}
          defaultPageSize={pageSize}
          showPagination={pagination}
        />
      </Fragment>
    );
  }
}

export default Datatable;
