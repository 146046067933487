/* eslint-disable react/no-set-state */
import { createSourceEventStream } from 'graphql';
import React from 'react';

export default class SearchDatatable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
      columnSearch: '',
      searchInput: ''
    };
  }

  handleChange = event => {
    const val = event.target.value;
    this.setState({ searchInput: val }, () => this.globalSearch());
    this.props.handleSetSearchInput(val);
  };

  globalSearch = () => {
    const { searchInput, columnSearch } = this.state;
    let filteredData = this.props.data.filter(value => {
      if (columnSearch) {
        return value[columnSearch]
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }

      const adj = value['Adjudicator'] ? value['Adjudicator'].toLowerCase().includes(searchInput.toLowerCase()) : false;

      const user = value['User'] ? value['User'].toLowerCase().includes(searchInput.toLowerCase()) : false;
      const amount = value['Amount'] ? value['Amount'].toString().toLowerCase().includes(searchInput.toLowerCase()) : false;
      const email = value['Email'] ? value['Email'].toString().toLowerCase().includes(searchInput.toLowerCase()) : false;
      const status = value['Status'] ?  value['Status'].toLowerCase().includes(searchInput.toLowerCase()) : false;

      const desc = value['Transaction Name'] ?  value['Transaction Name'].toLowerCase().includes(searchInput.toLowerCase()) : false;
      const amount2 = value['Transaction Amount'] ?  value['ransaction Amount'].toLowerCase().includes(searchInput.toLowerCase()) : false;
      const source = value['Transaction Source'] ?  value['Transaction Source'].toLowerCase().includes(searchInput.toLowerCase()) : false;

      const date = value['Date'] ?  value['Date'].toLowerCase().includes(searchInput.toLowerCase()) : false;



      return (
        value['ID'].toLowerCase().includes(searchInput.toLowerCase()) ||
        adj ||
        user ||
        adj ||
        email || 
        status ||
        date ||
        desc ||
        amount2 ||
        source ||
        amount
      );
    });

    this.props.handleSetFilteredData(filteredData);
  };

  setColumnSearch = e => {
    this.setState({ columnSearch: e.target.value }, () => this.globalSearch());
  };

  render() {
    const { columns } = this.props;
    const { columnSearch } = this.state;

    return (
      <>
        <br />
        <div
          className="d-flex flex-row justify-content-end"
        >
          <input
            className="form-control mr-2"
            label="Search"
            name="searchInput"
            onChange={this.handleChange}
            placeholder="Search..."
            style={{width: 'auto'}}
            value={this.state.searchInput || ''}
          />
          <select
            className="form-control"
            onChange={e => {
              e.persist();
              this.setColumnSearch(e);
            }}
            style={{
              width: 'auto',
              minWidth: '150px'
            }}
            value={columnSearch}
          >
            <option>All columns</option>
            {columns.map((col, index) => {
              return <option key={index} value={col.accessor}>{col.accessor}</option>;
            })}
          </select>
        </div>
        <br />
        <br />
      </>
    );
  }
}
