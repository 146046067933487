import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { ScrollContext } from "react-router-scroll-4";
import { AuthRoute } from "../util";

// Auth
import UnlockUser from "../pages/unlockUser";

// Screens: old way
import Applications from "../pages/applicationsPage";
import ViewApplication from "../pages/applicationPage";
import Documents from "../pages/documentsPage";
import Calendar from "../pages/calenderPage";
import Inbox from "../pages/inbox";
import ComingSoon from "../pages/maintenance";
import GuestDocUpload from "../pages/documents";
import VerifyApp from "../pages/verifyapp";

// Screens: migrating to this format
import {
  Apply,
  CardApply,
  CardApplications,
  DashboardView,
  LeadsList,
  LeadItem,
  SignIn,
  Me,
  MyCard,
  User,
  Users,
  MastercardSignup,
  MastercardLeads,
  MastercardLead,
  MyReferrals,
  LoanTape,
  LoanTapes
} from "../screens";

const Routes = () => (
  <BrowserRouter basename={"/"}>
    <ScrollContext>
      <Switch>
        <Route component={SignIn} path={`${process.env.PUBLIC_URL}/login`} />
        <Route
          component={ComingSoon}
          path={`${process.env.PUBLIC_URL}/comingsoon`}
        />

        <Route
          component={GuestDocUpload}
          exact
          path={`${process.env.PUBLIC_URL}/docupload/:id`}
        />
        <Route
          component={UnlockUser}
          path={`${process.env.PUBLIC_URL}/pages/unlockuser`}
        />

        <Route
          component={VerifyApp}
          path={`${process.env.PUBLIC_URL}/verifyapp`}
        />
        <Route
          component={MastercardSignup}
          path={`${process.env.PUBLIC_URL}/mastercard`}
        />
        <AuthRoute
          component={DashboardView}
          path={`${process.env.PUBLIC_URL}/dashboard/overview`}
        />
        <AuthRoute
          component={Documents}
          path={`${process.env.PUBLIC_URL}/dashboard/documents`}
        />
        <AuthRoute
          component={Apply}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/request`}
        />
        <AuthRoute
          component={Calendar}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/calendar`}
        />
        <AuthRoute
          component={Me}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/me`}
        />
        <AuthRoute
          admin
          component={Users}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/users`}
        />
        <AuthRoute
          admin
          component={User}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/users/:id`}
        />
        <AuthRoute
          component={Inbox}
          exact
          path={`${process.env.PUBLIC_URL}/inbox/:id`}
        />
        <AuthRoute
          component={Inbox}
          exact
          path={`${process.env.PUBLIC_URL}/inbox`}
        />
        <AuthRoute
          component={ViewApplication}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/applications/:id`}
        />
        <AuthRoute
          component={Applications}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/applications`}
        />
        <AuthRoute
          component={LeadsList}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/marketing`}
        />
        <AuthRoute
          component={LeadsList}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/leads`}
        />
        <AuthRoute
          component={LeadItem}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/leads/:id`}
        />
        <Route
          component={VerifyApp}
          path={`${process.env.PUBLIC_URL}/dashboard/verifyapp`}
        />
        <AuthRoute
          component={CardApplications}
          exact
          path={`${process.env.PUBLIC_URL}/card`}
        />
        <AuthRoute
          component={CardApply}
          exact
          path={`${process.env.PUBLIC_URL}/card/apply`}
        />
         <AuthRoute
          component={MastercardLeads}
          exact
          path={`${process.env.PUBLIC_URL}/card/leads`}
        />
         <AuthRoute
          component={MyReferrals}
          exact
          path={`${process.env.PUBLIC_URL}/card/referrals`}
        />
         <AuthRoute
          component={MastercardLead}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/card/leads/:id`}
        />
        <AuthRoute
          component={MyCard}
          exact
          path={`${process.env.PUBLIC_URL}/dashboard/card/:id`}
        />
        <AuthRoute
          component={LoanTape}
          exact
          path={`${process.env.PUBLIC_URL}/tape`}
        />
        <AuthRoute
          component={LoanTapes}
          exact
          path={`${process.env.PUBLIC_URL}/tape/history`}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          render={() => {
            return (
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard/overview`} />
            );
          }}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/completesignup`}
          render={(props) => {
            return (
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard/overview${props.location.search}`} />
            );
          }}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashboard`}
          render={() => {
            return (
              <Redirect to={`${process.env.PUBLIC_URL}/dashboard/overview`} />
            );
          }}
        />
      </Switch>
    </ScrollContext>
  </BrowserRouter>
);

export default Routes;
