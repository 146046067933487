import React, { Fragment } from 'react';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';
import en from '../../../assets/i18n/en.json';
import es from '../../../assets/i18n/es.json';
import pt from '../../../assets/i18n/pt.json';
import fr from '../../../assets/i18n/fr.json';

setTranslations({ en, es, pt, fr });
setDefaultLanguage('en');
setLanguageCookie();

const Language = () => {
  const handleSetLanguage = (key) => {
    setLanguage(key);
  };
  return (
    <Fragment>
      <div>
        <ul className="language-dropdown onhover-show-div p-20">
          <li onClick={() => handleSetLanguage('en')}>
            <a
              data-lng="en"
              href="#javascript"
            >
              <i className="flag-icon flag-icon-is" /> English
            </a>
          </li>
          <li onClick={() => handleSetLanguage('es')}>
            <a
              data-lng="es"
              href="#javascript"
            >
              <i className="flag-icon flag-icon-um" /> Spanish
            </a>
          </li>
          <li onClick={() => handleSetLanguage('pt')}>
            <a
              data-lng="pt"
              href="#javascript"
            >
              <i className="flag-icon flag-icon-uy" /> Portuguese
            </a>
          </li>
          <li onClick={() => handleSetLanguage('fr')}>
            <a
              data-lng="fr"
              href="#javascript"
            >
              <i className="flag-icon flag-icon-nz" /> French
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};


export default translate(Language);