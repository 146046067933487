import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Papa from "papaparse";
import currency from "currency.js";

import * as xlsx from "xlsx";

import {
  LOAN_TAPES_ADD,
  LOAN_TAPES
} from "../graphql";

const UploadLoanTape = ({ card }) => {
  const [modal, toggle] = useState(false);

  const [addLoanTape, { loading }] = useMutation(
    LOAN_TAPES_ADD
  );

  const { register, handleSubmit, errors, reset } = useForm();

  const handleAddTape = async (data) => {
    if (data === "") {
      errors.showMessages();
      return;
    }
    try {

      const reader = new FileReader();
      reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const csv = xlsx.utils.sheet_to_csv(worksheet);

          const jsonResult = Papa.parse(csv)
          const totalBorrowed = jsonResult.data.map(item => item[3].trim().replace("$", "").replace(/,/g, '')).filter((item) => item !== 'AMOUNT BORROWED').reduce((a,b) => currency(a).add(b).value)
          const totalPayback = jsonResult.data.map(item => item[5].trim().replace("$", "").replace(/,/g, '')).filter((item) => item !== 'AMOUNT BORROWED').reduce((a,b) => currency(a).add(b).value)
          const adminFee = jsonResult.data.map(item => item[4].trim().replace("$", "").replace(/,/g, '')).filter((item) => item !== 'AMOUNT BORROWED').reduce((a,b) => currency(a).add(b).value)


          console.log(totalBorrowed);
          console.log(totalPayback);
          console.log(adminFee);
          await addLoanTape({
            variables: {
              items: csv,
              totalBorrowed,
              totalPayback,
              adminFee
            },
            refetchQueries: [
              {
                query: LOAN_TAPES,
              },
            ],
          })
            
          reset({
            ...data,
          });

          toast.success("Successfully Uploaded Tape");
          toggleModal(!modal);

       };
      reader.readAsArrayBuffer(data.upload[0]);
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };
  const toggleModal = () => toggle(!modal);

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} size="lg">
        <ModalHeader>Edit Information</ModalHeader>
        <ModalBody>
          {loading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          <div className="card">
            <form
              className={" needs-validation tooltip-validation"}
              noValidate=""
              onSubmit={handleSubmit(handleAddTape)}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="validationTooltip01"
                      >
                        Excel File
                      </label>
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            ref={register({
                                required: true,
                              })}
                        />
                      {errors.upload && <span>File is required</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-prev btn-success btn-lg pull-right"
                >
                  Upload Tape
                </button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <button style={{backgroundColor: "#007bff", color: "white", fontWeight: "bold", padding: '0.4rem 1rem', borderRadius: '4px'}} disabled={loading} onClick={toggleModal}>
        Upload Loan Tape
      </button>
    </React.Fragment>
  );
};

export default withRouter(UploadLoanTape);
