import React from "react";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";

import {
  FormCardSelect,
  FormPhoneInput,
} from "../../../../components";

import { useWindowResize } from "../../../../hooks";

import { cardOptions } from "../../../../constant/constants";

const CardSelect = ({
  wizardData,
  setWizardData,
  navigation: { next, previous },
}) => {

  const size = useWindowResize();
  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    defaultValues: {
      cardType: "$300",
      ...wizardData,
    },
  });

 
  const onSubmit = (data) => {
    setWizardData({ ...wizardData, ...data });
    next();
  };

  return (
    <div className="card">
      <form
        className={"needs-validation tooltip-validation"}
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card-header">
          <h5>Step 3 - Select Your Secured Mastercard</h5>
        </div>
        <div className="card-body">
          <h6>
            <strong>Select Credit Card</strong>
          </h6>
          <span style={{
            display: "block",
            padding: "10px 10px 10px 0px",
            textDecoration: "underline"
          }}>A one time $19.99 activation fee will apply. Our Secured Mastercard has an annual fee of $120.</span>
         <div class="row">
         <div className="col-sm-12 col-md-6 mt-2 mb-3">
           <Controller
              as={<Select placeholder="Select Card Limit" />}
              control={control}
              name="cardType"
              options={cardOptions}
              placeholder="Select Card Limit"
              rules={{ validate: (select) => select.value !== undefined, required: true }}
            />
            {errors.cardType && <><span style={{color: "red"}} className="mb-3">Please select your secured card limit</span><br /> <br /></>}     
            </div>
         </div>
          <h6>
            <strong>Monthly Income</strong>
          </h6>
          <div className="d-flex mt-3 mb-3">
                  <input
                    className="form-control"
                    id="validationTooltip05"
                    name="monthlyIncome"
                    maxLength={150}
                    placeholder="Example: $1000.00"
                    ref={register({ required: true })}
                    type="number"
                  />
          </div>
          {errors.monthlyIncome && <><span style={{color: "red"}} className="mb-3">Monthly income is required</span><br /> <br /></>}

          <h6>
            <strong>Employment Information</strong>
          </h6>
              <div className={`d-flex ${size.width < 600 ? "flex-column" : "flex-row"} mt-3 mb-3`}>
              <input
                type="text"
                name="employerName"
                placeholder="Employer's Name (Business Name)"
                className="form-control col-md-3 mr-2 mb-2"
                ref={register({ required: true })}              />
               <FormPhoneInput
                  control={control}
                  name="employerPhone"
                  rules={{
                    required: true,
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                />
              
              <input
                type="text"
                name="employerJobTitle"
                placeholder="Your Job Title"
                className={`form-control col-md-3 ${size.width < 600 ? "" : "ml-2"} mr-2 mb-2`}
                ref={register({ required: true })}              />
                            
          </div>
          {errors.employerName && <><span style={{color: "red"}}>Employer name is required</span><br /></>}
          {errors.employerPhone && <><span style={{color: "red"}}>Employer phone is required</span><br /></>}
          {errors.employerJobTitle && <><span style={{color: "red"}}>Job title is required</span><br /></>}
          </div>

          <div style={{
    background: "rgb(246 246 255)",
    display: 'inline-block',
    padding: "13px",
    textAlign: "justify"
          }}>
                      <strong style={{
    textAlign: "center",
    display: "inline-block",
    width: "100%"}}>HOW DOES A SECURED MASTERCARD WORK?</strong>
<p>A Secured Mastercard requires a one-time deposit (collateral) that is held in trust, <strong>IT IS NOT HELD ON THE CARD</strong>. This deposit is to ensure you are committed to the card; in case you default it will cover the card. We will then issue a credit limit for you to use, just like any other credit card. As you build your credit history, we will guarantee an annual limit increase without taking any further deposits (collateral).</p>
                    </div>
        <div className="card-footer mb-5">
          <button
            className="btn btn-prev btn-default btn-lg pull-left"
            onClick={previous}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-prev btn-success btn-lg pull-right"
            onClick={() => handleSubmit(onSubmit)}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default CardSelect;
