/* eslint-disable no-case-declarations */
import Moment from "moment";
import { extendMoment } from "moment-range";
import currency from "currency.js";

const moment = extendMoment(Moment);

const every2Weeks = (start, end) => {
  var startDate = moment(start);
  var endDate = moment(end);
  var datesBetween = [];

  var startingMoment = startDate;

  while (startingMoment <= endDate) {
    datesBetween.push(startingMoment.clone().format("L")); // clone to add new object
    startingMoment.add(2, "weeks");
  }
  return datesBetween;
};
export default function loanCalc(amount, term, frequency, startDate) {
  if (!amount || !term || !frequency) {
    return {};
  }
  if (startDate === "Invalid date") {
    // not a date
    return {};
  }
  const totalSelected = currency(amount);
  const monthSelected = term;
  let feePercent = monthSelected === 3 || monthSelected === 4 ? 0.15 : 0.25;
  const feeTotal = totalSelected.multiply(feePercent).add(17); // Administrative fee times totalSelected
  const feeTax = feeTotal.multiply(0.13);

  let realAdminFee = feeTotal.add(feeTax); // total admin fee tax included.
  let realTotal = totalSelected.add(realAdminFee); // total amount owed

  const paymentStartDate = moment(startDate); // Date which the payment cycle will begin
  const paymentEndDate = paymentStartDate.clone().add(monthSelected, "months"); // The current date of today
  const paymentFrequency = frequency;

  let paymentScheduleDates = [];

  switch (paymentFrequency) {
    case "weekly":
      const range = moment.range(paymentStartDate, paymentEndDate);
      for (let weeks of range.by("weeks")) {
        paymentScheduleDates.push(weeks.format("L"));
      }
      break;
    case "biweekly":
      paymentScheduleDates = every2Weeks(paymentStartDate, paymentEndDate);
      break;
    case "monthly":
      var i;
      var startingDate = paymentStartDate.clone().subtract(1, "month");
      for (i = 0; i < monthSelected; i++) {
        paymentScheduleDates.push(startingDate.add(1, "month").format("LL"));
      }
      break;
    default:
      return paymentScheduleDates;
  }
  let realPaymentCycleAmount = realTotal.divide(paymentScheduleDates.length);

  return {
    loanAmount: totalSelected,
    loanFee: realAdminFee,
    loanFeeTax: feeTax,
    loanTerm: term,
    paymentCycleStartDate: paymentStartDate.format(),
    paymentCycleEndDate: paymentEndDate.format(),
    paymentCycleSchedule: paymentScheduleDates.map((date) =>
      moment(date).format()
    ),
    paymentCycleFrequency: paymentFrequency,
    paymentCycleAmount: realPaymentCycleAmount,
  };
}
