import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import Select from "react-select";
import ReactDatePicker from "./ReactDatePicker";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useUser } from "../hooks";
import {
  LOAN_ADD_PAYMENT,
  USER_LOAN_QUERY,
  USER_LOAN_QUERY_BYID,
  USER_PROFILE_QUERY,
  DC_CANCEL_CARD,
  DC_ACTIVATE_CARD
} from "../graphql";


const ActivateCard = ({ card, component: button }) => {
  
  const user = useUser();

  const [modal, toggleModal] = useState(false);
  const [validateClass, setValidateClass] = useState(false);

  const [getCard, { loading }] = useMutation(DC_ACTIVATE_CARD);

  const issueCard = async () => {
    try {
      await getCard({
        variables: {
          applicationId: card.cardApplication.id,
          customerNumber: card.cardApplication.user.customerNumber,
          lastFourDigits: parseInt(card.cardApplication.lastFourDigits)
        }
      })
      toast.success("Successfully Activated Card");
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  }
  return (
    <React.Fragment>
      <button onClick={() => issueCard()}>Activate Card</button>
    </React.Fragment>
  );
};

export default ActivateCard;
