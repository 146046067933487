import React, { memo } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal-root");

const Modal = memo(({ isOpen, toggle, children }) => {
  return isOpen
    ? createPortal(<React.Fragment>{children}</React.Fragment>, modalRoot)
    : null;
});

export default Modal;
