export const steps = [
  {
    id: "Step 1",
    title: "Welcome",
  },
  {
    id: "Step 2",
    title: "Basic Info",
  },
  {
    id: "Step 3",
    title: "Loan Terms",
  },
  {
    id: "Step 4",
    title: "ID Upload",
  },
  {
    id: "Step 5",
    title: "Authorization",
  },
  {
    id: "Step 6",
    title: "Complete",
  },
];

export const stepsCard = [
  {
    id: "Step 1",
    title: "Welcome",
  },
  {
    id: "Step 2",
    title: "Basic Info",
  },
  {
    id: "Step 3",
    title: "Card Select",
  },
  {
    id: "Step 4",
    title: "ID Upload",
  },
  {
    id: "Step 5",
    title: "Complete",
  },
];

export const termOptions = [
  {
    id: 1,
    label: "3 Months",
    term: 3,
    description: "Select this option to pay back funds within 3 months.",
  },
  {
    id: 2,
    label: "6 Months",
    term: 6,
    description: "Select this option to pay back funds within 6 months.",
  },
];

export const cardOptions = [
  {
    label: "$300",
    value: "$300",
  },
  {
    label: "$400",
    value: "$400",
  },
  {
    label: "$500",
    value: "$500",
  },
  {
    label: "$600",
    value: "$600",
  },
  {
    label: "$700",
    value: "$700",
  },
  {
    label: "$800",
    value: "$800",
  },
  {
    label: "$900",
    value: "$900",
  },
  {
    label: "$1000",
    value: "$1000",
  },
];


export const purposeOptions = [
  { value: "Future Needs", label: "Future Needs" },
  {
    value: "Home Renovations / Maintenance",
    label: "Home Renovations / Maintenance",
  },
  {
    value: "Vacation / Travel",
    label: "Vacation / Travel",
  },
  {
    value: "Vehicle Purchase",
    label: "Vehicle Purchase",
  },
  {
    value: "Major Expenses e.g. appliances, wedding",
    label: "Major Expenses e.g. appliances, wedding",
  },
  { value: "Investements", label: "Investements" },
  {
    value: "Balance of Account Transfer",
    label: "Balance of Account Transfer",
  },
  {
    value: "Personal / Family Emergency",
    label: "Personal / Family Emergency",
  },
  {
    value: "Education/Tuition",
    label: "Education/Tuition",
  },
  {
    value: "Debt Consolidation",
    label: "Debt Consolidation",
  },
];

export const employmentTypePlaceholders = {
  Employed: "i.e: Driver, Teacher, Janitor..etc",
  Student: "i.e: York University",
  "Government Assistance": "i.e: Child Support, CPP..etc",
  "Secondary Income": "i.e: Part time job, evening shifts",
};
