import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import Select from "react-select";
import ReactDatePicker from "./ReactDatePicker";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useUser } from "../hooks";
import {
  LOAN_ADD_PAYMENT,
  USER_LOAN_QUERY,
  USER_LOAN_QUERY_BYID,
  USER_PROFILE_QUERY,
  DC_ISSUE_CARD
} from "../graphql";


const IssueCard = ({ card, component: button }) => {
  
  const user = useUser();

  const [modal, toggleModal] = useState(false);
  const [validateClass, setValidateClass] = useState(false);

  const [issueTheCard, { loading }] = useMutation(DC_ISSUE_CARD);

  const issueCard = async () => {
    try {
      await issueTheCard({
        variables: {
          userId: card.cardApplication.user.id,
          customerId: parseInt(card.cardApplication.user.customerId),
          customerNumber: card.cardApplication.user.customerNumber,
          applicationId: card.cardApplication.id,
          walletId: card.cardApplication.user.walletId
        }
      })
      toast.success("Successfully Issued Card");
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  }
  return (
    <React.Fragment>
      <button disabled={loading} onClick={() => issueCard()}>{loading ? "Issuing Card..." : "Issue Card"}</button>
    </React.Fragment>
  );
};

export default IssueCard;
