/* eslint-disable no-undef */
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Application from "./Application";
import DCBank from "../DCBank";
import Comments from "./Comments";
import { useQuery } from "@apollo/client";

import { Breadcrumb } from "../../components";
import { CARD_REQUEST_BYID_QUERY } from "../../graphql";

import ScrollBars from "react-scrollbar";

import { useUser } from "../../hooks";

const MyCard = ({ match }) => {
  const me = useUser();

  const id = match.params.id;
  const { data, loading, refetch } = useQuery(CARD_REQUEST_BYID_QUERY, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });


  const isAdmin = me.permissions.includes("ADMIN");
  const isAdjudicator = me.permissions.includes("ADJUDICATOR");

  const [modal, setModal] = useState();
  const [modalMeta, setModalMeta] = useState({
    url: [],
    title: null,
    campaign: "",
  });

  const toggle = (url, title, campaign) => {
    setModalMeta({
      url,
      title,
      campaign,
    });
    setModal(!modal);
  };

  if (loading) {
    return "...";
  }

  return (
    <Fragment>
      <Breadcrumb parent="My Card" title="Lendle Credit Card" />
      <Modal fade={false} isOpen={modal} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalMeta.title}</ModalHeader>
        <ModalBody>
          <ScrollBars autoHide={false} horizontal>
            <div>
              {modalMeta &&
                modalMeta.url.length > 0 &&
                modalMeta.url.map((url, index) => (
                  <img
                    alt="document"
                    className="img-fluid"
                    src={`${
                      modalMeta.campaign === "secured_mastercard"
                        ? "https://lendle.com/api/src"
                        : process.env.REACT_APP_BASE_URL_PROD
                    }/${modalMeta.url[index]}`.replace(
                      "https://api.lendle.com/https://lendle.com",
                      "https://lendle.com"
                    )}
                  />
                ))}
            </div>
          </ScrollBars>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Tabs>
        <TabList>
          <Tab>Application</Tab>
          {(!loading && data && (isAdmin || isAdjudicator)) && <Tab>Comments</Tab>}
          {(!loading && data && isAdmin && data.cardApplication.user.customerId !== null) && <Tab>DC Bank</Tab>}
        </TabList>

        <TabPanel>
          {!loading && data && <Application data={data} refetch={refetch} />}
        </TabPanel>
        <TabPanel> <Comments match={match} /></TabPanel>
        <TabPanel>{(!loading && data && isAdmin && data.cardApplication.user.customerId !== null) && <DCBank data={data} />}</TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default withRouter(MyCard);
