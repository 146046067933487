import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const FormProvinceSelect = ({ control, rules }) => {
  const options = [
    {
      label: "Ontario",
      value: "Ontario",
    },
    {
      label: "Quebec",
      value: "Quebec",
    },
    {
      label: "Nova Scotia",
      value: "Nova Scotia",
    },
    {
      label: "New Brunswick",
      value: "New Brunswick",
    },
    {
      label: "Manitoba",
      value: "Manitoba",
    },
    {
      label: "British Columbia",
      value: "British Columbia",
    },
    {
      label: "Prince Edward Island",
      value: "Prince Edward Island",
    },
    {
      label: "Saskatchewan",
      value: "Saskatchewan",
    },
    {
      label: "Alberta",
      value: "Alberta",
    },
    {
      label: "Newfoundland and Labrador",
      value: "Newfoundland and Labrador",
    },
    {
      label: "Northwest Territories",
      value: "Northwest Territories",
    },
    {
      label: "Yukon",
      value: "Yukon",
    },
    {
      label: "Nunavut",
      value: "Nunavut",
    },
  ];
  return (
    <Controller
      as={Select}
      control={control}
      name="province"
      options={options}
      placeholder="Select Province"
      rules={rules}
    />
  );
};

export default FormProvinceSelect;
