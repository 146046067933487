import { useQuery } from "@apollo/client";
import { ADMIN_PROFILE_BYID_QUERY } from "../graphql";

const useUserById = (id) => {
  const { data } = useQuery(ADMIN_PROFILE_BYID_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      id,
    },
  });
  if (data) {
    return data.user;
  }
};

export default useUserById;
