export {
  ADMIN_USER_LOANS_QUERY,
  ADMIN_CREATE_USER,
  ADMIN_PROFILE_BYID_QUERY,
  ADMIN_UPDATE_LOAN_ADJ,
  ADMIN_REMOVE_LOAN_ADJ,
  ADMIN_VERIFY_ACCOUNT,
  ADMIN_UPDATE_ACCOUNT_STATUS,
  ADMIN_DELETE_PAYMENT,
  ADMIN_UPDATE_LOAN_INFORMATION,
  ADMIN_UPDATE_LOAN_TERMS,
  ADMIN_CARD_LEADS,
  ADMIN_UPDATE_CARD_INFORMATION,
  ADMIN_UPDATE_CARD_LIMIT,
  DC_ISSUE_CARD,
  DC_ACTIVATE_CARD,
  DC_CANCEL_CARD,
  LOAN_TAPES_ADD,
  LOAN_TAPES_DELETE,
  LOAN_TAPES
} from "./admin";

export {
  CARD_REQUEST_MUTATION,
  CARD_REQUEST_BYID_QUERY,
  CARD_UPDATE_STATUS_MUTATION,
  CARD_LEAD,
  CARD_COMPLETE_LEAD,
  CARD_ADD_DISCOUNT,
  CARD_ADD_PAYMENT,
  CARD_CREATE_COMMENT,
  CARD_COMMENTS,
  CARD_TRANSACTIONS
} from "./card"

export {
  AUTH_EMAIL_VERIFICATION,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD,
  AUTH_SIGNIN_MUTATION,
  AUTH_SIGNUP_MUTATION,
  AUTH_SIGNOUT_MUTATION,
  AUTH_VERIFY_USER_MUTATION,
} from "./auth";

export {
  LOAN_DOCUSIGN_REQUEST_MUTATION,
  LOAN_DOCS_UPDATE_MUTATION,
  LOAN_REQUEST_MUTATION,
  LOAN_UPDATE_STATUS_MUTATION,
  LOANS_QUERY,
  LOANS_USER_QUERY,
  LOAN_ADD_PAYMENT,
  LOAN_NOTIFICATIONS,
  LOAN_COMMENTS,
  LOAN_CREATE_COMMENT,
  LOAN_EMPLOYMENT_AND_INCOME,
  LOAN_DELETE,
} from "./loan";

export {
  USER_PROFILE_QUERY,
  USER_LOAN_QUERY,
  USER_CARDS_QUERY,
  USER_LOANS_QUERY,
  USER_UPDATE_SHOW_TOUR,
  USER_UPDATE_INFORMATION_MUTATION,
  USERS_QUERY,
  USER_APPROVED_LOAN_QUERY,
  USER_UPDATE_DOCS,
  USER_PASSWORD_UPDATE_QUERY,
  USER_CREATE_ACTIVITY,
  USER_UPDATE_PROFILE_DOCS,
  USER_LOAN_QUERY_BYID,
  USER_NOTIFICATIONS_QUERY,
  USER_ACTIVITY_QUERY,
  USER_ACTIVITY_BYID_QUERY,
  USER_NOTIFICATION_MARK_READ,
  VERIFY_LEAD_MUTATION,
} from "./user";

export { REFERRALS_BY_EMAIL } from "./referral";
export { CREATE_NOTIFICATION, CREATE_LOAN_NOTIFICATION } from "./common";
export { MARKETING_LEADS_QUERY, MARKETING_LEAD_QUERY } from "./marketing";
