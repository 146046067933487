/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState, useEffect } from "react";
import {  useMutation } from "@apollo/client";
import stepTwo from "../../../assets/images/completed.png";

import logo from "../../../assets/images/logo-3.png";

import { toast } from "react-toastify";
import { withRouter, Link } from "react-router-dom";


import {
  USER_PROFILE_QUERY,
  CARD_COMPLETE_LEAD,
} from "../../../graphql";


const MastercardSignup = ({ location, history }) => {
  const [formState, setFormState] = useState({});
  const tokens = location.search.split("?");
  const httparams = tokens[1].split("&");
  const lid = httparams[0].split("=")[1];
  const hid = httparams[2].split("=")[1];
  const email = httparams[1].split("=")[1];
  const [verifyLead, { loading, error }] = useMutation(CARD_COMPLETE_LEAD);
  const [flinks, setFlinks] = useState(false);

  const [loadingFlinksIframe, setLoadingFlinksIframe] = useState(true);
  //added two different steps to choose from. redirect step happens after account selected step
  useEffect(() => {
    async function checkLead() {
      try {
        const data = await verifyLead({
          variables: {
            hid,
            lid,
            email,
          },
          refetchQueries: [
            { query: USER_PROFILE_QUERY },
          ],          
          fetchPolicy: "no-cache",
        });
        setFormState({});
        setFlinks(true);
      } catch (e) {
        toast.error(e.message.replace("GraphQL error: ", ""));
      }
    }

    if(!flinks){
      checkLead();
    }
    
  }, [email, hid, lid, verifyLead, flinks]);

  const hideSpinner = () => {
    setLoadingFlinksIframe(false);
  };
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="auth-bg">
          <div
            style={{
              margin: "0 auto",
              zIndex: 2,
              width: "800px",
            }}
          >
            <div className="text-center">
              <Link to="/login">
                <img alt="" src={logo} />
              </Link>
            </div>
            <div />
            <div className="card mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h4>
                    {loading ? "Verifying Account..." : "Signup Completed!"} <br />
                   
                  </h4>
                  <h6>
                    {loading && "Please wait while we verify your application"}
                  </h6>
                </div>
                {loading ? (
                  <div className="loader-box loader-form-wrapper">
                    <span className="rotate dotted" />
                  </div>
                ) : (
                  <div className="d-flex align-items-center flex-column p-2">
                    <img
                      alt="Verify Your Email"
                      className="mt-3"
                      src={stepTwo}
                      style={{
                        width: "90%",
                      }}
                    />
                    <div className="typography text-center mt-4" />
                    <div className="row">
                      <div className="col-sm-12 text-right mt-3 mb-3">
                        <button
                        disabled={loading}
                          className="btn btn-success btn-lg ml-auto"
                          onClick={() => {
                            history.push(
                              `${process.env.PUBLIC_URL}/card`
                            );
                          }}
                          type="button"
                        >
                          {loading ? "Verifying Your Account..." : "View Application"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(MastercardSignup);
