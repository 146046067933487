/* eslint-disable no-undef */
import React, { useState, Fragment, useRef } from "react";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";

import { CARD_LEAD } from "../../../graphql";

import { Breadcrumb, CreditCard } from "../../../components";

import ScrollBars from "react-scrollbar";

var images = require.context("../../../assets/images", true);

const MastercardLead = ({ match }) => {
  const id = match.params.id;
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  const { data, loading } = useQuery(CARD_LEAD, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  const [modal, setModal] = useState();
  const [modalMeta, setModalMeta] = useState({
    url: [],
    title: null,
  });

  const toggle = (url, title) => {
    setModalMeta({
      url,
      title,
    });
    setModal(!modal);
  };

  if (loading) {
    return "...";
  }
  if (!data && !data.cardLead) {
    return "...";
  }

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  return (
    <Fragment>
      <Breadcrumb parent="My Card" title="Lendle Credit Card" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div
              className="card cuanimated bounceInLeft"
              data-intro="This is University Earning Chart"
              id="animated-example"
            >
              <div className="card-header b-l-primary">
                <div className="row">
                  <div className="col-sm-6">
                    <h5>Credit Card Application Details</h5>
                  </div>
                  <div className="col-sm-6 text-right pull-right">
                    <small>
                      Application ID:{" "}
                      <strong>#{data.cardLead.id.toUpperCase()}</strong>
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <div className="form-group">
                        <label className="form-label">
                          <strong>Name</strong>
                        </label>
                        <br />
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.cardLead.name}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Email Address</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.cardLead.email.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Phone Number</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.cardLead.phone.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Gender</strong>
                      </label>
                      <br />
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {data.cardLead.gender.toLowerCase()}
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Address</strong>
                      </label>
                      <br />
                      {data.cardLead.residentialAddress !== null && (
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {data.cardLead.residentialAddress.street},{" "}
                          {data.cardLead.residentialAddress.city},{" "}
                          {data.cardLead.residentialAddress.suite},{" "}
                          {data.cardLead.residentialAddress.postal},{" "}
                          {data.cardLead.residentialAddress.province},{" "}
                          {data.cardLead.residentialAddress.country}
                        </span>
                      )}
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Monthly Income</strong>
                      </label>
                      <br />
                      <span>${data.cardLead.monthlyIncome}</span>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Employer's Name</strong>
                      </label>
                      <br />
                      <span>{data.cardLead.employerName}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Employer's Phone Number</strong>
                      </label>
                      <br />
                      <span>{data.cardLead.employerPhone}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Job Title / Position</strong>
                      </label>
                      <br />
                      <span>{data.cardLead.employerJobTitle}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        <strong>Verification Link</strong>
                      </label>
                      <br />
                      <span>
                        <input
                          ref={textAreaRef}
                          className="form-control"
                          onClick={copyToClipboard}
                          type="text"
                          value={`https://dashboard.lendle.com${`/mastercard?lid=${data.cardLead.id}&email=${data.cardLead.email}&hid=${data.cardLead.hash}&campaign=mastercard`}`}
                        />
                      </span>
                      {copySuccess}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(MastercardLead);
