import React from "react";
import { useUpload } from "../../../hooks";

import { FileDialogue, ValidIdentificationsList } from "../../../components";

import FrontImage from "../../../assets/images/id_placeholder_front.png";
import BackImage from "../../../assets/images/id_placeholder_back.png";

const CardUpload = ({
  wizardData,
  setWizardData,
  navigation: { next, previous },
}) => {
  const { files, addFile, removeFile } = useUpload({
    defaultValues: {
      govIssuedIdFront: { ...wizardData.govIssuedIdFront },
      govIssuedIdBack: { ...wizardData.govIssuedIdBack },
    },
  });
  

  const onSubmit = () => {
    setWizardData({
      ...wizardData,
      govIssuedIdFront: files["govIssuedIdFront"],
      govIssuedIdBack: files["govIssuedIdBack"],
    });
    next();
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-2">
          Step 4 - Upload Your Government Issued Identification
        </h5>
        <div className="row">
          To see a list of valid identifications{" "}
          <ValidIdentificationsList
            button={(onClick) => (
              <span className="ml-2" onClick={onClick}>
                Click Here
              </span>
            )}
          />
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h4 className="mt-3 mb-3">Front Side</h4>
            <div className="d-flex">
              {files["govIssuedIdFront"] && files["govIssuedIdFront"].file ? (
                <img
                  alt="ID Front"
                  src={files["govIssuedIdFront"].imagePreviewUrl}
                  width={150}
                  height={90}
                />
              ) : (
                <img alt="ID Front" src={FrontImage} />
              )}
            </div>

            <div className="d-flex flex-column align-items-start">
              {files["govIssuedIdFront"] && files["govIssuedIdFront"].file ? (
                <>
                  <span className="mt-3 mb-3">
                    {files["govIssuedIdFront"].file.name}
                  </span>
                  <button
                    onClick={() => removeFile("govIssuedIdFront")}
                    className="btn btn-danger"
                  >
                    Remove
                  </button>
                </>
              ) : (
                <FileDialogue
                  onChange={(file) => addFile(file, "govIssuedIdFront")}
                />
              )}
            </div>
          </div>
          <hr />
          <div className="col-md-12">
            <h4 className="mt-3 mb-3">Back Side</h4>
            <div className="d-flex">
              {files["govIssuedIdBack"] && files["govIssuedIdBack"].file ? (
                <img
                  alt="ID Back"
                  src={files["govIssuedIdBack"].imagePreviewUrl}
                  width={150}
                  height={90}
                />
              ) : (
                <img alt="ID Back" src={BackImage} />
              )}
            </div>
            <div className="d-flex flex-column align-items-start">
              {files["govIssuedIdBack"] && files["govIssuedIdBack"].file ? (
                <>
                  <br />
                  <span className="mt-3 mb-3">
                    {files["govIssuedIdBack"].file.name}
                  </span>
                  <button
                    onClick={() => removeFile("govIssuedIdBack")}
                    className="btn btn-danger"
                  >
                    Remove
                  </button>
                </>
              ) : (
                <FileDialogue
                  onChange={(file) => addFile(file, "govIssuedIdBack")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button
          onClick={previous}
          type="button"
          className="btn btn-prev btn-default btn-lg pull-left"
          id="next-button"
        >
          Previous
        </button>
        <button
          type="submit"
          disabled={
            !files["govIssuedIdFront"] || 
            !files["govIssuedIdBack"] || 
            Object.keys(files["govIssuedIdFront"]).length === 0 || 
            Object.keys(files["govIssuedIdBack"]).length === 0
          }
          className="btn btn-prev btn-success btn-lg pull-right"
          id="next-button"
          onClick={onSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CardUpload;
