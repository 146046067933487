import React, { useState } from "react";

const Reveal = ({ label, secret }) => {
  const [isVisible, toggle] = useState(false);
  return (
    <>
      <small>
        {label}: {isVisible ? <strong>{secret.toUpperCase()}</strong> : ""}
        <button onClick={() => toggle(!isVisible)}>
          {isVisible ? "Hide" : "Show"}
        </button>
      </small>
    </>
  );
};

export default Reveal;
