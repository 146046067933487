/* eslint-disable no-undef */
/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState, useEffect } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import logo from '../assets/images/logo-3.png'
import { withRouter} from 'react-router-dom';
import stepTwo from '../assets/images/completed.png';

import 'react-toastify/dist/ReactToastify.css';
import { LOAN_DOCS_UPDATE_MUTATION } from '../graphql';

const GuestDocUpload = ({match}) => {
  const [updateDocs, {loading, error}] = useMutation(LOAN_DOCS_UPDATE_MUTATION);
 const [currentStep, setCurrentStep] = useState(1);
  const [documents, setDocument] = useState({
    bankStatement: null,
    proofOfResidence: null,
    govIssuedId: null,
    directDepositForm: null,
  });

  useEffect(() => {
    if (error && error.message !== '') {
      toast.error(error.message.replace('GraphQL error: ', ''));
    }
  }, [error]);

  const handleUpdateDocuments = () => {
    const query = updateDocs({
      variables: {
        id: match.params.id,
        ...documents
      }
    })
    if(query){
      toast.success(
        'Successfully Updated Your Documents!',
        {
          onClose: function() {
            // props.history.push('/completesignup');
          },
        }
      );
      setCurrentStep(2)
    }
  }
  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid text-center">
          <div
            className="authentication-main"
            style={{
              width: '70%',
              margin: '0 auto',
          }}
          >
            <div className="row">
              <div className="col-md-12 p-0">
            <div className="text-center">
                <img
                  alt=""
                  src={logo}
                />
                </div>
                <div
                  className="card mt-4 mb-5"
                  style={{
                    padding: '40px'
                }}
                >
                <div className="u-steps row mt-4">
                  <div
                    className={'u-step col-sm-4 '}
                  >
                    <span className="u-step-number">1</span>
                    <div className="u-step-desc">
                      <span className="u-step-title">Submit Application</span>
                      <p>Fill up a lendle fund request</p>
                    </div>
                  </div>
                  <div
                    className={`u-step col-sm-4 ${currentStep === 1 ? 'current' : '' }`}
                  >
                    <span className="u-step-number">2</span>
                    <div className="u-step-desc">
                      <span className="u-step-title">Upload Documents</span>
                      <p>Provide documents to verify yourself</p>
                    </div>
                  </div>
                  <div
                    className={`u-step col-sm-4 ${currentStep === 2 ? 'current' : '' }`}
                  >
                    <span className="u-step-number">3</span>
                    <div className="u-step-desc">
                      <span className="u-step-title">Receive Funds</span>
                      <p>Documents received and funds issued</p>
                    </div>
                  </div>
                </div>
                      <div className="d-flex flex-column mt-4">
                    
                    {loading && (
                    <div className="loader-box loader-form-wrapper">
                        <span className="rotate dotted" />
                    </div>
                    )}
                {
                    currentStep === 1 && (
                        <>
                        <div
                          style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gridTemplateRows: 'auto',
                          columnGap: '1em',
                          rowGap: '1em',
                        }}
                        >
                        <form
                          className="dropzone digits"
                          id="singleFileUpload"
                        >
                          <div
                            className="dz-message needsclick"
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                            }}
                          >
                            <Dropzone
                              accept="image/*"
                              canCancel={false}
                              getFilesFromEvent={getFilesFromEvent}
                              id="govIssuedId"
                              InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                                return (
                                  <>
                                    {
                                      documents.govIssuedId ? ( <div
                                        style={{
                                          width: '50%',
                                          margin: '5%',
                                          padding: '10px',
                                          background: 'rgb(237, 242, 246)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          height: 'inherit',
                                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                          border: '1px solid rgb(208, 219, 228)',
                                          position: 'relative',
                                        }}
                                                                ><div
                                                                  onClick={() => setDocument(docs => ({
                                                            ...docs,
                                                            govIssuedId: null
                                                          }))}
                                                                  style={{
                                            position: 'absolute',
                                            top:'34px',
                                            right: '-9px',
                                            color: 'rgb(255, 255, 255)',
                                            background: 'rgb(255, 64, 129)',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '26px',
                                            fontWeight: '700',
                                            lineHeight: '30px',
                                            width: '30px',
                                            height: '30px',
                                          }}
                                                                // eslint-disable-next-line react/jsx-closing-bracket-location
                                                                >X</div>
                                 
                                        <img
                                          alt="preview"
                                          className="animated fadeIn"
                                          src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.govIssuedId}`}
                                          style={{width: '100%'}}
                                        />
                                      </div>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                                    Select Gov Issued ID
                                        <input
                                          accept={accept}
                                          onChange={e => {
                                            getFilesFromEvent(e).then(chosenFiles => {
                                              setDocument(state => ({
                                                ...state,
                                                govIssuedId: chosenFiles[0],
                                              }));
                                              onFiles(chosenFiles)
                                            })
                                          }}
                                          style={{ display: 'none' }}
                                          type="file"
                                        />
                                      </label>)
                                    }
                                   
                                  </>
                                )
                              }
                              }
                              inputContent="Gov ID"
                              maxFiles={1}
                              multiple={false}
                              PreviewComponent={(props) => <Preview
                                onClick={() => setDocument(docs => ({
                                  ...docs,
                                  govIssuedId: null
                                }))}
                                {...props}
                                                           />}
                              styles={{
                                dropzone: {
                                  width: '100%',
                                  height: 200,
                                },
                                dropzoneActive: {
                                  borderColor: 'green',
                                },
                              }}
                            />
                          </div>
                        </form>
                        <form
                          className="dropzone digits"
                          id="singleFileUpload"
                        >
                          <div
                            className="dz-message needsclick"
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                            }}
                          >
                            <Dropzone
                              accept="image/*"
                              canCancel={false}
                              getFilesFromEvent={getFilesFromEvent}
                              id="proofOfResidence"
                              InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                                return (
                                  <>
                                    {
                                      documents.proofOfResidence ? ( <div
                                        style={{
                                          width: '50%',
                                          margin: '5%',
                                          padding: '10px',
                                          background: 'rgb(237, 242, 246)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          height: 'inherit',
                                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                          border: '1px solid rgb(208, 219, 228)',
                                          position: 'relative',
                                        }}
                                                                     ><div
                                                                       onClick={() => setDocument(docs => ({
                                                            ...docs,
                                                            proofOfResidence: null
                                                          }))}
                                                                       style={{
                                            position: 'absolute',
                                            top:'34px',
                                            right: '-9px',
                                            color: 'rgb(255, 255, 255)',
                                            background: 'rgb(255, 64, 129)',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '26px',
                                            fontWeight: '700',
                                            lineHeight: '30px',
                                            width: '30px',
                                            height: '30px',
                                          }}
                                                                     // eslint-disable-next-line react/jsx-closing-bracket-location
                                                                     >X</div>
                                 
                                        <img
                                          alt="preview"
                                          className="animated fadeIn"
                                          src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.proofOfResidence}`}
                                          style={{width: '100%'}}
                                        />
                                      </div>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                                    Select Proof Of Residence
                                        <input
                                          accept={accept}
                                          onChange={e => {
                                            getFilesFromEvent(e).then(chosenFiles => {
                                              setDocument(state => ({
                                                ...state,
                                                proofOfResidence: chosenFiles[0],
                                              }));
                                              onFiles(chosenFiles)
                                            })
                                          }}
                                          style={{ display: 'none' }}
                                          type="file"
                                        />
                                      </label>)
                                    }
                                   
                                  </>
                                )
                              }
                              }
                              inputContent="Proof Of Residence"
                              maxFiles={1}
                              multiple={false}
                              PreviewComponent={(props) => <Preview
                                onClick={() => setDocument(docs => ({
                                  ...docs,
                                  proofOfResidence: null
                                }))}
                                {...props}
                                                           />}
                              styles={{
                                dropzone: {
                                  width: '100%',
                                  height: 200,
                                },
                                dropzoneActive: {
                                  borderColor: 'green',
                                },
                              }}
                            />
                          </div>
                        </form>
                        <form
                          className="dropzone digits"
                          id="singleFileUpload"
                        >
                          <div
                            className="dz-message needsclick"
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                            }}
                          >
                            <Dropzone
                              accept="image/*"
                              canCancel={false}
                              getFilesFromEvent={getFilesFromEvent}
                              id="directDepositForm"
                              InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                                return (
                                  <>
                                    {
                                      documents.directDepositForm ? ( <div
                                        style={{
                                          width: '50%',
                                          margin: '5%',
                                          padding: '10px',
                                          background: 'rgb(237, 242, 246)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          height: 'inherit',
                                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                          border: '1px solid rgb(208, 219, 228)',
                                          position: 'relative',
                                        }}
                                                                      ><div
                                                                        onClick={() => setDocument(docs => ({
                                                            ...docs,
                                                            directDepositForm: null
                                                          }))}
                                                                        style={{
                                            position: 'absolute',
                                            top:'34px',
                                            right: '-9px',
                                            color: 'rgb(255, 255, 255)',
                                            background: 'rgb(255, 64, 129)',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '26px',
                                            fontWeight: '700',
                                            lineHeight: '30px',
                                            width: '30px',
                                            height: '30px',
                                          }}
                                                                      // eslint-disable-next-line react/jsx-closing-bracket-location
                                                                      >X</div>
                                 
                                        <img
                                          alt="preview"
                                          className="animated fadeIn"
                                          src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.directDepositForm}`}
                                          style={{width: '100%', marginTop: '50px'}}
                                        />
                                      </div>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                                   Direct Deposit Form
                                        <input
                                          accept={accept}
                                          onChange={e => {
                                            getFilesFromEvent(e).then(chosenFiles => {
                                              setDocument(state => ({
                                                ...state,
                                                directDepositForm: chosenFiles[0],
                                              }));
                                              onFiles(chosenFiles)
                                            })
                                          }}
                                          style={{ display: 'none' }}
                                          type="file"
                                        />
                                      </label>)
                                    }
                                   
                                  </>
                                )
                              }
                              }
                              inputContent="Direct Deposit Form"
                              maxFiles={1}
                              multiple={false}
                              PreviewComponent={(props) => <Preview
                                onClick={() => setDocument(docs => ({
                                  ...docs,
                                  directDepositForm: null
                                }))}
                                {...props}
                                                           />}
                              styles={{
                                dropzone: {
                                  width: '100%',
                                  height: 200,
                                },
                                dropzoneActive: {
                                  borderColor: 'green',
                                },
                              }}
                            />
                          </div>
                        </form>
                        <form
                          className="dropzone digits"
                          id="singleFileUpload"
                        >
                          <div
                            className="dz-message needsclick"
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                            }}
                          >
                            <Dropzone
                              accept="image/*"
                              canCancel={false}
                              getFilesFromEvent={getFilesFromEvent}
                              id="bankStatement"
                              InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                                return (
                                  <>
                                    {
                                      documents.bankStatement ? ( <div
                                        style={{
                                          width: '50%',
                                          margin: '5%',
                                          padding: '10px',
                                          background: 'rgb(237, 242, 246)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          height: 'inherit',
                                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                          border: '1px solid rgb(208, 219, 228)',
                                          position: 'relative',
                                        }}
                                                                  ><div
                                                                    onClick={() => setDocument(docs => ({
                                                            ...docs,
                                                            bankStatement: null
                                                          }))}
                                                                    style={{
                                            position: 'absolute',
                                            top:'34px',
                                            right: '-9px',
                                            color: 'rgb(255, 255, 255)',
                                            background: 'rgb(255, 64, 129)',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '26px',
                                            fontWeight: '700',
                                            lineHeight: '30px',
                                            width: '30px',
                                            height: '30px',
                                          }}
                                                                  // eslint-disable-next-line react/jsx-closing-bracket-location
                                                                  >X</div>
                                 
                                        <img
                                          alt="preview"
                                          className="animated fadeIn"
                                          src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.bankStatement}`}
                                          style={{width: '100%'}}
                                        />
                                      </div>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                                   Bank Statement
                                        <input
                                          accept={accept}
                                          onChange={e => {
                                            getFilesFromEvent(e).then(chosenFiles => {
                                              setDocument(state => ({
                                                ...state,
                                                bankStatement: chosenFiles[0],
                                              }));
                                              onFiles(chosenFiles)
                                            })
                                          }}
                                          style={{ display: 'none' }}
                                          type="file"
                                        />
                                      </label>)
                                    }
                                   
                                  </>
                                )
                              }
                              }
                              inputContent="Bank Statement"
                              maxFiles={1}
                              multiple={false}
                              PreviewComponent={(props) => <Preview
                                onClick={() => setDocument(docs => ({
                                  ...docs,
                                  bankStatement: null
                                }))}
                                {...props}
                                                           />}
                              styles={{
                                dropzone: {
                                  width: '100%',
                                  height: 200,
                                },
                                dropzoneActive: {
                                  borderColor: 'green',
                                },
                              }}
                            />
                          </div>
                        </form>
                      </div>
                      <button
                        className="btn btn-primary mt-3 pull-right"
                        disabled={!documents.bankStatement || !documents.proofOfResidence || !documents.govIssuedId || !documents.directDepositForm}
                        onClick={handleUpdateDocuments}
                        style={{width: '200px'}}
                        type="submit"
                      >
                        Upload Documents
                      </button>
                      </>

                    )
                }
                {
                        currentStep === 2 && (
<div className="d-flex align-items-center flex-column p-2">
                    <img
                      alt="Verify Your Email"
                      className="mt-3"
                      src={stepTwo}
                      width="400px"
                    />
                    <div className="typography text-center mt-4">
                      <h3>
                        You're All Done! <br />
                        <small className="text-muted">
                          Congratulations, we have all that we need form you.{' '}
                          <br />
                          Your application is now in review by one of our Lendle staff.
                        </small>
                      </h3>
                    </div>
                  </div>
                        )
                }
        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};


const Preview = (props) => {
    const { previewUrl } = props.meta;
    const { remove } = props.fileWithMeta
    return (
      <div
        className="customFade-in"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '50%',
              margin: '5%',
              padding: '10px',
              background: '#edf2f6',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'inherit',
              boxShadow: '0 0 8px 2px rgba(0,0,0,.1)',
              border: '1px solid #d0dbe4',
              position: 'relative',
            }}
          >
            <div
              onClick={() =>{ props.onClick(); remove() }}
              style={{
                position: 'absolute',
                top:'34px',
                right: '-9px',
                color: '#fff',
                background: '#ff4081',
                borderRadius: '50%',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '26px',
                fontWeight: '700',
                lineHeight: '30px',
                width: '30px',
                height: '30px',
              }}
            >
              X
            </div>
            <img
              alt="preview"
              className="animated fadeIn"
              src={previewUrl}
              style={{
                width: '100%',
              }}
            />
          </div>
        </div>
      </div>
    );
  };
    
export default withRouter(GuestDocUpload);