/* eslint-disable no-undef */
import React from 'react';

import { useQuery } from '@apollo/client';
import { USER_ACTIVITY_QUERY, USER_ACTIVITY_BYID_QUERY } from '../graphql';

import ActivityItem from './ActivityItem';
import Empty from './Empty';
import Loading from './Loading';

const UserActivity = ({ id }) => {
  let query = {
    name: USER_ACTIVITY_QUERY,
    options: {
      fetchPolicy: 'no-cache',
    },
  };

  // if user id is present, change our query
  if (id) {
    query = {
      name: USER_ACTIVITY_BYID_QUERY,
      options: {
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
        },
      },
    };
  }

  const { data, loading } = useQuery(query.name, {
    ...query.options,
  });

  const activityData = !loading
    ? id && data
      ? data.userActivitiesById
      : data.userActivities
    : [];

  return (
    <div className="card">
      <div className="card-header b-l-primary">
        <h5>Activity</h5>
      </div>
      <div className="card-body activity-scroll">
        {!loading ? (
          <div className="activity">
            {activityData.length > 0 ? (
              activityData.map((activity, index) => {
                return <ActivityItem activity={activity} key={index} />;
              })
            ) : (
              <Empty message="No Activities Found" />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default UserActivity;
