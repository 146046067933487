import React from "react";

import s1 from "../../../../assets/images/step1.png";
import s2 from "../../../../assets/images/step2.png";
import s3 from "../../../../assets/images/step3.png";

const styles = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gridTemplateRows: "auto",
    columnGap: "1em",
    rowGap: "1em",
  },
  img: {
    width: "95%",
    margin: "0 auto",
  },
};

const Begin = ({ navigation: { next } }) => (
  <div className="card">
    <div className="card-header text-center">
      <h5 className="mb-2">Secured Mastercard Application</h5>
      <h6>You Dream It, We Fund It!</h6>
      <p className="text-center mt-3 mb-3">
      Before we begin please make sure you have government photo ID and a direct debit form. These documents will be needed later in this application.
      </p>
    </div>
    <div className="card-body text-center">
      <div style={styles.grid}>
        <div>
          <img alt="step 1" src={s1} style={styles.img} />
          <h6>
            <strong>STEP ONE</strong>
          </h6>
          <p className="text-center mt-3 mb-3">
          Let us get to know you better! We want to create long term relationships; this step helps us understand who you are. It also ensures we are dealing with the correct person.
          </p>
        </div>
        <div>
          <img alt="step 2" src={s2} style={styles.img} />
          <h6 className="mt-3">
            <strong>STEP TWO</strong>
          </h6>
          <p className="text-center mt-3 mb-3">
          Select the credit limit you desire and understand the terms and condition. Provide us with your income and employment information, we want to make sure you can commit to the card and help build your credit score.
          </p>
        </div>
        <div>
          <img alt="step 3" src={s3} style={styles.img} />
          <h6>
            <strong>STEP THREE</strong>
          </h6>
          <p className="text-center mt-3 mb-3">
          Your almost there! All we need is for you to upload the front and back of your ID. Once again this helps prevents identify theft and that we are dealing with the correct person.
          </p>
        </div>
      </div>
    </div>
    <div className="card-footer" style={{textAlign: "center"}}>
      <button
        className="btn btn-prev btn-success btn-lg"
        onClick={next}
      >
        Begin Application
      </button>
    </div>
  </div>
);

export default Begin;
