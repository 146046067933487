import React from 'react';
import moment from 'moment';

const UpcomingPaymentItem = ({ date, amount }) => (
  <tr>
    <td>
      <p>{moment(date).format('LL')}</p>
    </td>
    <td>
      <p>${amount}</p>
    </td>
    <td>
      <button className="btn btn-primary btn-pill" type="button">
        {moment(date).diff(new Date(), 'days')} Days
      </button>
    </td>
  </tr>
);

export default UpcomingPaymentItem;
