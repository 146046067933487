import React, { Fragment } from 'react';
import Breadcrumb from '../components/common/breadcrumb';
import { useQuery } from '@apollo/client';
import { USER_APPROVED_LOAN_QUERY } from '../graphql';
import {
  Calendar,
  momentLocalizer,
  Views,
} from 'react-big-calendar';
import moment from 'moment';
import currency from 'currency.js';

import 'react-big-calendar/lib/sass/styles.scss';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const Calender1 = ({ customLoan }) => {
  const { data, loading } = useQuery(
    USER_APPROVED_LOAN_QUERY
  );
  let loan =
    !loading &&
    customLoan &&
    customLoan.paymentCycleSchedule.length > 0
      ? customLoan
      : !loading && data.loanApplications.length
      ? data.loanApplications[0]
      : [];

  let myEventsList =
    !loading && loan.paymentCycleSchedule
      ? loan.paymentCycleSchedule.map((date, index) => {
          return {
            id: index,
            title:
              '$' + currency(loan.paymentCycleAmount).value,
            allDay: true,
            start: moment(date).toDate(),
            end: moment(date).toDate(),
          };
        })
      : [];

  return (
    <Fragment>
      {!customLoan && (
        <Breadcrumb parent="Calender" title="Calender" />
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div
              className="card cuanimated bounceInLeft"
              id="animated-example">
              <div className="card-body">
                {!loading && (
                  <Calendar
                    defaultDate={new Date()}
                    dragRevertDuration={500}
                    dragScroll
                    endAccessor="end"
                    eventOverlap
                    events={myEventsList}
                    localizer={localizer}
                    onSelectEvent={(event) =>
                      alert(event.title)
                    }
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    showMultiDayTimes
                    startAccessor="start"
                    step={60}
                    views={allViews}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Calender1;
