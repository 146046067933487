import {
  Home,
  Inbox,
  Calendar,
  FileText,
  CreditCard,
  User,
  Folder,
  MousePointer,
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/dashboard/overview",
  },
  {
    title: "Applications",
    icon: FileText,
    type: "link",
    active: false,
    path: "/dashboard/applications",
    children: [
      {
        path: "/dashboard/request",
        title: "New Application",
        type: "link",
      },
      {
        path: "/dashboard/applications",
        title: "Loan Applications",
        type: "link",
      },
    ],
  },

  {
    title: "Calendar",
    icon: Calendar,
    type: "link",
    active: false,
    path: "/dashboard/calendar",
  },
  {
    title: "My Card",
    icon: CreditCard,
    type: "link",
    active: false,
    path: "/card",
    children: [
      {
        path: "/card/apply",
        title: "Request Card",
        type: "link",
      },
      {
        path: "/card/referrals",
        title: "My Referrals",
        type: "link",
      },
    ],
  },

  {
    title: "Profile",
    icon: User,
    type: "link",
    active: false,
    path: "/dashboard/me",
  },
];

export const PARTNERITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/dashboard/overview",
  },
  {
    title: "Loan Tape",
    icon: MousePointer,
    type: "link",
    active: false,
    path: "/tape",
  },
  {
    title: "Applications",
    icon: FileText,
    type: "link",
    active: false,
    path: "/dashboard/applications",
    children: [
      {
        path: "/dashboard/request",
        title: "New Application",
        type: "link",
      },
      {
        path: "/dashboard/applications",
        title: "Loan Applications",
        type: "link",
      },
    ],
  },

  {
    title: "Calendar",
    icon: Calendar,
    type: "link",
    active: false,
    path: "/dashboard/calendar",
  },
  {
    title: "My Card",
    icon: CreditCard,
    type: "link",
    active: false,
    path: "/card",
    children: [
      {
        path: "/card/apply",
        title: "Request Card",
        type: "link",
      },
      {
        path: "/card/referrals",
        title: "My Referrals",
        type: "link",
      },
    ],
  },

  {
    title: "Profile",
    icon: User,
    type: "link",
    active: false,
    path: "/dashboard/me",
  },
];

export const ADMINMENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/dashboard/overview",
  },
  {
    title: "Loan Tape",
    icon: MousePointer,
    type: "link",
    active: false,
    path: "/tape",
    children: [
      {
        path: "/tape/history",
        title: "Loan Tapes",
        type: "link",
      },
    ],
  },
  {
    title: "Applications",
    icon: FileText,
    type: "link",
    active: false,
    path: "/dashboard/applications",
  },
  {
    title: "Marketing",
    icon: MousePointer,
    type: "link",
    active: false,
    path: "/dashboard/marketing",
    children: [
      {
        path: "/dashboard/leads",
        title: "App Leads",
        type: "link",
      },
      {
        path: "/card/leads",
        title: "Mastercard Leads",
        type: "link",
      },
    ],
  },
  {
    title: "Users",
    icon: Folder,
    type: "link",
    active: false,
    path: "/dashboard/users",
  },
  
  {
    title: "My Card",
    icon: CreditCard,
    type: "link",
    active: false,
    path: "/card",
    children: [
      {
        path: "/card/apply",
        title: "Request Card",
        type: "link",
      },

    ],
  },


  {
    title: "Profile",
    icon: User,
    type: "link",
    active: false,
    path: "/dashboard/me",
  },
];
