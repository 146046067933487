import { gql } from '@apollo/client';

export const USER_PASSWORD_UPDATE_QUERY = gql`
  mutation USER_PASSWORD_UPDATE_QUERY(
    $id: ID!
    $email: String!
    $password: String!
    $newPassword: String!
    $confirmPassword: String!
    $emailPassword: Boolean
  ) {
    updatePassword(
      id: $id
      email: $email
      password: $password
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      emailPassword: $emailPassword
    ) {
      message
    }
  }
`;

// Returns the currently signed in user's profile
export const USER_PROFILE_QUERY = gql`
  query USER_PROFILE_QUERY {
    me {
      id
      email
      name
      permissions
      gender
      phone
      dob
      hash
      active
      showTour
      notifications {
        id
        title
        brand
        icon
        message
        read
        createdAt
      }
      activity {
        id
        type
        createdAt
      }
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
      }
      residentialAddress {
        id
        country
        province
        street
        city
        postal
        suite
      }
      customerId
      customerNumber
      customerName
      customerTypeCode
      uniqueClientId
      customerStatus
    }
  }
`;

export const USER_LOAN_QUERY_BYID = gql`
  query USER_LOAN_QUERY_BYID($id: ID!) {
    loan(id: $id) {
      id
      createdAt
      email
      gender
      name
      user {
        id
      }
      loanAmount
      adj {
        id
        name
        email
      }
      loanFee
      loanFeeTax
      paymentCycleStartDate
      paymentCycleEndDate
      paymentCycleSchedule
      paymentCycleAmount
      paymentCycleDuration
      paymentCycleFrequency
      purposeOfBorrow
      borrowReason
      employmentAndIncome {
        desc
        title
        meta
      }
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      phone
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
        envelopeStatus
        envelopeUri
        envelopeId
      }
      dob
      status
      payments {
        id
        amount
        createdAt
        status
        paymentDate
      }
      campaign
    }
  }
`;

// Returns all loans for the currently signed in user
export const USER_NOTIFICATIONS_QUERY = gql`
  query USER_NOTIFICATIONS_QUERY($returnOnlyNew: Boolean) {
    userNotifications(returnOnlyNew: $returnOnlyNew) {
      id
      title
      message
      brand
      icon
      createdAt
      loan {
        id
      }
      category
      read
      user {
        id
        name
        email
      }
    }
  }
`;

// Returns all loans for the currently signed in user
export const USER_ACTIVITY_QUERY = gql`
  query USER_ACTIVITY_QUERY {
    userActivities {
      id
      type
      createdAt
    }
  }
`;

// Returns all loans for the currently signed in user
export const USER_ACTIVITY_BYID_QUERY = gql`
  query USER_ACTIVITY_BYID_QUERY($id: ID!) {
    userActivitiesById(id: $id) {
      id
      type
      createdAt
    }
  }
`;

export const USER_LOANS_QUERY = gql`
  query USER_LOANS_QUERY {
    loanApplications(orderBy: createdAt_DESC) {
      id
      name
      dob
      phone
      gender
      loanAmount
      campaign
      adj {
        id
        name
        email
      }
      loanFee
      loanFeeTax
      user {
        id
      }
      email
      paymentCycleStartDate
      paymentCycleEndDate
      paymentCycleDuration
      paymentCycleSchedule
      paymentCycleAmount
      paymentCycleFrequency
      residentialAddress {
        street
        city
        postal
        province
        country
      }
      documents {
        bankStatement
        govIssuedId
        directDepositForm
        proofOfResidence
      }
      status
      createdAt
      builder
    }
  }
`;

export const USER_CREATE_ACTIVITY = gql`
  mutation USER_CREATE_ACTIVITY($user: ID!, $type: Activity!) {
    createUserActivity(user: $user, type: $type) {
      message
    }
  }
`;

export const USER_NOTIFICATION_MARK_READ = gql`
  mutation USER_NOTIFICATION_MARK_READ($id: ID!) {
    markNotificationRead(id: $id) {
      message
    }
  }
`;


// Returns loans based on statuses for the currently signed in user
export const USER_CARDS_QUERY = gql`
  query USER_CARDS_QUERY {
    cardApplications(
      where: { status_in: [APPROVED, PAID, PENDING, INREVIEW, DENIED, WITHDRAW, CARD_ISSUED] }
      orderBy: createdAt_DESC
    ) {
      id
      name
      email
      phone
      status
      tempNumber
      user{
        id
        customerNumber
      }
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
        envelopeStatus
        envelopeUri
        envelopeId
      }
      transactions {
        id
        Description
        ProvisionAmount
        TransactionSource
        TerminalType
        createdAt
      }
      dob
      gender
      employerName
      employerPhone
      employerJobTitle
      monthlyIncome
      cardType
      cardFee
      cardBalance
      createdAt
      campaign
      cardNumber
      cardLimit
    }
  }
`;

// Returns loans based on statuses for the currently signed in user
export const USER_LOAN_QUERY = gql`
  query USER_LOAN_QUERY {
    loanApplications(
      where: { status_in: [APPROVED, PAID, PENDING] }
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      email
      gender
      name
      loanAmount
      adj {
        id
        name
        email
      }
      loanFee
      user {
        id
      }
      purposeOfBorrow
      borrowReason
      employmentAndIncome {
        desc
        title
        meta
      }
      loanFeeTax
      paymentCycleStartDate
      paymentCycleEndDate
      paymentCycleSchedule
      paymentCycleAmount
      paymentCycleDuration
      paymentCycleFrequency
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      phone
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
        envelopeStatus
        envelopeUri
        envelopeId
      }
      dob
      status
      payments {
        id
        amount
        createdAt
      }
    }
  }
`;
// Provided a oan id and params it will update the documents for the loan
export const USER_UPDATE_DOCS = gql`
  mutation USER_UPDATE_DOCS(
    $id: ID!
    $bankStatement: Upload
    $proofOfResidence: Upload
    $govIssuedId: Upload
    $directDepositForm: Upload
  ) {
    updateUserDocs(
      id: $id
      bankStatement: $bankStatement
      proofOfResidence: $proofOfResidence
      govIssuedId: $govIssuedId
      directDepositForm: $directDepositForm
    ) {
      message
    }
  }
`;

export const USER_UPDATE_PROFILE_DOCS = gql`
  mutation USER_UPDATE_PROFILE_DOCS(
    $bankStatement: Upload
    $proofOfResidence: Upload
    $govIssuedId: Upload
    $directDepositForm: Upload
  ) {
    updateUserProfileDocuments(
      bankStatement: $bankStatement
      proofOfResidence: $proofOfResidence
      govIssuedId: $govIssuedId
      directDepositForm: $directDepositForm
    ) {
      message
    }
  }
`;
// Returns loans based on statuses for the currently signed in user
export const USER_APPROVED_LOAN_QUERY = gql`
  query USER_APPROVED_LOAN_QUERY {
    loanApplications(
      where: { status_in: [APPROVED] }
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      email
      gender
      name
      loanAmount
      adj {
        id
        name
        email
      }
      loanFee
      loanFeeTax
      paymentCycleStartDate
      paymentCycleEndDate
      paymentCycleSchedule
      paymentCycleAmount
      paymentCycleFrequency
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      phone
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
        envelopeStatus
        envelopeUri
        envelopeId
      }
      dob
      status
      payments {
        id
        amount
        createdAt
      }
    }
  }
`;

// Provided with the params this mutation will update the user's personal information
export const USER_UPDATE_INFORMATION_MUTATION = gql`
  mutation USER_UPDATE_INFORMATION_MUTATION(
    $id: ID!
    $name: String
    $email: String
    $phone: String
    $gender: Gender
    $dob: String
    $street: String
    $city: String
    $postal: String
    $province: String
    $country: String
    $suite: String
    $source: String
  ) {
    updateUserProfile(
      id: $id
      name: $name
      email: $email
      phone: $phone
      gender: $gender
      dob: $dob
      street: $street
      city: $city
      postal: $postal
      province: $province
      country: $country
      suite: $suite
      source: $source
    ) {
      id
    }
  }
`;

// Provided with the params this mutation will update the user's personal information
export const USER_UPDATE_SHOW_TOUR = gql`
  mutation USER_UPDATE_SHOW_TOUR($id: ID!) {
    updateShowTour(id: $id) {
      message
    }
  }
`;

// Return all users (only works for admin)
export const USERS_QUERY = gql`
  query USERS_QUERY {
    users {
      id
      name
      phone
      email
      createdAt
      permissions
      gender
      dob
      active
      loanApplications {
        id
      }
      residentialAddress {
        id
        country
        province
        street
        city
        postal
        suite
      }
      status
      source
      lat
      long
    }
  }
`;

export const VERIFY_LEAD_MUTATION = gql`
  mutation VERIFY_LEAD_MUTATION($lid: ID!, $hid: ID!, $email: String!) {
    verifyLead(lid: $lid, hid: $hid, email: $email) {
      message
    }
  }
`;
