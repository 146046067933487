/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'react-feather';
import moment from 'moment';

export default function NotificationItem ({index, read, title, createdAt, message, id}) {
  const url = `inbox/${id}`;
  return(
    <li key={index}> 
      <Link to={`${process.env.PUBLIC_URL}/${url}`}><div className="media">
        <div className="media-body">
          <h6 className={`mb-0 ${read === false && 'txt-success'}`}><span><AlertCircle /></span>{title}</h6>
          <p className={'mb-0'}>{message}</p>
          <p className="mb-0 small">{moment(createdAt).fromNow()}</p>
        </div>
      </div>
      </Link>
    </li>
  )
}