/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useQuery, useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import customPassword from "../../services/genPass";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/min";
import Datatable from "../../components/datatable";
import { useUser } from "../../hooks";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumb from "../../components/common/breadcrumb";
import { ADMIN_CREATE_USER, USERS_QUERY } from "../../graphql";

var images = require.context("../../assets/images", true);

// eslint-disable-next-line react/display-name
const PhoneNumberInput = React.forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      {...props}
      className="form-control"
      country="CA"
      maxLength={15}
    />
  );
});

const Users = () => {
  const me = useUser();
  const [genPassword, setGenPassword] = useState();
  const { loading, data } = useQuery(USERS_QUERY);
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    permissions: ["USER"],
  });

  const [createUser, { loading: createUserLoading }] = useMutation(
    ADMIN_CREATE_USER
  );
  const [modal, setModal] = useState();
  const [dropdownOpen, setOpen] = useState(false);
  const [validateClass, setValidateClass] = useState(false);
  const [userPermissions, setUserPermissions] = useState(["USER"]);
  const [phone, setPhoneNumber] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState({
    message: null,
  });
  const [dobError, setDobError] = useState({
    dobDay: {
      message: null,
    },
    dobMonth: {
      message: null,
    },
    dobYear: {
      message: null,
    },
  });

  const resetCustomValidations = () => {
    setDobError({
      dobDay: {
        message: null,
      },
      dobMonth: {
        message: null,
      },
      dobYear: {
        message: null,
      },
    });
  };
  const toggle = () => setModal(!modal);
  const toggleDropDown = () => setOpen(!dropdownOpen);

  const exportCsv = () => {
    window.location.href = "https://api.lendle.com/user-csv";
  }
  const handleAddUser = async (data) => {
    if (
      data !== "" &&
      isValidPhoneNumber(phone) &&
      data.dobDay !== "DD" &&
      data.dobMonth !== "MM" &&
      data.dobYear !== "YYYY"
    ) {
      const dob = `${data.dobDay}-${data.dobMonth}-${data.dobYear}`;
      try {
        await createUser({
          variables: {
            ...data,
            name: data.fname + " " + data.lname,
            dob,
            phone,
            permissions: userPermissions,
            emailPassword: data.notifyUser,
          },
          refetchQueries: [
            {
              query: USERS_QUERY,
            },
          ],
        });
        toast.success("Successfully added a new user");
        toggle();
      } catch (error) {
        toast.error(error.message.replace("GraphQL error: ", ""));
      }
    } else {
      if (Object.keys(errors).length > 0) {
        errors.showMessages();
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Users" />
      <Modal fade={false} isOpen={modal} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>Create New User</ModalHeader>
        <form
          className={`theme-form needs-validation tooltip-validation ${
            validateClass ? "validateClass" : ""
          }`}
          noValidate=""
          onSubmit={(e) => {
            e.preventDefault();
            resetCustomValidations();

            if (phone === "") {
              setPhoneNumberError({
                message: "Phone number is required",
              });
            }
            const data = getValues();

            if (data.dobDay === "DD") {
              setDobError((state) => ({
                ...state,
                dobDay: {
                  name: "dobDay",
                  message: "Day is required.",
                },
              }));
            }
            if (data.dobMonth === "MM") {
              setDobError((state) => ({
                ...state,
                dobMonth: {
                  name: "dobMonth",
                  message: "Month is required.",
                },
              }));
            }
            if (data.dobYear === "YYYY") {
              setDobError((state) => ({
                ...state,
                dobYear: {
                  name: "dobYear",
                  message: "Year is required.",
                },
              }));
            }

            if (!phone || phone === "") {
              setPhoneNumberError({
                message: "Phone is required",
              });
            }

            if (phone && !isValidPhoneNumber(`${phone}`)) {
              setPhoneNumberError({
                message: "Invalid Phone Number",
              });
            }

            handleSubmit(handleAddUser)(e);
          }}
        >
          <ModalBody>
            {createUserLoading && (
              <div className="loader-box loader-form-wrapper">
                <span className="rotate dotted" />
              </div>
            )}
            <fieldset
              aria-busy={createUserLoading}
              disabled={createUserLoading}
            >
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-form-label"
                      htmlFor="validationTooltip01"
                    >
                      First Name
                    </label>
                    <input
                      className="form-control"
                      id="validationTooltip01"
                      name="fname"
                      placeholder="John"
                      ref={register({ required: true })}
                      type="text"
                    />
                    {errors.fname && <span>First Name is required</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="col-form-label"
                      htmlFor="validationTooltip02"
                    >
                      Last Name
                    </label>
                    <input
                      className="form-control"
                      id="validationTooltip02"
                      name="lname"
                      placeholder="Deo"
                      ref={register({ required: true })}
                      type="text"
                    />
                    {errors.lname && <span>Last Name is required</span>}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip03">
                  Your Email
                </label>
                <input
                  className="form-control"
                  id="validationTooltip03"
                  name="email"
                  placeholder="email@domain.com"
                  ref={register({ required: true })}
                  type="text"
                />
                {errors.email && <span>Email is required</span>}
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip04">
                  Phone Number
                </label>
                <PhoneInput
                  country="CA"
                  defaultCountry="CA"
                  inputComponent={PhoneNumberInput}
                  onChange={(e) => {
                    setPhoneNumber(e);
                    setPhoneNumberError({ message: null });
                  }}
                  placeholder="Enter phone number"
                  value={phone}
                />
                {phoneNumberError && phoneNumberError.message !== null && (
                  <span>{phoneNumberError.message}</span>
                )}
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip05">
                  Password
                </label>
                <input
                  className="form-control"
                  id="validationTooltip05"
                  name="password"
                  placeholder="**********"
                  ref={register({ required: true })}
                  type="password"
                />
                <button
                  className="btn btn-light pull-right mt-2 mb-2"
                  onClick={() => {
                    const pass = customPassword();
                    setValue("password", pass);
                    setGenPassword(pass);
                  }}
                  type="button"
                >
                  Generate Password
                </button>
                {genPassword && genPassword}
                {errors.password && <span>Password is required</span>}
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip06">
                  Date Of Birth
                </label>
                <div className="form-row">
                  <div className="col-sm-4">
                    <select
                      className="form-control mb-1"
                      name="dobDay"
                      onChange={() =>
                        setDobError((state) => ({
                          ...state,
                          dobDay: { message: null },
                        }))
                      }
                      ref={register({ required: true })}
                    >
                      <option>DD</option>
                      <option>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                      <option>31</option>
                    </select>
                    {dobError && dobError.dobDay.message !== null && (
                      <span>Day is required</span>
                    )}
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="form-control mb-1"
                      name="dobMonth"
                      onChange={() =>
                        setDobError((state) => ({
                          ...state,
                          dobMonth: { message: null },
                        }))
                      }
                      ref={register({ required: true })}
                    >
                      <option>MM</option>
                      <option>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                    {dobError && dobError.dobMonth.message !== null && (
                      <span>Month is required</span>
                    )}
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="form-control mb-1"
                      name="dobYear"
                      onChange={() =>
                        setDobError((state) => ({
                          ...state,
                          dobYear: { message: null },
                        }))
                      }
                      ref={register({ required: true })}
                    >
                      <option>YYYY</option>
                      <option>1920</option>
                      <option>1921</option>
                      <option>1922</option>
                      <option>1923</option>
                      <option>1924</option>
                      <option>1925</option>
                      <option>1926</option>
                      <option>1927</option>
                      <option>1928</option>
                      <option>1929</option>
                      <option>1930</option>
                      <option>1931</option>
                      <option>1932</option>
                      <option>1933</option>
                      <option>1934</option>
                      <option>1935</option>
                      <option>1936</option>
                      <option>1937</option>
                      <option>1938</option>
                      <option>1939</option>
                      <option>1940</option>
                      <option>1941</option>
                      <option>1942</option>
                      <option>1943</option>
                      <option>1944</option>
                      <option>1945</option>
                      <option>1946</option>
                      <option>1947</option>
                      <option>1948</option>
                      <option>1949</option>
                      <option>1950</option>
                      <option>1951</option>
                      <option>1952</option>
                      <option>1953</option>
                      <option>1954</option>
                      <option>1955</option>
                      <option>1956</option>
                      <option>1957</option>
                      <option>1958</option>
                      <option>1959</option>
                      <option>1960</option>
                      <option>1961</option>
                      <option>1962</option>
                      <option>1963</option>
                      <option>1964</option>
                      <option>1965</option>
                      <option>1966</option>
                      <option>1967</option>
                      <option>1968</option>
                      <option>1969</option>
                      <option>1970</option>
                      <option>1971</option>
                      <option>1972</option>
                      <option>1973</option>
                      <option>1974</option>
                      <option>1975</option>
                      <option>1976</option>
                      <option>1977</option>
                      <option>1978</option>
                      <option>1979</option>
                      <option>1980</option>
                      <option>1981</option>
                      <option>1982</option>
                      <option>1983</option>
                      <option>1984</option>
                      <option>1985</option>
                      <option>1986</option>
                      <option>1987</option>
                      <option>1988</option>
                      <option>1989</option>
                      <option>1990</option>
                      <option>1991</option>
                      <option>1992</option>
                      <option>1993</option>
                      <option>1994</option>
                      <option>1995</option>
                      <option>1996</option>
                      <option>1997</option>
                      <option>1998</option>
                      <option>1999</option>
                      <option>2000</option>
                      <option>2001</option>
                      <option>2002</option>
                      <option>2003</option>
                      <option>2004</option>
                      <option>2005</option>
                      <option>2006</option>
                      <option>2007</option>
                      <option>2008</option>
                      <option>2009</option>
                      <option>2010</option>
                      <option>2011</option>
                      <option>2012</option>
                      <option>2013</option>
                      <option>2014</option>
                      <option>2015</option>
                      <option>2016</option>
                      <option>2017</option>
                      <option>2018</option>
                      <option>2019</option>
                      <option>2020</option>
                    </select>
                    {dobError && dobError.dobYear.message !== null && (
                      <span>Year is required</span>
                    )}
                  </div>
                </div>

                {me.permissions.includes("ADMIN") && (
                  <div className="form-group m-t-15 m-checkbox-inline mb-0 ml-1">
                    <label
                      className="col-form-label"
                      htmlFor="validationTooltip06"
                    >
                      User Permission Groups
                    </label>
                    <div className="checkbox checkbox-dark">
                      <input
                        id="inline-1"
                        name="ADMIN"
                        onClick={() =>
                          setUserPermissions((permissions) => {
                            if (permissions.includes("ADMIN")) {
                              return permissions.filter((e) => e !== "ADMIN");
                            } else {
                              return [...permissions, "ADMIN"];
                            }
                          })
                        }
                        type="checkbox"
                      />
                      <label htmlFor="inline-1">Administrator</label>
                    </div>
                    <div className="checkbox checkbox-dark">
                      <input
                        id="inline-2"
                        name="MANAGER"
                        onClick={() =>
                          setUserPermissions((permissions) => {
                            if (permissions.includes("MANAGER")) {
                              return permissions.filter((e) => e !== "MANAGER");
                            } else {
                              return [...permissions, "MANAGER"];
                            }
                          })
                        }
                        type="checkbox"
                      />
                      <label htmlFor="inline-2">Manager</label>
                    </div>
                    <div className="checkbox checkbox-dark">
                      <input
                        id="inline-3"
                        name="ADJUDICATOR"
                        onClick={() =>
                          setUserPermissions((permissions) => {
                            if (permissions.includes("ADJUDICATOR")) {
                              return permissions.filter(
                                (e) => e !== "ADJUDICATOR"
                              );
                            } else {
                              return [...permissions, "ADJUDICATOR"];
                            }
                          })
                        }
                        type="checkbox"
                      />
                      <label htmlFor="inline-3">Adjudicator</label>
                    </div>
                  </div>
                )}
                <div className="checkbox mb-4">
                  <input
                    defaultChecked
                    id="notifyUser"
                    name="notifyUser"
                    ref={register}
                    type="checkbox"
                  />
                  <label className="mb-0" htmlFor="notifyUser">
                    Email user the new password
                  </label>
                </div>
              </div>
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setValidateClass(!validateClass)}
              type="submit"
            >
              Create User
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <div className="container-fluid">
        <div
          className="email-top"
          style={{
            top: "25px",
            position: "absolute",
            right: 0,
            width: "auto",
            float: "right",
          }}
        >
          <div className="col text-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
              <DropdownToggle caret className="dropbtn" color="primary">
                Action Menu
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={toggle}>Create New User</DropdownItem>
                <DropdownItem onClick={exportCsv}>Export CSV</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="card cuanimated bounceInLeft" id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
              {" "}
              {!loading && data.users
                ? "Found " + data.users.length + " Record(s)"
                : "Loading..."}{" "}
            </h5>
          </div>
          <div className="card-body">
            {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: "inherit" }}
              >
                <span className="rotate dotted" />
              </div>
            ) : data.users.length > 0 ? (
              <Datatable
                class="-striped -highlight"
                myData={data.users.map((app) => {
                  let row = {};

                  row["ID"] = app.id;
                  row["Name"] = app.name;
                  row["Email"] = app.email;
                  row["Status"] = app.active === true ? "Active" : "Pending";
                  row["Date"] = moment(app.createdAt).format("LL");
                  row["Apps"] = app.loanApplications.length;

                  if (app.status === "ARCHIVE") {
                    row["Status"] = "Deleted";
                  }

                  return row;
                })}
                pageSize={15}
                pagination
                routeUrl="users"
              />
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: "300px", marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images("./search-not-found.png")}
                    style={{ marginTop: "-50px" }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Users;
