import React from "react";
import { withRouter } from "react-router-dom";

import img from "../../../../assets/images/completed.png";

const Success = ({ appId, history }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flex: "1",
    }}
  >
    <div
      className="card cuanimated bounceInLeft"
      id="animated-example"
      style={{ marginBottom: 0 }}
    >
      <div className="card-body text-center ">
        <div className="d-flex align-items-center flex-column p-2">
          <h5 className="mb-2">
            <strong>Application Request Completed</strong>
          </h5>
          <h6>You Dream It, We Fund It!</h6>
          <img
            alt="Verify Your Email"
            className="mb-3"
            src={img}
            width="400px"
          />
          <div className="typography text-center mb-3">
            <h3>
              <small className="text-muted">
                Congratulations, you have successfully completed your application.<br />
                Please allow up to 24 business hours to process your application.<br />
                You can check the status of your application on the “My Card” tab on your dashboard.
              </small>
            </h3>
          </div>
          <div className="row">
            <div className="col-sm-12 text-right mt-3 mb-3">
              <button
                className="btn btn-success btn-lg ml-auto"
                onClick={() =>
                  history.push(
                    `${process.env.PUBLIC_URL}/dashboard/card/${appId}`
                  )
                }
                type="button"
              >
                View My Application
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withRouter(Success);
