import React from "react";

import s1 from "../../../assets/images/step1.png";
import s2 from "../../../assets/images/step2.png";
import s3 from "../../../assets/images/step3.png";

const styles = {
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gridTemplateRows: "auto",
    columnGap: "1em",
    rowGap: "1em",
  },
  img: {
    width: "95%",
    margin: "0 auto",
  },
};

const Begin = ({ navigation: { next } }) => (
  <div className="card">
    <div className="card-header text-center">
      <h5 className="mb-2">Funds Request Online Form</h5>
      <h6>You Dream It, We Fund It!</h6>
      <p className="text-center mt-3 mb-3">
        Before we begin please make sure you have government photo ID, 3 months
        bank history, and a direct debit/ deposit form. These documents will be
        needed later in this application.
      </p>
    </div>
    <div className="card-body text-center">
      <div style={styles.grid}>
        <div>
          <img alt="step 1" src={s1} style={styles.img} />
          <h6>
            <strong>Personal Info</strong>
          </h6>
          <p className="text-center mt-3 mb-3">
            Let us get to know you better! Lendle will ask you to fill out your
            personal information to help us assist you in getting funded. Please
            make sure this information is accurate. Information is kept private
            and only you will have accesses to it.
          </p>
        </div>
        <div>
          <img alt="step 2" src={s2} style={styles.img} />
          <h6 className="mt-3">
            <strong>Select A Term</strong>
          </h6>

          <p className="text-center mt-3 mb-3">
            Once we get your personal information, select the amount you wish to
            be funded for. You will then be presented with term options. You
            select the payment plan you are comfortable with.
          </p>
        </div>
        <div>
          <img alt="step 3" src={s3} style={styles.img} />
          <h6>
            <strong>Upload Documents</strong>
          </h6>

          <p className="text-center mt-3 mb-3">
            Last step in the process! All we need is for you to upload the
            required documents to help us help you get funded. Your documents
            will be stored securely with Lendle.
          </p>
        </div>
      </div>
    </div>
    <div className="card-footer">
      <button
        className="btn btn-prev btn-success btn-lg pull-right"
        onClick={next}
      >
        Begin Application
      </button>
    </div>
  </div>
);

export default Begin;
