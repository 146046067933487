import { gql } from '@apollo/client';

// Provided an id it will email a user documents to sign
export const LOAN_DOCUSIGN_REQUEST_MUTATION = gql`
  mutation LOAN_DOCUSIGN_REQUEST_MUTATION($id: ID!) {
    requestSignature(id: $id) {
      message
    }
  }
`;

export const LOAN_ADD_PAYMENT = gql`
  mutation LOAN_ADD_PAYMENT($loanId: ID!, $userId: ID!, $amount: String!, $status: String, $paymentDate: DateTime) {
    addPayment(loanId: $loanId, userId: $userId, amount: $amount, status: $status, paymentDate: $paymentDate) {
      message
    }
  }
`;

export const LOAN_DELETE = gql`
  mutation LOAN_DELETE($loanId: ID!) {
    deleteLoan(loanId: $loanId) {
      message
    }
  }
`;
// Provided a oan id and params it will update the documents for the loan
export const LOAN_DOCS_UPDATE_MUTATION = gql`
  mutation LOAN_DOCS_UPDATE_MUTATION(
    $id: ID!
    $bankStatement: Upload
    $proofOfResidence: Upload
    $govIssuedId: Upload
    $directDepositForm: Upload
  ) {
    updateDocs(
      id: $id
      bankStatement: $bankStatement
      proofOfResidence: $proofOfResidence
      govIssuedId: $govIssuedId
      directDepositForm: $directDepositForm
    ) {
      message
    }
  }
`;

// Create a new loan request based on the params provided
export const LOAN_REQUEST_MUTATION = gql`
  mutation LOAN_REQUEST_MUTATION(
    $id: ID
    $name: String!
    $email: String!
    $phone: String!
    $street: String!
    $city: String!
    $postal: String!
    $province: String!
    $country: String!
    $bankStatement: Upload
    $proofOfResidence: Upload
    $govIssuedId: Upload
    $directDepositForm: Upload
    $dob: String!
    $gender: String!
    $loanAmount: Float!
    $loanFee: Float!
    $loanFeeTax: Float!
    $paymentCycleStartDate: DateTime!
    $paymentCycleEndDate: DateTime!
    $paymentCycleSchedule: [DateTime!]!
    $paymentCycleFrequency: String!
    $paymentCycleDuration: String!
    $paymentCycleAmount: Float!
    $updateAddress: Boolean
    $employmentAndIncome: [EmploymentItemType!]!
    $purposeOfBorrow: String!
    $borrowReason: String!
  ) {
    requestLoan(
      id: $id
      name: $name
      email: $email
      phone: $phone
      street: $street
      city: $city
      postal: $postal
      province: $province
      country: $country
      bankStatement: $bankStatement
      proofOfResidence: $proofOfResidence
      govIssuedId: $govIssuedId
      directDepositForm: $directDepositForm
      dob: $dob
      gender: $gender
      loanAmount: $loanAmount
      loanFee: $loanFee
      loanFeeTax: $loanFeeTax
      paymentCycleStartDate: $paymentCycleStartDate
      paymentCycleEndDate: $paymentCycleEndDate
      paymentCycleSchedule: $paymentCycleSchedule
      paymentCycleFrequency: $paymentCycleFrequency
      paymentCycleDuration: $paymentCycleDuration
      paymentCycleAmount: $paymentCycleAmount
      updateAddress: $updateAddress
      employmentAndIncome: $employmentAndIncome
      purposeOfBorrow: $purposeOfBorrow
      borrowReason: $borrowReason
    ) {
      id
    }
  }
`;

// Provided an id and status it will update the loan's status
export const LOAN_UPDATE_STATUS_MUTATION = gql`
  mutation LOAN_UPDATE_STATUS_MUTATION($id: ID!, $status: String!, $user: ID!) {
    updateStatus(id: $id, status: $status, user: $user) {
      message
    }
  }
`;

// Provided an id and status it will update the loan's status
export const LOAN_CREATE_COMMENT = gql`
  mutation LOAN_CREATE_COMMENT(
    $user: ID!
    $loan: ID!
    $title: String!
    $message: String!
  ) {
    createComment(user: $user, loan: $loan, title: $title, message: $message) {
      message
    }
  }
`;

// Returns all loans for the currently signed in user
export const LOAN_NOTIFICATIONS = gql`
  query LOAN_NOTIFICATIONS($id: ID!) {
    loanNotifications(id: $id) {
      id
      title
      message
      brand
      icon
      createdAt
      read
      user {
        id
      }
    }
  }
`;

// Returns all employment data for the current loan
export const LOAN_EMPLOYMENT_AND_INCOME = gql`
  query LOAN_EMPLOYMENT_AND_INCOME($id: ID!) {
    loan(id: $id) {
      employmentAndIncome {
        desc
        title
        meta
      }
    }
  }
`;

// Returns all loans for the currently signed in user
export const LOAN_COMMENTS = gql`
  query LOAN_COMMENTS($id: ID!) {
    loanComments(id: $id) {
      id
      title
      message
      user {
        id
        name
      }
      createdAt
    }
  }
`;

// Returns all loans for the currently signed in user
export const LOANS_QUERY = gql`
  query LOANS_QUERY {
    loanApplications(orderBy: createdAt_DESC) {
      id
      name
      dob
      phone
      gender
      loanAmount
      adj {
        id
        name
        email
      }
      email
      paymentCycleFrequency
      paymentCycleAmount
      residentialAddress {
        street
        city
        postal
        province
        country
      }
      documents {
        bankStatement
        govIssuedId
        directDepositForm
        proofOfResidence
      }
      status
      createdAt
    }
  }
`;

// Returns all loans for the user id provided
export const LOANS_USER_QUERY = gql`
  query LOANS_USER_QUERY($id: ID!) {
    userLoanApplications(id: $id, orderBy: createdAt_DESC) {
      id
      name
      dob
      phone
      email
      loanAmount
      adj {
        id
        name
        email
      }
      loanFee
      loanFeeTax
      paymentCycleStartDate
      paymentCycleEndDate
      paymentCycleSchedule
      paymentCycleAmount
      paymentCycleFrequency
      residentialAddress {
        street
        city
        postal
        province
        country
      }
      documents {
        bankStatement
        govIssuedId
        directDepositForm
        proofOfResidence
      }
      status
      createdAt
    }
  }
`;
