/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import Breadcrumb from "../../../components/common/breadcrumb";
import Datatable from "../../../components/datatable";
import { useCSVDownloader } from 'react-papaparse';
import Papa from "papaparse"

import currency from "currency.js";
import { LOAN_TAPES } from "../../../graphql";
import UploadLoanTape from "components/UploadLoanTape";
import { useUser } from "../../../hooks";

var images = require.context("../../../assets/images", true);


const LoanTapes = () => {
  const me = useUser();
  // const [tableData, setTableData] = React.useState([]);
  const { loading, data, refetch } = useQuery(LOAN_TAPES, {
    fetchPolicy: "network-only",
  });
  const { CSVDownloader, Type } = useCSVDownloader();
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Loan Tapes History" />
      <div className="container-fluid">
        <div className="card cuanimated bounceInLeft" id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
              Loan Tapes
            </h5>
            {me && me.permissions.includes("ADMIN") && <div style={{position: 'absolute',right:20, top :20}}> <UploadLoanTape /></div>}
          </div>
          <div className="card-body">
           
            {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: "inherit" }}
              >
                <span className="rotate dotted" />
              </div>
            ) : data.tapes.length > 0 ? (
              <div>
                 <Datatable
                class="-striped -highlight"
                hideAction
                myData={data.tapes.map((app) => {
                  let row = {
                    Date: app.createdAt,
                  };

                  row["ID"] = app.id;
                  row["Amount Borrowed"] = currency(app.totalBorrowed).format("$");
                  row["Total Payback"] = currency(app.totalPayback).format("$");
                  row["Admin Fee"] = currency(app.adminFee).format("$");
                  row["Download Tape"] =  <CSVDownloader
                  type={Type.Button}
                  filename={'tape-' + app.id}
                  bom={true}
                  config={{
                    delimiter: ';',
                  }}
                  data={Papa.parse(app.items)}
                >
                  Download
                </CSVDownloader>

                  return row;
                })}
                minRows={100}
                pagination
                routeUrl="card"
              />

              </div>
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: "300px", marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images("./search-not-found.png")}
                    style={{ marginTop: "-40px" }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoanTapes;
