import React from "react";
import loanCalc from "../services/loanCalc";

const FormFancyCheckbox = ({
  options,
  loanAmount,
  setValue,
  input,
  watch,
  defaultValue,
}) => {
  const onChange = (e) => {
    const [, id] = e.target.id.split("-");
    setValue(input, options[id - 1].label);
  };

  return options.map((option, index) => {
    const { loanFee } = loanCalc(
      loanAmount,
      option.term,
      watch("paymentCycleFrequency"),
      watch("paymentCycleStartDate")
    );

    return (
      <div className="col-sm-6" key={index}>
        <div className="card">
          <div className="media p-20">
            <div className="radio radio-primary mr-3 ml-2">
              <input
                defaultChecked={defaultValue === option.label}
                id={`radio-${option.id}`}
                onChange={onChange}
                name="term"
                type="radio"
                value={option.value}
              />
              <label htmlFor={`radio-${option.id}`} />
            </div>
            <div className="media-body">
              <h6 className="mt-0 mega-title-badge">
                {option.label}
                {loanAmount && (
                  <span className="badge badge-primary pull-right digits">
                    ${loanFee && loanFee.value}
                  </span>
                )}
              </h6>
              {option.description && <p>{option.description}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default FormFancyCheckbox;
