import React, { Fragment } from "react";

import { Breadcrumb, UserReferrals } from "../../../components";
import { useUser } from "../../../hooks";

const MyReferrals = () => {
    const me = useUser();
    return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="My Referrals" />
      <div className="container-fluid">
        <div className="card cuanimated bounceInLeft" id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
            </h5>
          </div>
          <div className="card-body">
            <UserReferrals email={me.email} campaign="secured_mastercard" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyReferrals;
