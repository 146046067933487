import React, { useEffect } from 'react';
import round from '../assets/images/university/round.png';
const Knob = require('knob');

const LendleScore = () => {
  const score = 800;
  const primary = localStorage.getItem('primary_color') || '#4466f2';

  useEffect(() => {
    const student = Knob({
      value: (score / 800).toFixed(1) * 100,
      angleOffset: 80,
      angleArc: 360,
      className: 'review',
      lineCap: 'round',
      thickness: 0.1,
      width: 180,
      step: 1,
      height: 180,
      fgColor: '#fff',
      bgColor: primary,
      readOnly: true,
    });
    document.getElementById('student').appendChild(student);
  }, []);

  return (
    <div className="card card-gradient">
      <div className="card-body text-center o-hidden score">
        <div className="knob-header">
          <h5>Lendle Score</h5>
          <div className="d-inline-block pull-right f-16">
            85 / <span>800</span>
          </div>
        </div>
        <div className="knob-block text-center knob-center university-knob">
          <div className="knob" id="student" />
        </div>
        <img alt="" className="round-image" src={round} />
      </div>
    </div>
  );
};

export default LendleScore;
