import React, { useState, useEffect } from "react";
import { useStep, useUser, useWindowResize } from "../../../hooks";
import { getUserDetails } from "../../../util";
import { Steps } from "rsuite";

import Welcome from "./steps/Welcome.js";
import BasicInfo from "./steps/BasicInfo.js";
import CardSelect from "./steps/CardSelect.js";
import FlinksConnect from "./steps/FlinksConnect.js";
import CardUpload from "./steps/CardUpload.js";
import Result from "./steps/Result.js";

import { stepsCard } from "../../../constant/constants";

const Wizard = () => {
  const user = useUser();
  const initialValues = getUserDetails(user);
  const size = useWindowResize();
  const [wizardData, setWizardData] = useState({
    ...initialValues,
    paymentCycleDuration: "3 Months",
  });

  const { step, navigation, index } = useStep({ initialStep: 0, steps: stepsCard });

  const { id } = step;

  const props = {
    navigation,
    wizardData,
    setWizardData,
  };

  const resetWizard = () => {
    setWizardData({
      ...initialValues,
      cardType: "$300",
    });
    navigation.goTo(0);
  };

  useEffect(() => {
    resetWizard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Steps current={index}>
        {stepsCard.map((step) => (
          <Steps.Item title={size.width > 900 ? step.title : ""} />
        ))}
      </Steps>
      <div className="mb-4" />
      {id === "Step 1" && <Welcome {...props} />}
      {id === "Step 2" && <BasicInfo {...props} />}
      {id === "Step 3" && <CardSelect {...props} />}
      {id === "Step 4" && <CardUpload {...props} />}
      {id === "Step 5" && <Result {...props} />}
    </>
  );
};

export default Wizard;
