/* eslint-disable react/no-multi-comp */
import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { USER_LOAN_QUERY } from "../../graphql";

import DashboardTabs from "./Tabs";
import CustomerView from "./Customer";
import AdminView from "./Admin";

import Application from "../../components/application";
import Breadcrumb from "../../components/common/breadcrumb";

import { useUser } from "../../hooks";
import metricsCalc from "../../services/metricsCalc";

const DashboardView = ({ history }) => {
  const { data, loading } = useQuery(USER_LOAN_QUERY, {
    fetchPolicy: "network-only",
  });

  const user = useUser();
  const metrics = metricsCalc(data);

  const loanIsApproved = (app) => app.status === "APPROVED";
  const hasAdminRole = user && user.permissions.includes("ADMIN");
  const hasAdjudicatorRole = user && user.permissions.includes("ADJUDICATOR");

  const approvedLoan =
    !loading && data && data.loanApplications
      ? data.loanApplications.filter(loanIsApproved)
      : [];

  const tabs = [
    {
      title: "Overview",
      icon: "HomeIcon",
      current: true,
      panel:
        hasAdminRole || hasAdjudicatorRole ? (
          <AdminView loansApproved={approvedLoan} metrics={metrics} />
        ) : (
          <CustomerView loansApproved={approvedLoan} metrics={metrics} />
        ),
    },
  ];

  if (!hasAdminRole && approvedLoan.legnth > 0) {
    tabs.push({
      title: "My Application",
      icon: "HomeIcon",
      current: false,
      panel: <Application embedded />,
    });
  } else {
    tabs.push({
      title: "New Application",
      icon: "HomeIcon",
      current: false,
      isButton: true,
      panel: "",
      onClick: () =>
        history.push(`${process.env.PUBLIC_URL}/dashboard/request`),
    });
  }

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Overview" />
      <div className="container-fluid">
        {!loading && <DashboardTabs tabs={tabs} />}
      </div>
    </Fragment>
  );
};

export default DashboardView;
