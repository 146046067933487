import { gql } from '@apollo/client';
export const ADMIN_PROFILE_BYID_QUERY = gql`
  query ADMIN_PROFILE_BYID_QUERY($id: ID!) {
    user(id: $id) {
      id
      email
      name
      permissions
      gender
      phone
      dob
      hash
      active
      status
      residentialAddress {
        id
        country
        province
        street
        city
        postal
        suite
      }
      customerId
      customerNumber
      customerName
      customerTypeCode
      uniqueClientId
      customerStatus
    }
  }
`;

export const ADMIN_DELETE_PAYMENT = gql`
  mutation ADMIN_DELETE_PAYMENT($id: ID!) {
    deletePayment(id: $id) {
      message
    }
  }
`;

export const ADMIN_USER_LOANS_QUERY = gql`
  query ADMIN_USER_LOANS_QUERY($id: ID!) {
    userLoanApplications(id: $id, orderBy: createdAt_DESC) {
      id
      name
      dob
      phone
      email
      loanAmount
      adj {
        id
        name
        email
      }
      paymentCycleDuration
      loanAmount
      paymentCycleFrequency
      paymentCycleAmount
      residentialAddress {
        postal
        street
        city
        postal
        province
        country
      }
      documents {
        bankStatement
        govIssuedId
        directDepositForm
        proofOfResidence
      }
      status
      createdAt
    }
  }
`;

export const DC_ISSUE_CARD = gql`
  mutation DC_ISSUE_CARD($userId: ID!, $customerNumber: String, $customerId: Int, $applicationId: ID, $walletId: Int) {
    dcIssueCard(userId: $userId, customerNumber: $customerNumber, customerId: $customerId, applicationId: $applicationId, walletId: $walletId) {
      message
    }
  }
`;



export const DC_ACTIVATE_CARD = gql`
  mutation DC_ACTIVATE_CARD($applicationId: ID!, $customerNumber: String!, $lastFourDigits: Int!) {
    dcActivateCard(applicationId: $applicationId, customerNumber: $customerNumber, lastFourDigits: $lastFourDigits) {
      message
    }
  }
`;

export const DC_CANCEL_CARD = gql`
  mutation DC_CANCEL_CARD($applicationId: ID!, $customerNumber: String!, $lastFourDigits: Int!, $cancelcode: String!, $desc: String!) {
    dcCancelCard(applicationId: $applicationId, customerNumber: $customerNumber, lastFourDigits: $lastFourDigits, cancelcode: $cancelcode, desc: $desc) {
      message
    }
  }
`;


export const LOAN_TAPES_DELETE = gql`
  mutation LOAN_TAPES_DELETE($id: ID!) {
    deleteTape(id: $id) {
      message
    }
  }
`;


export const LOAN_TAPES_ADD = gql`
  mutation LOAN_TAPES_ADD($items: String!, $totalBorrowed: Float!, $totalPayback: Float!, $adminFee: Float!) {
    addTape(items: $items, totalBorrowed: $totalBorrowed, totalPayback: $totalPayback, adminFee: $adminFee) {
      message
    }
  }
`;

export const LOAN_TAPES = gql`
  query LOAN_TAPES {
    tapes {
      id
      items
      totalBorrowed
      totalPayback
      adminFee
      createdAt
    }
  }
`;

// export const DC_ISSUE_PIN = gql`
//   mutation DC_ISSUE_PIN($userId: ID!, $customerId: String!, $cardLimit: String!) {
//     dcIssueCard(userId: $userId, customerId: $customerId, cardLimit: $cardLimit) {
//       message
//     }
//   }
// `;


export const ADMIN_UPDATE_LOAN_ADJ = gql`
  mutation ADMIN_UPDATE_LOAN_ADJ($id: ID!, $adjId: ID) {
    updateLoanAdjudicator(id: $id, adjId: $adjId) {
      message
    }
  }
`;

export const ADMIN_REMOVE_LOAN_ADJ = gql`
  mutation ADMIN_UPDATE_LOAN_ADJ($id: ID!) {
    removeLoanAdjudicator(id: $id) {
      message
    }
  }
`;

// Create a new loan request based on the params provided
export const ADMIN_UPDATE_LOAN_INFORMATION = gql`
  mutation ADMIN_UPDATE_LOAN_INFORMATION(
    $id: ID
    $userId: String
    $name: String
    $phone: String
    $street: String
    $suite: String
    $city: String
    $postal: String
    $province: String
    $country: String
    $dob: String
    $gender: String
  ) {
    updateLoanInformation(
      id: $id
      userId: $userId
      name: $name
      phone: $phone
      street: $street
      suite: $suite
      city: $city
      postal: $postal
      province: $province
      country: $country
      dob: $dob
      gender: $gender
    ) {
      message
    }
  }
`;

// Create a new loan request based on the params provided
export const ADMIN_UPDATE_CARD_LIMIT = gql`
  mutation ADMIN_UPDATE_CARD_LIMIT(
    $id: ID
    $cardLimit: String
  ) {
    updateCardLimit(
      id: $id
      cardLimit: $cardLimit
    ) {
      message
    }
  }
`;


// Create a new loan request based on the params provided
export const ADMIN_UPDATE_CARD_INFORMATION = gql`
  mutation ADMIN_UPDATE_CARD_INFORMATION(
    $id: ID
    $userId: String
    $name: String
    $phone: String
    $street: String
    $suite: String
    $city: String
    $postal: String
    $province: String
    $country: String
    $dob: String
    $gender: String
  ) {
    updateCardInformation(
      id: $id
      userId: $userId
      name: $name
      phone: $phone
      street: $street
      suite: $suite
      city: $city
      postal: $postal
      province: $province
      country: $country
      dob: $dob
      gender: $gender
    ) {
      message
    }
  }
`;

// Create a new loan request based on the params provided
export const ADMIN_UPDATE_LOAN_TERMS = gql`
  mutation ADMIN_UPDATE_LOAN_TERMS(
    $id: ID
    $userId: String
    $loanAmount: Float!
    $loanFee: Float!
    $loanFeeTax: Float!
    $paymentCycleStartDate: DateTime!
    $paymentCycleEndDate: DateTime!
    $paymentCycleSchedule: [DateTime!]!
    $paymentCycleFrequency: String!
    $paymentCycleDuration: String!
    $paymentCycleAmount: Float!
    $employmentAndIncome: [EmploymentItemType!]!
    $purposeOfBorrow: String!
    $borrowReason: String!
  ) {
    updateLoanTerms(
      id: $id
      userId: $userId
      loanAmount: $loanAmount
      loanFee: $loanFee
      loanFeeTax: $loanFeeTax
      paymentCycleStartDate: $paymentCycleStartDate
      paymentCycleEndDate: $paymentCycleEndDate
      paymentCycleSchedule: $paymentCycleSchedule
      paymentCycleFrequency: $paymentCycleFrequency
      paymentCycleDuration: $paymentCycleDuration
      paymentCycleAmount: $paymentCycleAmount
      employmentAndIncome: $employmentAndIncome
      purposeOfBorrow: $purposeOfBorrow
      borrowReason: $borrowReason
    ) {
      message
    }
  }
`;

export const ADMIN_CREATE_USER = gql`
  mutation ADMIN_CREATE_USER(
    $email: String!
    $password: String!
    $name: String!
    $phone: String!
    $dob: String!
    $permissions: [Permission]!
    $emailPassword: Boolean
  ) {
    createUser(
      email: $email
      password: $password
      name: $name
      phone: $phone
      dob: $dob
      permissions: $permissions
      emailPassword: $emailPassword
    ) {
      id
      permissions
      name
      email
    }
  }
`;

export const ADMIN_UPDATE_PERMISSIONS = gql`
  mutation updatePermissions($permissions: [Permission], $userId: ID!) {
    updatePermissions(permissions: $permissions, userId: $userId) {
      id
      permissions
      name
      email
    }
  }
`;
export const ADMIN_UPDATE_ACCOUNT_STATUS = gql`
  mutation ADMIN_UPDATE_ACCOUNT_STATUS($id: ID!, $status: String!) {
    updateAccountStatus(id: $id, status: $status) {
      message
    }
  }
`;

export const ADMIN_VERIFY_ACCOUNT  = gql`
  mutation ADMIN_VERIFY_ACCOUNT($id: ID!) {
    verifyAccount(id: $id) {
      message
    }
  }
`;

// Returns loans based on statuses for the currently signed in user
export const ADMIN_CARD_LEADS = gql`
  query ADMIN_CARD_LEADS {
    cardLeads {
      id
      name
      email
      phone
      residentialAddress {
        country
        city
        street
        suite
        province
        postal
      }
      documents {
        govIssuedId
        proofOfResidence
        bankStatement
        directDepositForm
        envelopeStatus
        envelopeUri
        envelopeId
      }
      dob
      gender
      employerName
      employerPhone
      employerJobTitle
      monthlyIncome
      cardType
      cardFee
      createdAt
      verified
      campaign
    }
  }
`;
