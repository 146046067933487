import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { isValidPhoneNumber } from "react-phone-number-input";
import { setReactSelect, getUserDetails } from "../util";

import {
  FormDobSelect,
  FormGenderSelect,
  FormPhoneInput,
  FormProvinceSelect,
} from ".";

import {
  ADMIN_UPDATE_CARD_LIMIT,
  USER_CARDS_QUERY,
  CARD_REQUEST_BYID_QUERY,
} from "../graphql";

const EditCardInformation = ({ card }) => {
  const [modal, toggle] = useState(false);

  const [updateCardInformation, { loading }] = useMutation(
    ADMIN_UPDATE_CARD_LIMIT
  );

 
  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      cardLimit: card.cardLimit !== null ? card.cardLimit : null
    },
  });

  const handleSaveInformation = async (data) => {
    if (data === "") {
      errors.showMessages();
      return;
    }
    try {
      const payload = {
        id: card.id,
        cardLimit: data.cardLimit
      };

      await updateCardInformation({
        variables: {
          ...payload,
        },
        refetchQueries: [
          {
            query: USER_CARDS_QUERY,
          },
          {
            query: CARD_REQUEST_BYID_QUERY,
            variables: {
              id: card.id,
            },
          },
        ],
      });

      reset({
        ...data,
      });
      toast.success("Successfully Updated card");
      toggleModal(!modal);
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };
  const toggleModal = () => toggle(!modal);

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} size="lg">
        <ModalHeader>Edit Information</ModalHeader>
        <ModalBody>
          {loading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          <div className="card">
            <form
              className={" needs-validation tooltip-validation"}
              noValidate=""
              onSubmit={handleSubmit(handleSaveInformation)}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="validationTooltip01"
                      >
                        Enter Limit
                      </label>
                      <input
                        className="form-control"
                        id="validationTooltip01"
                        name="cardLimit"
                        maxLength={50}
                        placeholder="ex: 1200"
                        ref={register({
                          required: true,
                        })}
                        type="text"
                      />
                      {errors.cardLimit && <span>First Name is required</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-prev btn-success btn-lg pull-right"
                >
                  Update Limit
                </button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <button disabled={loading} onClick={toggleModal}>
        Edit Card Limit
      </button>
    </React.Fragment>
  );
};

export default withRouter(EditCardInformation);
