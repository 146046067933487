import React, { useState } from "react";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  ADMIN_UPDATE_LOAN_ADJ,
  USER_LOAN_QUERY,
  USER_LOAN_QUERY_BYID,
} from "../graphql";

const AssignLoanAdjudicator = ({ loanId, meId }) => {
  const [alertBox, setAlertBox] = useState(false);

  const [assignAdj, { loading }] = useMutation(ADMIN_UPDATE_LOAN_ADJ);

  const handleAssignLoan = async () => {
    let payload = {
      id: loanId,
      adjId: meId,
    };

    try {
      await assignAdj({
        variables: {
          ...payload,
        },
        refetchQueries: [
          {
            query: USER_LOAN_QUERY,
          },
          {
            query: USER_LOAN_QUERY_BYID,
            variables: {
              id: loanId,
            },
          },
        ],
      });

      toast.success("Successfully assigned this application");
      setAlertBox(false);
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  return (
    <React.Fragment>
      {alertBox ? (
        <SweetAlert
          confirmBtnBsStyle={""}
          confirmBtnText="Continue"
          onCancel={() => setAlertBox(false)}
          onConfirm={() => handleAssignLoan()}
          showCancel
          title="Are you sure?"
          type={""}
        >
          {loading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          You are about to assign yourself to this loan
        </SweetAlert>
      ) : (
        ""
      )}
      <button onClick={() => setAlertBox(true)}>Assign Loan</button>
    </React.Fragment>
  );
};

export default AssignLoanAdjudicator;
