// React
import React from "react";
import { ToastContainer } from "react-toastify";

// Import and Config Apollo/Graphql
import { ApolloProvider } from "@apollo/client";
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { client, cache } from "./graphql/client";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store/index";

// Everything Else
import Routes from "./components/Routes";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

// Main Css
import "./index.scss";

// import default style
import "rsuite/dist/styles/rsuite-default.css";

const appRoot = document.getElementById("app-root");

async function Root() {
  // Initialize apollo's cache store
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  ReactDOM.render(
    <ApolloProvider client={client}>
      <div className="App">
        <ToastContainer />
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    </ApolloProvider>,
    appRoot
  );
}

Root();

serviceWorker.unregister();