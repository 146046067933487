import { gql } from '@apollo/client';

// Returns all employment data for the current loan
export const REFERRALS_BY_EMAIL = gql`
  query REFERRALS_BY_EMAIL($email: String, $campaign: String) {
    referralsByUserEmail(email: $email, campaign: $campaign) {
        id
        ref_by
        ref_name
        ref_id
        ref_email
        campaign
        createdAt
    }
  }
`;