/* eslint-disable react/no-multi-comp */
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import { useUser } from "../hooks";
import { FormGenderSelect, FormDobSelect, FormPhoneInput } from "../components";
import { isValidPhoneNumber } from "react-phone-number-input";
import { setReactSelect } from "../util";

import {
  USER_PROFILE_QUERY,
  USER_UPDATE_INFORMATION_MUTATION,
  ADMIN_PROFILE_BYID_QUERY,
} from "../graphql";

const UserEditForm = ({ me }) => {
  const user = useUser();
  const isAdmin =
    user.permissions.includes("ADMIN") ||
    user.permissions.includes("ADJUDICATOR");

  const [dobDay, dobMonth, dobYear] = me.dob ? me.dob.split("-") : "DD-MM-YYYY";

  
  let defaultValues = {
    fname: me.name.split(" ").length > 0 ? me.name.split(" ")[0] : me.name,
    lname: me.name.split(" ").length > 0 ? me.name.split(" ")[1] : "",
    email: me.email,
    phone: me.phone,
    city: me.residentialAddress && me.residentialAddress.city,
    province: me.residentialAddress && me.residentialAddress.province,
    country: me.residentialAddress && me.residentialAddress.country,
    postal: me.residentialAddress && me.residentialAddress.postal,
    street: me.residentialAddress && me.residentialAddress.street,
    suite: me.residentialAddress && me.residentialAddress.suite,
    gender: me.gender,
    dobDay,
    dobMonth,
    dobYear,
  };

  defaultValues = setReactSelect(
    ["gender", "dobDay", "dobMonth", "dobYear"],
    defaultValues
  );

  const {
    register: profileFormRegister,
    handleSubmit: profileFormSubmit,
    errors: profileFormErrors,
    control,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  }); // initialise the hook

  const [profileFormValidateClass, profileFormSetValidateClass] = useState(
    false
  );

  const [
    updateUser,
    { loading: profileFormLoading, error: profileUpdateError },
  ] = useMutation(USER_UPDATE_INFORMATION_MUTATION);

  useEffect(() => {
    if (profileUpdateError && profileUpdateError.message !== "") {
      toast.error(profileUpdateError.message.replace("GraphQL error: ", ""));
    }
  }, [profileUpdateError]);

  const onSubmitProfile = async (data) => {
    if (data !== "") {
      const dob = `${data.dobDay.label}-${data.dobMonth.label}-${data.dobYear.label}`;
      const refetchList = [
        {
          query: USER_PROFILE_QUERY,
          fetchPolicy: "no-cache",
          variables: {
            id: me.id,
          },
        },
      ];
      if (isAdmin) {
        refetchList.push({
          query: ADMIN_PROFILE_BYID_QUERY,
          fetchPolicy: "no-cache",
          variables: {
            id: me.id,
          },
        });
      }
      const query = await updateUser({
        variables: {
          ...data,
          id: me.id,
          name: data.fname + " " + data.lname,
          dob,
          country: "canada",
          gender: data.gender.label.toUpperCase(),
          phone: data.phone,
        },
        refetchQueries: refetchList,
      });
      if (query) {
        toast.success("Successfully Updated Your Profile");
      }
    } else {
      profileFormErrors.showMessages();
    }
  };

  return (
    <Fragment>
      <form
        className={`card needs-validation tooltip-validation ${
          profileFormValidateClass ? "validateClass" : ""
        }`}
        noValidate=""
        onSubmit={profileFormSubmit(onSubmitProfile)}
      >
        {profileFormLoading && (
          <div className="loader-box loader-form-wrapper">
            <span className="rotate dotted" />
          </div>
        )}
        <div className="card-header b-l-primary">
          <h4 className="card-title mb-0">Edit Profile</h4>
          <div className="card-options">
            <a
              className="card-options-collapse"
              data-toggle="card-collapse"
              href="#javascript"
            >
              <i className="fe fe-chevron-up" />
            </a>
            <a
              className="card-options-remove"
              data-toggle="card-remove"
              href="#javascript"
            >
              <i className="fe fe-x" />
            </a>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip01">
                  Firstname
                </label>
                <input
                  className="form-control"
                  id="validationTooltip01"
                  name="fname"
                  placeholder="Firstname"
                  ref={profileFormRegister({
                    required: true,
                  })}
                  type="text"
                />
                {profileFormErrors.fname && <span>First Name is required</span>}
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip02">
                  Lastname
                </label>
                <input
                  className="form-control"
                  id="validationTooltip02"
                  name="lname"
                  placeholder="Lastname"
                  ref={profileFormRegister({
                    required: true,
                  })}
                  type="text"
                />
                {profileFormErrors.lname && <span>Last Name is required</span>}
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip03">
                  Phone
                </label>
                <FormPhoneInput
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                />
                {profileFormErrors.phone && (
                  <span>A valid phone number is required</span>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip04">
                  Gender
                </label>
                <FormGenderSelect
                  control={control}
                  rules={{
                    required: true,
                    validate: (select) => select.value !== undefined,
                  }}
                />
                {profileFormErrors.gender && (
                  <span>Your gender is required</span>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="col-form-label" htmlFor="validationTooltip04">
                Date Of Birth
              </label>
              <div className="form-row">
                <FormDobSelect
                  control={control}
                  rules={{
                    required: true,
                    validate: (select) => select.value !== undefined,
                  }}
                />
                {(profileFormErrors.dobDay ||
                  profileFormErrors.dobMonth ||
                  profileFormErrors.dobYear) && (
                  <span style={{ color: "red" }}>
                    Your date of birth is required
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip05">
                  Address
                </label>
                <input
                  className="form-control"
                  id="validationTooltip05"
                  name="street"
                  placeholder="Home Address"
                  ref={profileFormRegister({
                    required: true,
                  })}
                  type="text"
                />
                {profileFormErrors.street && <span>Address is required</span>}
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip06">
                  City
                </label>
                <input
                  className="form-control"
                  htmlFor="validationTooltip07"
                  id="validationTooltip06"
                  name="city"
                  placeholder="City"
                  ref={profileFormRegister({
                    required: true,
                  })}
                  type="text"
                />
                {profileFormErrors.city && <span>City is required</span>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip07">
                  Province
                </label>
                <select
                  className="form-control mb-1"
                  name="province"
                  ref={profileFormRegister({
                    required: true,
                    validate: (value) => value !== "-- Select --",
                  })}
                >
                  <option>-- Select --</option>
                  <option>Ontario</option>
                  <option>Quebec</option>
                  <option>Nova Scotia</option>
                  <option>New Brunswick</option>
                  <option>Manitoba</option>
                  <option>British Columbia</option>
                  <option>Prince Edward Island</option>
                  <option>Saskatchewan</option>
                  <option>Alberta</option>
                  <option>Newfoundland and Labrador</option>
                  <option>Northwest Territories</option>
                  <option>Yukon</option>
                  <option>Nunavut</option>
                </select>
                {profileFormErrors.province && (
                  <span>Province is required</span>
                )}
              </div>
            </div>

            <div className="col-sm-6 col-md-4">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip08">
                  Suite
                </label>
                <input
                  className="form-control"
                  id="validationTooltip08"
                  name="suite"
                  placeholder="Suite"
                  ref={profileFormRegister({
                    required: false,
                  })}
                  type="text"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip08">
                  Postal Code
                </label>
                <input
                  className="form-control"
                  id="validationCustom04"
                  name="postal"
                  placeholder="1A2 B3C"
                  ref={profileFormRegister({
                    required: true,
                    validate: (value) => {
                      var regex = new RegExp(
                        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                      );
                      return regex.test(value) || "Invalid postal code.";
                    },
                  })}
                  type="text"
                />
                {profileFormErrors.postal && (
                  <span>
                    {profileFormErrors.postal.message !== ""
                      ? profileFormErrors.postal.message
                      : "Required"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            className="btn btn-primary"
            disabled={profileFormLoading}
            onClick={() =>
              profileFormSetValidateClass(!profileFormValidateClass)
            }
            type="submit"
          >
            Update Profile
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default UserEditForm;
