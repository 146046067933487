/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import moment from "moment";

import { ADMIN_USER_LOANS_QUERY } from "../graphql";

var images = require.context("../assets/images", true);

const UserLoans = ({ isAdmin, userId }) => {
  const { loading, data } = useQuery(ADMIN_USER_LOANS_QUERY, {
    variables: {
      id: userId,
    },
  });
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="card cuanimated bounceInLeft" id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
              {" "}
              {!loading && data.userLoanApplications
                ? "Found " + data.userLoanApplications.length + " Record(s)"
                : "Loading..."}{" "}
            </h5>
          </div>
          <div className="card-body">
            {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: "inherit" }}
              >
                <span className="rotate dotted" />
              </div>
            ) : data.userLoanApplications.length > 0 ? (
              <div className="table-responsive active-order-table">
                <table className="table table-bordernone">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      {isAdmin && <th scope="col">User</th>}
                      <th scope="col">App ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Documents</th>
                      <th scope="col">Term</th>
                      <th scope="col">Amount</th>
                      <th scope="col">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      data.userLoanApplications.map((app, index) => (
                        <Row data={app} key={index} showUser={isAdmin} />
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: "300px", marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images("./search-not-found.png")}
                    style={{ marginTop: "-40px" }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Row = ({ data, showUser, index }) => (
  <tr key={index}>
    <td>
      <p>{moment(data.createdAt).format("LL")}</p>
    </td>
    {showUser && (
      <td>
        <p>{data.email.substr(0, 10)}...</p>
      </td>
    )}
    <td>
      <p>{data.id.substr(0, 10)}</p>
    </td>
    <td>
      <button
        className={`btn ${(function () {
          switch (data.status.toLowerCase()) {
            case "approved":
              return "btn-success";
            case "denied":
              return "btn-danger";
            case "inreview":
              return "btn-warning";
            default:
              return "btn-light active txt-dark";
          }
        })()} btn-pill`}
        type="button"
      >
        {data.status}
      </button>
    </td>
    <td>
      <div className="customers">
        <ul>
          <li className="d-inline-block" style={{ paddingBottom: "2px" }}>
            {data.documents.govIssuedId.length > 0 &&
              data.documents.govIssuedId &&
              data.documents.govIssuedId[0].split(".").pop().toLowerCase() !==
                "pdf" && (
                <img
                  alt="gov issued id"
                  className="img-40 rounded-circle"
                  src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.govIssuedId[0]}`.replace(
                    "https://api.lendle.com/https://lendle.com",
                    "https://lendle.com"
                  )}
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              )}

            {data.documents.govIssuedId.length > 0 &&
              data.documents.govIssuedId &&
              data.documents.govIssuedId[0].split(".").pop().toLowerCase() ===
                "pdf" && (
                <img
                  alt="gov issued id"
                  className="img-40 rounded-circle"
                  src={images("./pdf.png")}
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              )}
          </li>
          <li className="d-inline-block" style={{ paddingBottom: "2px" }}>
            {data.documents.proofOfResidence.length > 0 &&
              data.documents.proofOfResidence &&
              data.documents.proofOfResidence[0]
                .split(".")
                .pop()
                .toLowerCase() !== "pdf" && (
                <img
                  alt="bank statement"
                  className="img-40 rounded-circle"
                  src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.documents.proofOfResidence[0]}`.replace(
                    "https://api.lendle.com/https://lendle.com",
                    "https://lendle.com"
                  )}
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              )}

            {data.documents.proofOfResidence.length > 0 &&
              data.documents.proofOfResidence &&
              data.documents.proofOfResidence[0]
                .split(".")
                .pop()
                .toLowerCase() === "pdf" && (
                <img
                  alt="bank statement"
                  className="img-40 rounded-circle"
                  src={images("./pdf.png")}
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              )}
          </li>
        </ul>
      </div>
    </td>
    <td>
      <p>{data.paymentCycleDuration}</p>
    </td>
    <td>
      <p>${data.loanAmount}</p>
    </td>
    <td>
      <Link to={`${process.env.PUBLIC_URL}/dashboard/applications/${data.id}`}>
        <button className="btn btn-pill btn-outline-light">View</button>
      </Link>
    </td>
  </tr>
);

export default UserLoans;
