/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-undef */
import React, { Fragment, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { USERS_QUERY, USER_ACTIVITY_QUERY } from "../../../graphql";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
  DollarSign,
  CheckCircle,
  MoreHorizontal,
  FileText,
  User,
} from "react-feather";

import getActivity from "../../../services/activities";
import UserMap from "../../../components/map/userMap";

import { saleOptions } from "../../../components/charts-data/default";
import { Line, Doughnut } from "react-chartjs-2";
import CountUp from "react-countup";

var images = require.context("../../../assets/images", true);

const primary = localStorage.getItem("primary_color") || "#4466f2";

var taskChartOptions = {
  maintainAspectRatio: false,
  tooltips: {
    bodyFontSize: 16,
  },
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
      color: "white",
    },
  },
};

const AdminDash = ({ metrics }) => {
  const { data, loading: userLoading } = useQuery(USERS_QUERY);

  const { data: userActivities, loading: userActivitiesLoading } = useQuery(
    USER_ACTIVITY_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [profitDataState, setProfitDataState] = useState({});
  const [markers, setMarkers] = useState([]);
  const [taskChartData, setTaskChartData] = useState({
    labels: [
      "Facebook",
      "Instagram",
      "Google",
      "Yahoo",
      "Friend",
      "Email",
      "Word of mouth",
      "organic",
    ],
    datasets: [
      {
        lagend: "none",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: [primary, "#f85370", "red"],
        backgroundColor: [primary, "#f85370", "red"],
        fill: "origin",
      },
    ],
  });

  const { loansApproved, loansTotalProfit } = metrics;

  useMemo(() => {
    if (!userLoading && data) {
      setMarkers(
        data.users
          .filter((user) => user.lat !== null || user.long !== null)
          .map((user) => ({
            key: user.name,
            position: [user.lat, user.long],
            content: user.source,
          }))
      );
      const usersWithSource = data.users.filter((user) => user.source !== null);
      const fb = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "facebook"
      );
      const organic = data.users.filter((user) => user.source === null);
      const google = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "google"
      );
      const instagram = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "instagram"
      );
      const yahoo = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "yahoo"
      );
      const email = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "email"
      );
      const friend = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "friend"
      );
      const wom = usersWithSource.filter(
        (user) => user.source.toLowerCase() === "word of mouth"
      );

      setTaskChartData({
        labels: [
          "Facebook",
          "Instagram",
          "Google",
          "Yahoo",
          "Friend",
          "Email",
          "Word of mouth",
          "organic",
        ],
        datasets: [
          {
            lagend: "none",
            data: [
              fb.length,
              instagram.length,
              google.length,
              yahoo.length,
              friend.length,
              email.length,
              wom.length,
              organic.length,
            ],
            borderColor: [
              primary,
              "#f85370",
              "red",
              "green",
              "orange",
              "purple",
              "pink",
            ],
            backgroundColor: [
              primary,
              "#f85370",
              "red",
              "green",
              "orange",
              "purple",
              "pink",
            ],
            fill: "origin",
          },
        ],
      });
    }
    const sept = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "08 2020")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const oct = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "09 2020")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const nov = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "10 2020")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const dec = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "11 2020")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const jan = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "12 2020")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const feb = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "01 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const mar = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "02 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const apr = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "03 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const may = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "04 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const jun = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "05 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const jul = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "06 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);
    const aug = loansApproved
      .filter((loan) => moment(loan.createdAt).format("MM YYYY") === "07 2021")
      .reduce(function (prev, current) {
        let payments = current.payments !== null ? current.payments.reduce((prev,curr) => ({ amount: prev.amount + curr.amount}), { amount: 0}) : 0;
        return parseFloat(prev) + parseFloat(payments.amount);
      }, 0);

    const fundsTotalProfit = [
      sept,
      oct,
      nov,
      dec,
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
    ];

    const profitData = {
      labels: [
        "",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
      ],
      datasets: [
        {
          lagend: "none",
          data:
            loansApproved.length > 0
              ? [...fundsTotalProfit]
              : [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
          borderColor: primary,
          pointBackgroundColor: primary,
          backgroundColor: "transparent",
          fill: "origin",
        },
      ],
    };

    setProfitDataState(profitData);
  }, [loansApproved, data, userLoading]);

  return (
    <Fragment>
      {!userLoading && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 xl-100">
              <div className="row">
                <div
                  className="col-md-12 ecommerce-slider"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    columnGap: "1em",
                    gridTemplateRows: "auto",
                  }}
                >
                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example"
                    >
                      <div className="card-body ecommerce-icons text-center">
                        <FileText />
                        <div>
                          <span>Total Funds Issued</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          $
                          <CountUp
                            className="counter"
                            end={metrics.loansTotalAmount}
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example"
                    >
                      <div className="card-body ecommerce-icons text-center">
                        <User />
                        <div>
                          <span>Total Users Registered</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          <CountUp
                            className="counter"
                            end={data.users.length}
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example"
                    >
                      <div className="card-body ecommerce-icons text-center">
                        <MoreHorizontal />
                        <div>
                          <span>Total Apps Pending</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          <CountUp
                            className="counter"
                            end={metrics.loansPending.length}
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example"
                    >
                      <div className="card-body ecommerce-icons text-center">
                        <CheckCircle />
                        <div>
                          <span>Total Apps Approved</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          <CountUp
                            className="counter"
                            end={metrics.loansApproved.length}
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example"
                    >
                      <div className="card-body ecommerce-icons text-center">
                        <DollarSign />
                        <div>
                          <span>Total Profits</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          $
                          <CountUp
                            className="counter"
                            end={loansTotalProfit}
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className="card cuanimated bounceInLeft"
                    id="animated-example"
                  >
                    <div className="card-header">
                      <h5>Total Profits - ${loansTotalProfit}</h5>
                    </div>
                    <div className="card-body charts-box">
                      <div className="flot-chart-container">
                        <div className="flot-chart-placeholder" id="graph123">
                          <Line data={profitDataState} options={saleOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header project-header">
                  <div className="row">
                    <div className="col-sm-8">
                      <h5>User Distribution</h5>
                    </div>
                    <div className="col-sm-4">
                      <div className="select2-drpdwn-project select-options">
                        <select
                          className="form-control form-control-primary btn-square"
                          name="select"
                        >
                          <option value="opt1">Today</option>
                          <option value="opt2">Yesterday</option>
                          <option value="opt3">Tomorrow</option>
                          <option value="opt4">Monthly</option>
                          <option value="opt5">Weekly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body chart-block chart-vertical-center project-charts">
                  <Doughnut data={taskChartData} options={taskChartOptions} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5>Users Demographic</h5>
                </div>
                <div className="card-body chart-block ecommerce-income">
                  <UserMap data={markers} />
                </div>
              </div>
            </div>

            <div className="col-xl-4 xl-50 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5>Account Log</h5>
                </div>
                <div className="card-body height-equal log-content">
                  {!userActivitiesLoading &&
                  userActivities.userActivities.length > 0 ? (
                    userActivities.userActivities.map((activity, index) => {
                      const content = getActivity(activity.type);
                      return (
                        <div key={index} className="logs-element">
                          <div className="circle-double-odd" />
                          <span>{content.title}</span>
                          <span className="pull-right">
                            {moment(activity.createdAt).fromNow()}
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="search-not-found text-center ng-star-inserted"
                      style={{
                        height: "300px",
                        marginTop: 0,
                      }}
                    >
                      <div className="">
                        <img
                          alt=""
                          className="second-search"
                          src={images("./search-not-found.png")}
                          style={{ marginTop: "-50px" }}
                        />
                        <p className="mb-0">No Activities Found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 xl-50">
              <div className="card">
                <div className="card-header">
                  <h5>Recent Loan Requests</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive shopping-table text-center">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">User</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Term</th>
                          <th scope="col">Admin Fee</th>
                          <th scope="col">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {metrics.loansPending.slice(1, 10).map((loan, key) => (
                          <tr key={index}>
                            <td>{loan.id.substr(0, 6)}</td>
                            <td>{loan.email}</td>
                            <td>${loan.loanAmount}</td>
                            <td>{loan.paymentCycleDuration}</td>
                            <td>
                              {parseFloat(loan.loanFee) +
                                parseFloat(loan.loanFeeTax)}
                            </td>
                            <td>
                              <Link
                                to={`${process.env.PUBLIC_URL}/dashboard/applications/${loan.id}`}
                              >
                                <button cclassName="btn btn-light txt-dark">
                                  View App
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AdminDash;