import { useQuery } from "@apollo/client";
import { USER_PROFILE_QUERY } from "../graphql";

const useUser = () => {
  // FETCH USER LOGGED IN
  const { data } = useQuery(USER_PROFILE_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  if (data) {
    return data.me;
  }
};

export default useUser;
