import React, { Fragment } from 'react';
import Comments from './comments'
import AddComment from './addcomment'

const StickyNotes = ({ id, userid }) => {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-4">
            <AddComment
              id={id}
              userid={userid}
            />
         
          </div>
          <div className="col-sm-8">
            <Comments
              id={id}
              userid={userid}
            />
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default StickyNotes;