import React from "react";

import {
  EditLoanInformation,
  EditLoanTerms,
  UpdateLoanStatus,
} from "../../components";

export default function UserMenu({ data }) {
  return (
    <>
      {data.status === "PENDING" && <EditLoanInformation loan={data} />}
      {data.status === "PENDING" && <EditLoanTerms loan={data} />}

      {data.status !== "WITHDRAW" &&
        data.status !== "ARCHIVE" &&
        data.status !== "APPROVED" && (
          <UpdateLoanStatus
            loan={data}
            status="WITHDRAW"
            title="Withdraw Loan"
          />
        )}
    </>
  );
}
