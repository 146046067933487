import React from 'react';
import moment from 'moment';
import { ShoppingBag } from 'react-feather';
import getActivity from '../services/activities';

const ActivityItem = ({ activity }) => {
  const content = getActivity(activity.type);
  return (
    <div className="media">
      <div className="gradient-round m-r-30 gradient-line-1">
        <ShoppingBag />
      </div>
      <div className="media-body">
        <h6>
          {content.title}
          <span className="pull-right f-14">
            {moment(activity.createdAt).fromNow()}
          </span>
        </h6>
        <p>{content.message}</p>
      </div>
    </div>
  );
};

export default ActivityItem;
