import React, { Fragment } from 'react';
import CountUp from 'react-countup';
import { DollarSign } from 'react-feather';

import MetricItem from './MetricItem';
import FundsChart from './FundsChart';

import currency from 'currency.js';

const Metrics = ({
  metrics: { loansTotalAmount, loanBalance, loansTotalAmountPaid },
  loansApproved,
  loanFee,
}) => {
  return (
    <Fragment>
      <div className="col-md-4 metricone">
        <MetricItem icon="CheckCircle">
          <Fragment>
            <h5 className="mt-0 mb-0 f-w-600">
              <DollarSign />
              <span>
                <CountUp
                  className="counter"
                  decimals={2}
                  duration={2}
                  end={parseFloat(loansTotalAmount)}
                  start={0}
                />
              </span>
            </h5>
            <p>Total Funded</p>
          </Fragment>
        </MetricItem>
      </div>
      <div className="col-md-4 metrictwo">
        <MetricItem icon="CreditCard">
          <Fragment>
            <h5 className="mt-0 mb-0 f-w-600">
              <DollarSign />
              <span>
                <CountUp
                  className="counter"
                  decimals={2}
                  duration={2}
                  end={currency(loanBalance).add(loanFee).value}
                  start={0}
                />
              </span>
            </h5>
            <p>Current Loan</p>
          </Fragment>
        </MetricItem>
      </div>
      <div className="col-md-4 metricthree">
        <MetricItem icon="DollarSign">
          <Fragment>
            <h5 className="mt-0 mb-0 f-w-600">
              <DollarSign />
              <span>
                <CountUp
                  className="counter"
                  decimals={2}
                  duration={2}
                  end={parseFloat(loansTotalAmountPaid)}
                  start={0}
                />
              </span>
            </h5>
            <p>Available Credit</p>
          </Fragment>
        </MetricItem>
      </div>
      <div className="col-lg-12 fundstats">
        <FundsChart funds={loansApproved} />
      </div>
    </Fragment>
  );
};

export default Metrics;
