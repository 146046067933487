import React from "react";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";

// Auth components we need
import {
  FormDobSelect,
  FormGenderSelect,
  FormPhoneInput,
  FormProvinceSelect,
} from "../../../components";

import { setReactSelect, saveReactSelect } from "../../../util";

const BasicInfo = ({
  wizardData,
  setWizardData,
  navigation: { next, previous },
}) => {
  wizardData = setReactSelect(
    ["province", "gender", "dobDay", "dobMonth", "dobYear"],
    wizardData
  );
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      ...wizardData,
    },
  });

  const onSubmit = (data) => {
    if (data === "") {
      return;
    }

    saveReactSelect(
      ["province", "gender", "dobDay", "dobMonth", "dobYear"],
      wizardData,
      data
    );

    setWizardData({ ...wizardData, ...data });
    next();
  };

  return (
    <div className="card">
      <form
        className={" needs-validation tooltip-validation"}
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card-header">
          <h5>Step 2 - Personal Information</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip01">
                  First Name
                </label>
                <input
                  className="form-control"
                  id="validationTooltip01"
                  name="firstname"
                  maxLength={50}
                  placeholder="Firstname"
                  ref={register({
                    required: true,
                  })}
                  type="text"
                />
                {errors.firstname && <span>First Name is required</span>}
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip02">
                  Last Name
                </label>
                <input
                  className="form-control"
                  id="validationTooltip02"
                  name="lastname"
                  maxLength={50}
                  placeholder="Lastname"
                  ref={register({
                    required: true,
                  })}
                  type="text"
                />
                {errors.lastname && <span>Last Name is required</span>}
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip04">
                  Phone Number
                </label>{" "}
                <FormPhoneInput
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                />
                {errors.phone && <span>A valid phone number is required</span>}
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="col-form-label" htmlFor="validationTooltip04">
                  Gender
                </label>
                <FormGenderSelect
                  control={control}
                  rules={{
                    required: true,
                    validate: (select) => select.value !== undefined,
                  }}
                />
                {errors.gender && <span>Your gender is required</span>}
              </div>
            </div>
            <div className="col-md-4">
              <label className="col-form-label" htmlFor="validationTooltip04">
                Date Of Birth
              </label>
              <div className="form-row">
                <FormDobSelect
                  control={control}
                  rules={{
                    required: true,
                    validate: (select) => select.value !== undefined,
                  }}
                />
                {(errors.dobDay || errors.dobMonth || errors.dobYear) && (
                  <span>Your date of birth is required</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip05">
                  Address
                </label>
                <input
                  className="form-control"
                  id="validationTooltip05"
                  name="street"
                  maxLength={150}
                  placeholder="Home Address"
                  ref={register({ required: true })}
                  type="text"
                />
                {errors.street && <span>Address is required</span>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip06">
                  City
                </label>
                <input
                  className="form-control"
                  htmlFor="validationTooltip07"
                  id="validationTooltip06"
                  name="city"
                  maxLength={150}
                  placeholder="City"
                  ref={register({ required: true })}
                  type="text"
                />
                {errors.city && <span>City is required</span>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip07">
                  Province
                </label>
                <FormProvinceSelect
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 10,
                    validate: (select) => select.value !== undefined,
                  }}
                />
                {errors.province && <span>Province is required</span>}
              </div>
            </div>

            <div className="col-sm-2 col-md-2">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip08">
                  Postal Code
                </label>
                <input
                  className="form-control"
                  id="validationCustom04"
                  name="postal"
                  placeholder="1A2 B3C"
                  ref={register({
                    required: true,
                    validate: (value) => {
                      var regex = new RegExp(
                        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                      );
                      return regex.test(value) || "Invalid postal code.";
                    },
                  })}
                  type="text"
                />
                {errors.postal && (
                  <span>
                    {errors.postal.message !== ""
                      ? errors.postal.message
                      : "Required"}
                  </span>
                )}
              </div>
            </div>

            <div className="col-sm-3 col-md-3">
              <div className="form-group">
                <label className="form-label" htmlFor="validationTooltip08">
                  Suite
                </label>
                <input
                  className="form-control"
                  id="validationTooltip08"
                  name="suite"
                  maxLength={10}
                  placeholder="Suite"
                  ref={register({ required: false })}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer mb-5">
          <button
            className="btn btn-prev btn-default btn-lg pull-left"
            onClick={previous}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-prev btn-success btn-lg pull-right"
            onClick={() => handleSubmit(onSubmit)}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default BasicInfo;
