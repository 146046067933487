import React from 'react';
import { DropdownItem } from 'reactstrap';

export default function ProfileMenu({
  user,
  onClickDeleteAccount,
  onClickRestoreAccount,
  onClickVerifyAccount,
  onClickResendVerifyLink,
}) {
  return (
    <>
      {user.status !== 'ARCHIVE' && (
        <DropdownItem onClick={onClickDeleteAccount}>
          Delete Account
        </DropdownItem>
      )}

      {user.status === 'ARCHIVE' && (
        <DropdownItem onClick={onClickRestoreAccount}>
          Restore Account
        </DropdownItem>
      )}

      {user.active !== true && (
        <DropdownItem onClick={onClickVerifyAccount}>
          Verify Account
        </DropdownItem>
      )}
      {user.active !== true && (
        <DropdownItem onClick={onClickResendVerifyLink}>
          Resend Email Verification
        </DropdownItem>
      )}
    </>
  );
}
