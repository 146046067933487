/* eslint-disable react/no-multi-comp */
import React from 'react';
import Comment from "./Comment";

import { useQuery } from '@apollo/client';
import { CARD_COMMENTS } from '../../../graphql';

var images = require.context('../../../assets/images', true);

export default function CommentsList({ id }) {
  const { data, loading: loadingComments } = useQuery(
    CARD_COMMENTS,
    {
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: CARD_COMMENTS,
          variables: {
            id: id,
          },
        },
      ],
    }
  );

  return (
    <div className="card">
      <div className="card-header b-l-primary">
        <h5>Comments</h5>
      </div>
      <div className="card-body activity-scroll">
        {!loadingComments ? (
          <div className="activity">
            {data && data.cardComments.length > 0 ? (
              data.cardComments.map((comment, index) => {
                return (
                  <Comment
                    comment={comment}
                    index={index}
                    key={index}
                  />
                );
              })
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: '300px', marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images('./search-not-found.png')}
                    style={{ marginTop: '-50px' }}
                  />
                  <p className="mb-0">No Comments Found</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className="loader-box loader-form-wrapper-static"
            style={{ position: 'inherit' }}
          >
            <span className="rotate dotted" />
          </div>
        )}
      </div>
    </div>
  );
}
