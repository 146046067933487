import React from "react";
import { useWindowResize } from "../hooks"; 
import { employmentTypePlaceholders } from "../constant/constants";

const FormEmploymentIncome = ({
  register,
  fields,
  remove,
  watch,
  showAddBtn,
  onAdd,
}) => {
  const size = useWindowResize();
  return (
    <div className="d-flex flex-column mt-2">
      {fields.map((field, index) => {
        const watchEmploymentType = watch(`employmentAndIncome[${index}].type`);
        return (
          <div className={`d-flex ${size.width < 600 ? "flex-column" : "flex-row"} mb-2`} key={field.id}>
            <select
              name={`employmentAndIncome[${index}].type`}
              placeholder="Select Employment Type"
              ref={register({ required: true })}
              className="form-control col-md-3 mr-3 mb-2"
            >
              <option selected={field.type === "Employed"} value="Employed">
                Employed
              </option>
              <option selected={field.type === "Student"} value="Student">
                Student
              </option>
              <option
                selected={field.type === "Secondary Income"}
                value="Secondary Income"
              >
                Secondary Income
              </option>
              <option
                selected={field.type === "Government Assistance"}
                value="Government Assistance"
              >
                Government Assistance
              </option>
            </select>

            <input
              type="text"
              defaultValue={field.desc}
              name={`employmentAndIncome[${index}].desc`}
              className="form-control col-md-3 mr-3 mb-2"
              placeholder={
                watchEmploymentType &&
                employmentTypePlaceholders[watchEmploymentType]
              }
              ref={register({ required: true })}
              hidden={!watchEmploymentType}
            />

            {watchEmploymentType && watchEmploymentType === "Employed" && (
              <input
                type="text"
                defaultValue={field.name}
                name={`employmentAndIncome[${index}].name`}
                placeholder="Employer Name"
                className="form-control col-md-3 mr-2 mb-2"
                hidden={!watchEmploymentType}
                ref={register()}
              />
            )}

            {fields.length > 1 && (
              <button
                type="button"
                className="btn btn-danger mr-2"
                onClick={() => remove(index)}
              >
                Delete
              </button>
            )}
            {/** If it is the last item then show the add button */}
            {showAddBtn && fields.length - 1 === index && (
              <button type="button" className="btn btn-success" onClick={onAdd}>
                Add More
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FormEmploymentIncome;
