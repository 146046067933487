import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const FormCancelCodeSelect = ({ control, rules }) => {
  const options = [
    { value: '00', label: 'Active' },
    { value: '05', label: 'Temporarily Closed' },
    { value: '05', label: 'Waiting for Activation '},
    { value: '41', label: 'Lost' },
    { value: '43', label: 'Stolen' },
    { value: '05', label: 'Other' },
    { value: '59', label: 'Fraud' },
    { value: '05', label: 'Customer Request' }
  ];
  return (
    <Controller
      as={Select}
      control={control}
      name="cancelreason"
      options={options}
      placeholder="Select Reason"
      rules={rules}
    />
  );
};

export default FormCancelCodeSelect;
