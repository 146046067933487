import React from "react";

class FileDialogue extends React.Component {
  componentDidMount() {
    this.fileSelector = this.buildFileSelector();
  }

  componentWillUnmount() {
    this.fileSelector.removeEventListener(
      "change",
      this.handleFileSelect.bind(this)
    );
  }

  handleFileSelect = (e) => {
    e.preventDefault();
    this.fileSelector.click();
  };

  buildFileSelector = () => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.addEventListener("change", this.handleFiles.bind(this));
    return fileSelector;
  };

  handleFiles(event) {
    const { onChange } = this.props;
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    onChange(file);
  }

  render() {
    return (
      <button
        className="btn btn-primary mt-3 mb-3"
        onClick={this.handleFileSelect}
      >
        Select File
      </button>
    );
  }
}

export default FileDialogue;
