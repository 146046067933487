/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Map as LeafletMap, TileLayer, GeoJSON, Marker, Popup } from 'react-leaflet';
import WorldData from 'world-map-geojson';

const UserMap = ({ data }) => {
  const [animate, setAnimate] = useState(false);
  const [latlan, setlatlan] = useState({
    lat: 51.2538,
    lng: 85.3232,
  });

  const markers = data
  
  const handleClick = (e) => {
    setlatlan({
      latlng: e.latlng,
    })
  }

  const toggleAnimate = () => {
    setAnimate({
      animate: !animate,
    })
  }

  const MyPopupMarker = ({ content, position }) => (
    <Marker position={position}>
      <Popup>{content}</Popup>
    </Marker>
  )

  const MyMarkersList = ({ markers }) => {
    const items = markers.map(({ key, ...props }, index) => (
      <MyPopupMarker
        key={index}
        {...props}
      />
    ))
    return <Fragment>{items}</Fragment>
  }
  return (
    <Fragment>
       <LeafletMap center={[43.651070, -79.347015]} zoom={5}>
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <MyMarkersList markers={markers} />
                                </LeafletMap>
    </Fragment>
  );
};

export default UserMap;