import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import { useUser } from "../hooks";
import seven from "../assets/images/user/7.jpg";
import customPassword from "../services/genPass";

import { USER_PASSWORD_UPDATE_QUERY } from "../graphql";

const UserPasswordForm = ({ me, viewingSelf }) => {
  const user = useUser();
  const isAdmin =
    user.permissions.includes("ADMIN") ||
    user.permissions.includes("ADJUDICATOR");
  const {
    register: passwordFormRegister,
    handleSubmit: passwordFormSubmit,
    errors: passwordFormErrors,
    setValue,
    watch,
  } = useForm({
    notifyUser: viewingSelf
      ? false
      : process.env.NODE_ENV === "development"
      ? false
      : true,
  }); // initialise the hook

  const [genPassword, setGenPassword] = useState();
  const [passwordFormValidateClass, passwordFormSetValidateClass] = useState(
    false
  );
  const [
    updatePassword,
    { loading: passwordUpdateLoading, error: passwordUpdateError },
  ] = useMutation(USER_PASSWORD_UPDATE_QUERY);

  useEffect(() => {
    if (passwordUpdateError && passwordUpdateError.message !== "") {
      toast.error(passwordUpdateError.message.replace("GraphQL error: ", ""));
    }
  }, [passwordUpdateError]);

  const onSubmitPasswordForm = async (data) => {
    if (data !== "") {
      try {
        await updatePassword({
          query: USER_PASSWORD_UPDATE_QUERY,
          variables: {
            id: me.id,
            email: me.email,
            password: isAdmin ? "******" : data.password,
            emailPassword: viewingSelf ? false : data.notifyUser,
            ...data,
          },
        });
        toast.success("Password Successfully Updated");
      } catch (error) {
        console.log(error);
      }
    } else {
      passwordFormErrors.showMessages();
    }
  };

  return (
    <Fragment>
      <form
        className={`card needs-validation tooltip-validation ${
          passwordFormValidateClass ? "validateClass" : ""
        }`}
        noValidate=""
        onSubmit={passwordFormSubmit(onSubmitPasswordForm)}
      >
        {passwordUpdateLoading && (
          <div className="loader-box loader-form-wrapper">
            <span className="rotate dotted" />
          </div>
        )}
        <fieldset
          aria-busy={passwordUpdateLoading}
          disabled={passwordUpdateLoading}
        >
          <div className="row mb-2">
            <div className="col-auto">
              <img alt="" className="img-70 rounded-circle" src={seven} />
            </div>
            <div className="col">
              <h3 className="mb-1" style={{ textTransform: "capitalize" }}>
                {me.name}
              </h3>
              <p className="mb-4">
                <>
                  {me.status === "ARCHIVE" ? (
                    <span className="badge badge-pill badge-danger">
                      Deleted
                    </span>
                  ) : (
                    <span
                      className={`badge badge-pill ${
                        me.active === true ? "badge-success" : "badge-dark"
                      }`}
                    >
                      {me.active === true ? "Active" : "Pending"}
                    </span>
                  )}
                  {me.permissions &&
                    me.permissions.map((permission, index) => (
                      <span
                        key={index}
                        className="badge badge-pill badge-info"
                        style={{
                          textTransform: "capitalize",
                        }}
                        // eslint-disable-next-line react/jsx-closing-bracket-location
                      >
                        {permission.toLowerCase()}
                      </span>
                    ))}
                </>
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">Email-Address</label>
            <input
              className="form-control"
              disabled
              name="email"
              value={me.email}
            />
          </div>
          {!isAdmin && (
            <div className="form-group">
              <label className="form-label">Current Password</label>
              <input
                className="form-control"
                name="password"
                placeholder="******"
                ref={passwordFormRegister({
                  required: true,
                })}
                type="password"
              />

              {passwordFormErrors.password && <span>Field is required</span>}
            </div>
          )}
          <div className="form-group">
            <label className="form-label">New Password</label>
            <input
              className="form-control"
              name="newPassword"
              placeholder="******"
              ref={passwordFormRegister({
                required: true,
              })}
              type="password"
            />
            <button
              className="btn btn-light pull-right mt-2 mb-2"
              onClick={() => {
                const pass = customPassword();
                setValue("newPassword", pass);
                setValue("confirmPassword", pass);
                setGenPassword(pass);
              }}
              type="button"
            >
              Generate Password
            </button>
            {genPassword && genPassword}
            {passwordFormErrors.newPassword && <span>Field is required</span>}
          </div>
          <div className="form-group">
            <label className="form-label">Confirm New Password</label>
            <input
              className="form-control"
              name="confirmPassword"
              placeholder="******"
              ref={passwordFormRegister({
                required: true,
                validate: (value) =>
                  value === watch("newPassword") || "Passwords don't match.",
              })}
              type="password"
            />
            {passwordFormErrors.confirmPassword && (
              <span>
                {passwordFormErrors.confirmPassword.message !== ""
                  ? passwordFormErrors.confirmPassword.message
                  : "Field is required"}
              </span>
            )}
          </div>
          {isAdmin && !viewingSelf && (
            <div className="checkbox mb-4">
              <input
                defaultChecked={
                  process.env.NODE_ENV === "development" ? false : true
                }
                id="notifyUser"
                name="notifyUser"
                ref={passwordFormRegister}
                type="checkbox"
              />
              <label className="mb-0" htmlFor="notifyUser">
                Email user the new password
              </label>
            </div>
          )}
          <div className="form-footer">
            <button
              className="btn btn-primary btn-block"
              onClick={() =>
                passwordFormSetValidateClass(!passwordFormValidateClass)
              }
              type="submit"
            >
              Save
            </button>
          </div>
        </fieldset>
      </form>
    </Fragment>
  );
};

export default UserPasswordForm;
