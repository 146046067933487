import React from 'react';

const Loading = () => (
  <div
    className="loader-box loader-form-wrapper-static"
    style={{ position: 'inherit' }}>
    <span className="rotate dotted" />
  </div>
);

export default Loading;
