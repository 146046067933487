import React from "react";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import

import {
  CARD_UPDATE_STATUS_MUTATION,
  USER_CARDS_QUERY,
  CARD_REQUEST_BYID_QUERY,
} from "../graphql";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const UpdateCreditCardStatus = ({ card, status, title, component: button }) => {
  const [updateStatus, { loading }] = useMutation(CARD_UPDATE_STATUS_MUTATION);
  const handleUpdateStatus = async (status) => {
    try {
      await updateStatus({
        variables: {
          id: card.cardApplication.id,
          status: status,
        },
        refetchQueries: [
          {
            query: USER_CARDS_QUERY,
          },
          {
            query: CARD_REQUEST_BYID_QUERY,
            variables: {
              id: card.cardApplication.id,
            },
          },
        ],
      });
      toast.success("Successfully Updated Application Status");
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleUpdateStatus(status),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  return <button onClick={submit}>{title}</button>;
};

export default UpdateCreditCardStatus;
