import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import moment from "moment";
import {
  FormFancyCheckbox,
  FormBorrowPurposeSelect,
  ReactDatePicker,
  RangeSlider,
  FormEmploymentIncome,
} from "../../../components";

import { setReactSelect, saveReactSelect } from "../../../util";
import { termOptions } from "../../../constant/constants";

const Terms = ({
  wizardData,
  setWizardData,
  navigation: { next, previous },
}) => {
  wizardData = setReactSelect(["purposeOfBorrow"], wizardData);

  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    defaultValues: {
      paymentCycleDuration: "3 Months",
      employmentAndIncome: [{ type: "placeholder", desc: "", name: "" }],
      ...wizardData,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employmentAndIncome",
  });

  const onSubmit = (data) => {
    saveReactSelect(["purposeOfBorrow"], wizardData, data);
    setWizardData({ ...wizardData, ...data });
    next();
  };

  const handleLoanAmountChange = (e) => {
    let loanAmount = e.target.value;
    if (!isNaN(e.target.value)) {
      parseInt(loanAmount);
      if (loanAmount > 2000) {
        loanAmount = 2000;
      }
    }
    setValue("loanAmount", loanAmount);
  };

  const watchLoanAmount = watch("loanAmount", 1000);
  const employmentValidation = watch("employmentAndIncome").every(function (
    val
  ) {
    return val.type !== "" && val.desc !== "";
  });

  return (
    <div className="card">
      <form
        className={"needs-validation tooltip-validation"}
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card-header">
          <h5>Step 3 - Select Your Term</h5>
        </div>
        <div className="card-body">
          <h6>
            <strong>Borrow Amount</strong>
          </h6>
          <div className="form-group row">
            <div className="col-md-5">
              <div className="row align-items-center">
                <label
                  className="col-md-auto col-form-label sm-left-text"
                  htmlFor="u-range-01"
                >
                  Amount
                </label>
                <div className="col-md-9">
                  <RangeSlider
                    control={control}
                    defaultValue={1000}
                    name="loanAmount"
                    input="applyForm"
                    max={2000}
                    min={50}
                    step={50}
                    rules={{
                      required: {
                        value: true,
                        message: "Loan amount required",
                      },
                      min: {
                        value: 50,
                        message: "Loan amount must be greater than $50",
                      },
                      max: {
                        value: 2000,
                        message: "Loan amount must be less than $2000",
                      },
                      pattern: {
                        value: /^[-+]?\d+$/,
                        message: "Please enter a number without decimal point.",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7 d-flex">
              <div className="d-flex flex-row align-items-center">
                <label
                  className="col-md-auto col-form-label sm-left-text"
                  htmlFor="u-range-01"
                >
                  Or enter custom amount $
                </label>
                <div className="col-md-11">
                  <input
                    className="form-control"
                    type="number"
                    name="loanAmount"
                    value={watchLoanAmount}
                    onChange={handleLoanAmountChange}
                  />
                  {errors.loanAmount && (
                    <span> {errors.loanAmount.message}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <h6>
            <strong>Borrow Term</strong>
          </h6>
          <div className="row">
            <FormFancyCheckbox
              options={termOptions}
              loanAmount={watchLoanAmount}
              setValue={setValue}
              watch={watch}
              input="paymentCycleDuration"
              defaultValue={watch("paymentCycleDuration")}
            />
          </div>

          <input type="hidden" ref={register} name="paymentCycleDuration" />

          <h6>
            <strong>Purpose Of Borrow</strong>
          </h6>
          <div className="form-group row">
            <FormBorrowPurposeSelect
              errors={errors}
              control={control}
              rules={{ required: true }}
            />
          </div>
          <h6>
            <strong>Payments Term</strong>
          </h6>
          <div className="form-group row">
            <label
              className="col-md-auto col-form-label"
              htmlFor="inputPassword3"
            >
              When should payback payments begin?
            </label>
            <div className="col-sm-9">
              <ReactDatePicker
                control={control}
                maxDate={moment().add("days", 30).toDate()}
                name="paymentCycleStartDate"
                placeholderText={
                  "Select a date between today and 30 days in the future"
                }
                rules={{ required: true }}
              />

              {errors.paymentCycleStartDate && (
                <span> Start date is required</span>
              )}
            </div>
          </div>
          <fieldset className="form-group">
            <div className="row">
              <label className="col-form-label col-sm-2 pt-0">
                When do you want to pay?
              </label>
              <div className="col-sm-9">
                <div className="radio radio-primary ml-2">
                  <input
                    ref={register}
                    id="biweekly"
                    name="paymentCycleFrequency"
                    type="radio"
                    value="biweekly"
                    defaultChecked
                  />
                  <label htmlFor="biweekly">Bi-Weekly</label>
                </div>
                <div className="radio radio-primary ml-2">
                  <input
                    ref={register}
                    id="monthly"
                    name="paymentCycleFrequency"
                    type="radio"
                    value="monthly"
                  />
                  <label htmlFor="monthly">Monthly</label>
                </div>
              </div>
            </div>
          </fieldset>
          <h6>
            <strong>Employment &amp; Income</strong>
          </h6>
          <div>
            <FormEmploymentIncome
              remove={remove}
              register={register}
              watch={watch}
              fields={fields}
              onAdd={() => append()}
              showAddBtn={employmentValidation}
            />

            {errors.employmentAndIncome && (
              <span style={{ color: "red" }}>
                Employment information is required
              </span>
            )}
          </div>
        </div>

        <div className="card-footer mb-5">
          <button
            className="btn btn-prev btn-default btn-lg pull-left"
            onClick={previous}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-prev btn-success btn-lg pull-right"
            onClick={() => handleSubmit(onSubmit)}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Terms;
