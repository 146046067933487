/* eslint-disable no-undef */
/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../components/common/breadcrumb';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import {
  isMobile,
} from 'react-device-detect';

import 'react-toastify/dist/ReactToastify.css';
import { USER_UPDATE_PROFILE_DOCS, USER_PROFILE_QUERY } from '../graphql';

var images = require.context('../assets/images', true);


const Documents = ({ me }) => {
  const [updateDocs, {loading, error}] = useMutation(USER_UPDATE_PROFILE_DOCS);

  const [documents, setDocument] = useState({
    bankStatement: null,
    proofOfResidence: null,
    govIssuedId: null,
    directDepositForm: null,
    ...me.documents,
    uploadBankStatement: me.documents && me.documents.bankStatement ? false : true,
    uploadProofOfResidence: me.documents && me.documents.proofOfResidence ? false : true,
    uploadGovIssuedId: me.documents && me.documents.govIssuedId ? false : true,
    uploadDirectDepositForm : me.documents && me.documents.directDepositForm ? false : true,
  });
  useEffect(() => {
    if (error && error.message !== '') {
      toast.error(error.message.replace('GraphQL error: ', ''));
    }
  }, [error]);

  const handleUpdateDocuments = () => {
    const query = updateDocs({
      variables: {
        bankStatement: documents.uploadBankStatement && documents.bankStatement !== null ? documents.bankStatement : null,
        proofOfResidence: documents.uploadProofOfResidence && documents.proofOfResidence !== null ? documents.proofOfResidence : null,
        govIssuedId: documents.uploadGovIssuedId && documents.govIssuedId !== null ? documents.govIssuedId : null,
        directDepositForm: documents.uploadDirectDepositForm && documents.directDepositForm !== null ? documents.directDepositForm : null,
      },
      refetchQueries: [
        {
          query: USER_PROFILE_QUERY
        }
      ]
    })
    if(query){
      toast.success(
        'Successfully Updated Your Documents!',
        {
          onClose: function() {
            // props.history.push('/completesignup');
          },
        }
      );
    }
  }
  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  return (
    <Fragment>
      <Breadcrumb
        parent="Advance"
        title="Documents"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div
              className="card cuanimated bounceInLeft"
              id = "animated-example"
            >
              <div className="card-header b-l-primary">
                <h5>View / Upload</h5>
              </div>
              <div className="card-body">
                {loading && (
                  <div className="loader-box loader-form-wrapper">
                    <span className="rotate dotted" />
                  </div>
                )}

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'auto',
                    columnGap: '1em',
                    rowGap: '1em',
                  }}
                >
                   <form
                     className="dropzone digits"
                     id="singleFileUpload"
                   >
                    <div
                      className="dz-message needsclick"
                      style={{
                        marginLeft: '2px',
                        marginRight: '2px',
                      }}
                    >
                      <Dropzone
                        accept="image/*, application/pdf"
                        canCancel={false}
                        getFilesFromEvent={getFilesFromEvent}
                        id="govIssuedId"
                        InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                          return (
                              <>
                              {
                                documents.govIssuedId ? (

                                  <>
                                  {
                                  documents.govIssuedId.map((file, index) => 
                                  <div
                                    style={{
                                    width: '50%',
                                    margin: '5%',
                                    padding: '10px',
                                    background: 'rgb(237, 242, 246)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                    border: '1px solid rgb(208, 219, 228)',
                                    position: 'relative',
                                    height: 'auto'
                                  }}
                                  >
                                    <div
                                      onClick={() => setDocument(docs => ({
                                                      ...docs,
                                                      govIssuedId: null,
                                                      uploadGovIssuedId: true,
                                                    }))}
                                      style={{
                                      position: 'absolute',
                                      top: '34px',
                                      right: '-9px',
                                      color: 'rgb(255, 255, 255)',
                                      background: 'rgb(255, 64, 129)',
                                      borderRadius: '50%',
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      fontSize: '26px',
                                      fontWeight: '700',
                                      lineHeight: '30px',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    >X</div>
                                  
                                  {
                                    (typeof file === 'object' && file.type === 'application/pdf') || (typeof file === 'string' && file.split('.').pop() === 'pdf') ?  <div style={{textAlign: 'center', width:'100%'}}> <img
                                      alt="gov issued id"
                                      className="img-40 rounded-circle"
                                      src={images('./pdf.png')}
                                      style={{
                                    height: '40px',
                                    width: '40px'
                                  }}
                                                                                                                                                                                                                        /><br /> <br />PDF File: {typeof file === 'object' && file.type === 'application/pdf' ? file.name : file}</div> 
                                                                                                                        
                                      : (<img
                                        alt="preview"
                                        className="animated fadeIn"
                                        src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.govIssuedId[index]}`}
                                        style={{width: '100%'}}
                                         />)
                                  }
                                </div>)

                        }
                                  </>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                              Select Government Issued Id
                                  <input
                                    accept={accept}
                                    multiple
                                    onChange={e => {
                                      getFilesFromEvent(e).then(chosenFiles => {
                                        setDocument(state => ({
                                          ...state,
                                          govIssuedId: chosenFiles,
                                        }));
                                        onFiles(chosenFiles)
                                      })
                                    }}
                                    style={{ display: 'none' }}
                                    type="file"
                                  />
                                </label>)
                              }
                             
                            </>
                          )
                        }
                        }
                        inputContent="Government Issued Id"
                        maxFiles={1}
                        multiple
                        PreviewComponent={(props) => <Preview
                          isMultiple={documents.govIssuedId.length > 1}
                          onClick={() => setDocument(docs => ({
                            ...docs,
                            govIssuedId: null,
                            uploadGovIssuedId: true,
                          }))}
                          {...props}
                                                     />}
                        styles={{
                          dropzone: {
                            width: '100%',
                            height: isMobile ? 150 : 350,
                          },
                          dropzoneActive: {
                            borderColor: 'green',
                          },
                        }}
                      />
                    </div>
                  </form>
                  <form
                    className="dropzone digits"
                    id="singleFileUpload"
                  >
                    <div
                      className="dz-message needsclick"
                      style={{
                        marginLeft: '2px',
                        marginRight: '2px',
                      }}
                    >
                      <Dropzone
                        accept="image/*, application/pdf"
                        canCancel={false}
                        getFilesFromEvent={getFilesFromEvent}
                        id="proofOfResidence"
                        InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                          return (
                              <>
                              {
                                documents.proofOfResidence ? (

                                  <>
                                  {
                                  documents.proofOfResidence.map((file, index) => 
                                  <div
                                    style={{
                                    width: '50%',
                                    margin: '5%',
                                    padding: '10px',
                                    background: 'rgb(237, 242, 246)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                    border: '1px solid rgb(208, 219, 228)',
                                    position: 'relative',
                                    height: 'auto'
                                  }}
                                  >
                                    <div
                                      onClick={() => setDocument(docs => ({
                                                      ...docs,
                                                      proofOfResidence: null,
                                                      uploadProofOfResidence: true,
                                                    }))}
                                      style={{
                                      position: 'absolute',
                                      top: '34px',
                                      right: '-9px',
                                      color: 'rgb(255, 255, 255)',
                                      background: 'rgb(255, 64, 129)',
                                      borderRadius: '50%',
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      fontSize: '26px',
                                      fontWeight: '700',
                                      lineHeight: '30px',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    >X</div>
                                  {
                                    (typeof file === 'object' && file.type === 'application/pdf') || (typeof file === 'string' && file.split('.').pop() === 'pdf') ?  <div style={{textAlign: 'center', width:'100%'}}> <img
                                      alt="proof of residence"
                                      className="img-40 rounded-circle"
                                      src={images('./pdf.png')}
                                      style={{
                                    height: '40px',
                                    width: '40px'
                                  }}
                                                                                                                                                                                                                        /><br /> <br />PDF File: {typeof file === 'object' && file.type === 'application/pdf' ? file.name : file}</div> 
                                                                                                                         : (<img
                                                                                                                           alt="preview"
                                                                                                                           className="animated fadeIn"
                                                                                                                           src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.proofOfResidence[index]}`}
                                                                                                                           style={{width: '100%'}}
                                                                                                                            />)
                                  }
                                 
                                </div>)

                        }
                                  </>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                              Select Proof Of Residence
                                  <input
                                    accept={accept}
                                    multiple
                                    onChange={e => {
                                      getFilesFromEvent(e).then(chosenFiles => {
                                        setDocument(state => ({
                                          ...state,
                                          proofOfResidence: chosenFiles,
                                        }));
                                        onFiles(chosenFiles)
                                      })
                                    }}
                                    style={{ display: 'none' }}
                                    type="file"
                                  />
                                </label>)
                              }
                             
                            </>
                          )
                        }
                        }
                        inputContent="Proof Of Residence"
                        maxFiles={1}
                        multiple
                        PreviewComponent={(props) => <Preview
                          isMultiple={documents.proofOfResidence.length > 1}
                          onClick={() => setDocument(docs => ({
                            ...docs,
                            proofOfResidence: null,
                            uploadProofOfResidence: true,
                          }))}
                          {...props}
                                                     />}
                        styles={{
                          dropzone: {
                            width: '100%',
                            height: isMobile ? 150 : 350,
                          },
                          dropzoneActive: {
                            borderColor: 'green',
                          },
                        }}
                      />
                    </div>
                  </form>
                  <form
                    className="dropzone digits"
                    id="singleFileUpload"
                  >
                    <div
                      className="dz-message needsclick"
                      style={{
                        marginLeft: '2px',
                        marginRight: '2px',
                      }}
                    >
                      <Dropzone
                        accept="image/*, application/pdf"
                        canCancel={false}
                        getFilesFromEvent={getFilesFromEvent}
                        id="directDepositForm"
                        InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                          return (
                            <>
                              {
                                documents.directDepositForm ? (

                                  <>
                                  {
                                  documents.directDepositForm.map((file, index) => 
                                  <div
                                    style={{
                                    width: '50%',
                                    margin: '5%',
                                    padding: '10px',
                                    background: 'rgb(237, 242, 246)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                    border: '1px solid rgb(208, 219, 228)',
                                    position: 'relative',
                                    height: 'auto'
                                  }}
                                  >
                                    <div
                                      onClick={() => setDocument(docs => ({
                                                      ...docs,
                                                      directDepositForm: null,
                                                      uploadDirectDepositForm: true
                                                    }))}
                                      style={{
                                      position: 'absolute',
                                      top: '34px',
                                      right: '-9px',
                                      color: 'rgb(255, 255, 255)',
                                      background: 'rgb(255, 64, 129)',
                                      borderRadius: '50%',
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      fontSize: '26px',
                                      fontWeight: '700',
                                      lineHeight: '30px',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    >X</div>
                           
                           {
                                    (typeof file === 'object' && file.type === 'application/pdf') || (typeof file === 'string' && file.split('.').pop() === 'pdf') ?  <div style={{textAlign: 'center', width:'100%'}}> <img
                                      alt="direct deposit form"
                                      className="img-40 rounded-circle"
                                      src={images('./pdf.png')}
                                      style={{
                                    height: '40px',
                                    width: '40px'
                                  }}
                                                                                                                                                                                                                        /><br /> <br />PDF File: {typeof file === 'object' && file.type === 'application/pdf' ? file.name : file}</div> 
                                                                                                                         : (<img
                                                                                                                           alt="preview"
                                                                                                                           className="animated fadeIn"
                                                                                                                           src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.directDepositForm[index]}`}
                                                                                                                           style={{width: '100%'}}
                                                                                                                            />)
                                  }
                                </div>)

                        }
                                  </>
                                ) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                             Direct Deposit Form
                                  <input
                                  
                                    accept={accept}
                                    multiple
                                    onChange={e => {
                                      getFilesFromEvent(e).then(chosenFiles => {
                                        setDocument(state => ({
                                          ...state,
                                          directDepositForm: chosenFiles,
                                        }));
                                        onFiles(chosenFiles)
                                      })
                                    }}
                                    style={{ display: 'none' }}
                                    type="file"
                                  />
                                </label>)
                              }
                             
                            </>
                          )
                        }
                        }
                        inputContent="Direct Deposit Form"
                        maxFiles={1}
                        multiple
                        PreviewComponent={(props) => <Preview
                          isMultiple={documents.directDepositForm.length > 1}
                          onClick={() => setDocument(docs => ({
                            ...docs,
                            directDepositForm: null,
                            uploadDirectDepositForm: true
                          }))}
                          {...props}
                                                     />}
                        styles={{
                          dropzone: {
                            width: '100%',
                            height: isMobile ? 150 : 350,
                          },
                          dropzoneActive: {
                            borderColor: 'green',
                          },
                        }}
                      />
                    </div>
                  </form>
                  <form
                    className="dropzone digits"
                    id="singleFileUpload"
                  >
                    <div
                      className="dz-message needsclick"
                      style={{
                        marginLeft: '2px',
                        marginRight: '2px',
                      }}
                    >
                      <Dropzone
                        accept="image/*, application/pdf"
                        canCancel={false}
                        getFilesFromEvent={getFilesFromEvent}
                        id="bankStatement"
                        InputComponent={({ accept, onFiles, getFilesFromEvent }) => {                        
                          return (
                            <>
                              {
                                documents.bankStatement ? (

                                  <>
                                  {
                                  documents.bankStatement.map((file, index) => 
                                  <div
                                    style={{
                                    width: '50%',
                                    margin: '5%',
                                    padding: '10px',
                                    background: 'rgb(237, 242, 246)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px 2px',
                                    border: '1px solid rgb(208, 219, 228)',
                                    position: 'relative',
                                    height: 'auto'
                                  }}
                                  >
                                    <div
                                      onClick={() => setDocument(docs => ({
                                                      ...docs,
                                                      bankStatement: null,
                                                      uploadBankStatement: true
                                                    }))}
                                      style={{
                                      position: 'absolute',
                                      top: '34px',
                                      right: '-9px',
                                      color: 'rgb(255, 255, 255)',
                                      background: 'rgb(255, 64, 129)',
                                      borderRadius: '50%',
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      fontSize: '26px',
                                      fontWeight: '700',
                                      lineHeight: '30px',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    >X</div>
                           
                           {
                                    (typeof file === 'object' && file.type === 'application/pdf') || (typeof file === 'string' && file.split('.').pop() === 'pdf') ?  <div style={{textAlign: 'center', width:'100%'}}> <img
                                      alt="bank statement"
                                      className="img-40 rounded-circle"
                                      src={images('./pdf.png')}
                                      style={{
                                    height: '40px',
                                    width: '40px'
                                  }}
                                                                                                                                                                                                                        /><br /> <br />PDF File: {typeof file === 'object' && file.type === 'application/pdf' ? file.name : file}</div> 
                                                                                                                         : (<img
                                                                                                                           alt="preview"
                                                                                                                           className="animated fadeIn"
                                                                                                                           src={`${process.env.REACT_APP_BASE_URL_PROD}/${documents.bankStatement[index]}`}
                                                                                                                           style={{width: '100%'}}
                                                                                                                            />)
                                  }
                                </div>)

                        }
                                  </>) : ( <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
                             Bank Statement
                                  <input
                                    accept={accept}
                                    multiple
                                    onChange={e => {
                                      getFilesFromEvent(e).then(chosenFiles => {
                                        setDocument(state => ({
                                          ...state,
                                          bankStatement: chosenFiles,
                                        }));
                                        onFiles(chosenFiles)
                                      })
                                    }}
                                    style={{ display: 'none' }}
                                    type="file"
                                  />
                                </label>)
                              }
                             
                            </>
                          )
                        }
                        }
                        inputContent="Bank Statement"
                        maxFiles={1}
                        multiple
                        PreviewComponent={(props) => <Preview
                          isMultiple={documents.bankStatement.length > 1}
                          onClick={() => setDocument(docs => ({
                            ...docs,
                            bankStatement: null,
                            uploadBankStatement: true
                          }))}
                          {...props}
                                                     />}
                        styles={{
                          dropzone: {
                            width: '100%',
                            height: isMobile ? 150 : 350,
                          },
                          dropzoneActive: {
                            borderColor: 'green',
                          },
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="card-footer text-right">
                <button
                  className="btn btn-primary"
                  disabled={!documents.bankStatement || !documents.proofOfResidence || !documents.govIssuedId || !documents.directDepositForm}
                  onClick={handleUpdateDocuments}
                  type="submit"
                >
                  Update Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Preview = (props) => {
  const { previewUrl } = props.meta;
  const { remove } = props.fileWithMeta
  return (
    <div
      className="customFade-in"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '50%',
            margin: '5%',
            padding: '10px',
            background: '#edf2f6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'inherit',
            boxShadow: props.isMultiple ? 
            'rgba(0, 0, 0, 0.1) 0px 2px 8px 2px, rgb(218, 224, 228)-5px -5px 0px 0px, rgb(200, 200, 228) -10px -10px 0px 0px' : '0 0 8px 2px rgba(0,0,0,.1)',
            border: '1px solid #d0dbe4',
            position: 'relative',
          }}
        >
          <div
            onClick={() =>{ props.onClick(); remove() }}
            style={{
              position: 'absolute',
              top: '34px',
              right: '-9px',
              color: '#fff',
              background: '#ff4081',
              borderRadius: '50%',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '26px',
              fontWeight: '700',
              lineHeight: '30px',
              width: '30px',
              height: '30px',
            }}
          >
            X
          </div>
          {
            props.meta.type === 'application/pdf' ? 
            <div style={{textAlign: 'center', width:'100%'}}> <img
              alt="bank statement"
              className="img-40 rounded-circle"
              src={images('./pdf.png')}
              style={{
              height: '40px',
              width: '40px'
            }}
                                                              /><br /> <br />PDF File: {props.meta.name}</div>
            :
          
          <img
            alt="preview"
            className="animated fadeIn"
            src={previewUrl}
            style={{
              width: '100%',
            }}
          />
}
        </div>
      </div>
    </div>
  );
};

export default Documents;

/**
 */