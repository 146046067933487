import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const FormDobSelect = ({ control, rules }) => {
  const d = new Date();
  const maxYears = d.getFullYear() - 1940;
  const maxMonths = 12;
  const maxDays = 31;

  const optionsYear = Array.from(
    new Array(maxYears),
    (val, index) => index + 1940
  ).map(
    (val) => ({
      value: val,
      label: val,
    }),
    []
  );

  const optionsMonth = Array.from(
    new Array(maxMonths),
    (val, index) => index + 1
  ).map(
    (val) => ({
      value: val,
      label: val,
    }),
    []
  );

  const optionsDay = Array.from(
    new Array(maxDays),
    (val, index) => index + 1
  ).map(
    (val) => ({
      value: val,
      label: val,
    }),
    []
  );

  return (
    <>
      <Controller
        className="col-md-4 mb-2"
        as={Select}
        control={control}
        name="dobDay"
        options={optionsDay}
        placeholder="Day"
        rules={rules}
      />
      <Controller
        as={Select}
        className="col-md-4 mb-2"
        control={control}
        name="dobMonth"
        options={optionsMonth}
        placeholder="Month"
        rules={rules}
      />
      <Controller
        as={Select}
        className="col-md-4 mb-2"
        control={control}
        name="dobYear"
        options={optionsYear}
        placeholder="Year"
        rules={rules}
      />
    </>
  );
};

export default FormDobSelect;
