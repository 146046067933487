/* eslint-disable no-undef */
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { useCSVDownloader } from 'react-papaparse';
import moment from "moment";
import currency from "currency.js";
import { useQuery } from "@apollo/client";
import { Breadcrumb } from "../../components";
import { LOAN_TAPES } from "../../graphql";
import Datatable from "../../components/datatable";
import CountUp from 'react-countup';

import Papa from "papaparse"

var images = require.context("../../assets/images", true);

const Tape = ({ match }) => {

  const { loading, data, refetch } = useQuery(LOAN_TAPES, {
    fetchPolicy: "network-only"
  })

  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <Fragment>
    <Breadcrumb parent="Dashboard" title="Loan Tape" />
    <div className="container-fluid">
    {!loading && data.tapes.length > 0 &&
    <div className="row">
                <div
                  className="col-md-12 ecommerce-slider"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: '1em',
                    gridTemplateRows: 'auto',
                  }}>
                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example">
                      <div className="card-body ecommerce-icons text-center">
                        <div>
                          <span>Total Funds Issued</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          <CountUp
                          prefix="$"
                          formattingFn={(number) => currency(number).format("$")}
                            className="counter"
                            end={data.tapes[0].totalBorrowed === null ? 0 : data.tapes[0].totalBorrowed }
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example">
                      <div className="card-body ecommerce-icons text-center">
                        <div>
                          <span>Total Payback</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          <CountUp
                          prefix="$"
                          formattingFn={(number) => currency(number).format("$")}
                            className="counter"
                            end={data.tapes[0].totalPayback === null ? 0 : data.tapes[0].totalPayback }
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="card cuanimated bounceInLeft"
                      id="animated-example">
                      <div className="card-body ecommerce-icons text-center">
                        <div>
                          <span>Total Admin Fee</span>
                        </div>
                        <h4 className="font-primary mb-0">
                          <CountUp
                          prefix="$"
                          formattingFn={(number) => currency(number).format("$")}
                            className="counter"
                            end={data.tapes[0].adminFee === null ? 0 : data.tapes[0].adminFee }
                            start={0}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>

              </div>
          
              </div>

              }

      <div className="card cuanimated bounceInLeft" id="animated-example">
        <div className="card-header b-l-primary">
          <h5>

          {!loading && data.tapes.length > 0 && `Tape Date ${moment(data.tapes[0].createdAt).format("LLL")}` }

          </h5>
        </div>
        <div className="card-body">
         
          {loading ? (
            <div
              className="loader-box loader-form-wrapper-static"
              style={{ position: "inherit" }}
            >
              <span className="rotate dotted" />
            </div>
          ) : data.tapes.length > 0 ? (
            <div>
              <div style={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "flex-end"
              }}>
                <CSVDownloader
                  type={Type.Button}
                  filename={'tape-' + data.tapes[0].id}
                  bom={true}
                  config={{
                    delimiter: ';',
                  }}
                  data={Papa.parse(data.tapes[0].items)}
                >
                  Download Tape
                </CSVDownloader>
              </div>
               <Datatable
               pageSize={100}
              class="-striped -highlight"
              hideAction
              hideSearch
              myData={Papa.parse(data.tapes[0].items).data.map((app) => {
                let row = {
                  "LOAN ID": app[0],
                   "DISBURSEMENT DATE": app[1],
                   "TERM": app[2],
                   " AMOUNT BORROWED  ": app[3],
                   " APR (ADMIN FEE) ": app[4],
                   "TOTAL PAYBACK": app[5],
                   "PAYMENT STATUS": app[6],
                   " BI WEEKLY PAYMENT ": app[7],
                   " MONTHLY PAYMENT ": app[8],
                   "REMAINING TERM": app[9],
                   "LAST PAYMENT MADE": app[10],
                   "RELOAN": app[11],
                   "PROVINCE ": app[12],
                }

                // row["ID"] = app.id;
                return row;
              })}
              pageSize={15}
              pagination
              routeUrl="card"
            />
            </div>
          ) : (
            <div
              className="search-not-found text-center ng-star-inserted"
              style={{ height: "300px", marginTop: 0 }}
            >
              <div className="">
                <img
                  alt=""
                  className="second-search"
                  src={images("./search-not-found.png")}
                  style={{ marginTop: "-40px" }}
                />
                <p className="mb-0">No Records Found</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </Fragment>
  );
};

export default withRouter(Tape);
