import React from "react";

import {
  UpdateCreditCardStatus,
  UpdateCreditCardDiscount,
  AddCardPayment,
  IssueCard,
  ActivateCard,
  CancelCard,
  EditCardInformation,
  EditCardLimit
} from "../../../components";

export default function ActionMenu({ data }) {

  return (
    <>
      { data.cardApplication.cardId === null && data.cardApplication.status === "APPROVED" &&
        <IssueCard card={data}  title="Issue Card" />
    }

       {
         data.cardApplication.cardId !== null && data.cardApplication.status === "CARD_ISSUED" && 
         <CancelCard card={data}  title="Cancel Card" />
        }
       {
         data.cardApplication.cardId !== null && data.cardApplication.cardStatus === "InActive" && 
          <ActivateCard card={data}  title="Issue Card" />
       }

      {data.cardApplication.status === "INREVIEW" && (
        <UpdateCreditCardStatus card={data} status="APPROVED" title="Approve Application" />
      )}

      {data.cardApplication.status === "APPROVED" && (
       <>
       {(data.cardApplication.discountType === "1" || data.cardApplication.discountType === "2") &&  <UpdateCreditCardDiscount card={data} discountType="0" title="Remove Discount" />}
       {data.cardApplication.discountType !== "1" &&  <UpdateCreditCardDiscount card={data} discountType="1" title="Add 20% Discount" />}
       {data.cardApplication.discountType !== "2" &&  <UpdateCreditCardDiscount card={data} discountType="2" title="Add 50% Discount" />}
       </>
      )}

      {data.cardApplication.status === "CARD_ISSUED" && data.cardApplication.status !== "ARCHIVE" && (
        <AddCardPayment card={data} />
      )}
      {data.cardApplication.status !== "APPROVED" &&  data.cardApplication.status !== "CARD_ISSUED" && data.cardApplication.status !== "INREVIEW" &&  (
        <>
        <UpdateCreditCardStatus card={data} status="INREVIEW" title="Application In Review" />

        </>
      )}

      {data.cardApplication.status !== "ARCHIVE" &&  data.cardApplication.status !== "DENIED" &&  data.cardApplication.status !== "CARD_ISSUED"  && data.cardApplication.status !== "APPROVED" && (
          <UpdateCreditCardStatus card={data} status="DENIED" title="Deny Application" />
        )}

              <EditCardLimit card={data.cardApplication} />

        <EditCardInformation card={data.cardApplication} />
    </>
  );
}
