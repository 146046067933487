import React from 'react';
var images = require.context('../assets/images', true);

const Empty = ({ message }) => (
  <div
    className="search-not-found text-center ng-star-inserted"
    style={{ height: '300px', marginTop: 0 }}>
    <div className="">
      <img
        alt=""
        className="second-search"
        src={images('./search-not-found.png')}
        style={{ marginTop: '-50px' }}
      />
      <p className="mb-0">{message}</p>
    </div>
  </div>
);

export default Empty;
