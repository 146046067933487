import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { USER_NOTIFICATIONS_QUERY } from '../../graphql'

// Custom Import
import Email from '../../components/email';

const Inbox = ({match}) => {
  const { data, loading } = useQuery(
    USER_NOTIFICATIONS_QUERY,
    {
      fetchPolicy: 'no-cache'
    }
  );
  return (
    <Fragment>
      {
        !loading && ( <Email
          data={data.userNotifications}
          id={match.params && match.params.id ? match.params.id : null}
                      />)
      }
    </Fragment>
  );
};

export default Inbox;