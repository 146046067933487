import React from "react";
import moment from "moment";
import { ShoppingBag } from "react-feather";

const Comment = ({ index, comment }) => (
    <div
      className="media"
      key={index}
    >
      <div className="gradient-round m-r-30 gradient-line-1">
        <ShoppingBag />
      </div>
      <div className="media-body">
        <h6>
          {comment.title}
          <span className="pull-right f-14">
            {moment(comment.createdAt).fromNow()} by{' '}
            <b>{comment.user.name}</b>
          </span>
        </h6>
        <p>{comment.message}</p>
      </div>
    </div>
  );

export default Comment;