import React from 'react';
import moment from 'moment';

import Empty from './Empty';
import UpcomingPaymentItem from './UpcomingPaymentItem';

const UpcomingPayments = ({ payments, paymentCycleAmount }) => {
  return (
    <div className="card">
      <div className="card-header b-l-primary">
        <h5>Upcoming Payments</h5>
      </div>
      <div className="card-body">
        {payments.length > 0 ? (
          <div className="table-responsive active-order-table">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col" style={{ paddingLeft: '0' }}>
                    Amount Due (CAD)
                  </th>
                  <th scope="col">Days Until Payment</th>
                </tr>
              </thead>
              <tbody>
                {payments
                  .filter(
                    (payment) => moment(new Date()).diff(payment) < 0
                  )
                  .map((payment, index) => (
                    <UpcomingPaymentItem
                      amount={paymentCycleAmount}
                      date={payment.date}
                      key={index}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Empty message="No Upcoming Payments Yet..." />
        )}
      </div>
    </div>
  );
};

export default UpcomingPayments;
