import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
} from 'react';
import { MENUITEMS } from '../../../constant/menu';
import { Link } from 'react-router-dom';
import { Search } from 'react-feather';

const SearchHeader = () => {
  // eslint-disable-next-line no-unused-vars
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  // eslint-disable-next-line no-unused-vars
  const [searchValue, setsearchValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [
    searchResultEmpty,
    setSearchResultEmpty,
  ] = useState(false);
  const [searchOpen, setsearchOpen] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      setsearchOpen(false);
      setsearchValue('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener(
      'keydown',
      escFunction,
      false
    );
    return () => {
      document.removeEventListener(
        'keydown',
        escFunction,
        false
      );
    };
  }, []);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    setsearchValue(keyword);
    mainmenu.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(keyword) &&
        menuItems.type === 'link'
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(keyword) &&
          subItems.type === 'link'
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (
            suSubItems.title.toLowerCase().includes(keyword)
          ) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      checkSearchResultEmpty(items);
      setsearchValue(items);
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document
        .querySelector('.empty-menu')
        .classList.add('is-open');
    } else {
      setSearchResultEmpty(false);
      document
        .querySelector('.empty-menu')
        .classList.remove('is-open');
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document
      .querySelector('.Typeahead-menu')
      .classList.add('is-open');
    document.body.classList.add('offcanvas');
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue('');
    document
      .querySelector('.Typeahead-menu')
      .classList.remove('is-open');
    document.body.classList.remove('offcanvas');
  };

  const toggleBtn = () => {
    if (searchOpen) {
      setsearchOpen(!searchOpen);
      document
        .querySelector('.searchIcon')
        .classList.add('open');
    } else {
      setsearchOpen(!searchOpen);
      document
        .querySelector('.searchIcon')
        .classList.remove('open');
    }
  };

  return (
    <Fragment>
      <div>
        <form className="form-inline search-form">
          <div className="form-group">
            <input
              className="form-control-plaintext searchIcon"
              defaultValue={searchValue}
              onChange={(e) =>
                handleSearchKeyword(e.target.value)
              }
              placeholder="search"
              type="text"
            />
            <span
              className="d-sm-none mobile-search"
              onClick={toggleBtn}
            >
              <Search />
            </span>

            <div
              className="Typeahead-menu custom-scrollbar"
              id="search-outer"
            >
              {searchValue
                ? searchValue.map((data, index) => {
                  return (
                    <div
                      className="ProfileCard u-cf"
                      key={index}
                    >
                      <div className="ProfileCard-avatar">
                        <data.icon />
                      </div>
                      <div className="ProfileCard-details">
                        <div className="ProfileCard-realName">
                          <Link
                            className="realname"
                            onClick={removeFix}
                            to={`${process.env.PUBLIC_URL}${data.path}`}
                          >
                            {data.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
                : ''}
            </div>
            <div className="Typeahead-menu empty-menu">
              <div className="tt-dataset tt-dataset-0">
                <div className="EmptyMessage">
                  Opps!! There are no result found.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default SearchHeader;
