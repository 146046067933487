import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const FormGenderSelect = ({ control, rules }) => {
  const options = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  return (
    <Controller
      as={<Select placeholder="Select Gender" />}
      control={control}
      name="gender"
      options={options}
      placeholder="Select Gender"
      rules={rules}
    />
  );
};

export default FormGenderSelect;
