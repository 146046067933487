import React, { useEffect, useState } from "react";

import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import Loader from "./common/loader";

import "react-toastify/dist/ReactToastify.css";

const AppLayout = ({ children }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 2000);
  }, [show]);

  return (
    <div>
      <Loader show={show} />
      <div className="page-wrapper compact-wrapper">
        <div className="page-body-wrapper sidebar-icon">
          <Header />
          <Sidebar />
          <div className="page-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
