import React, { useState, useEffect } from "react";
import { useStep, useUser, useWindowResize } from "../../hooks";
import { getUserDetails } from "../../util";
import { Steps } from "rsuite";

import Welcome from "./steps/Welcome.js";
import BasicInfo from "./steps/BasicInfo.js";
import Terms from "./steps/Terms.js";
import FlinksConnect from "./steps/FlinksConnect.js";
import CardUpload from "./steps/CardUpload.js";
import Result from "./steps/Result.js";

import { steps } from "../../constant/constants";

const Wizard = () => {
  const user = useUser();
  const size = useWindowResize();

  const initialValues = getUserDetails(user);

  const [wizardData, setWizardData] = useState({
    ...initialValues,
    paymentCycleDuration: "3 Months",
    flinks: process.env.NODE_ENV === "production" ? null : true,
  });

  const { step, navigation, index } = useStep({ initialStep: 0, steps });

  const { id } = step;

  const props = {
    navigation,
    wizardData,
    setWizardData,
  };

  const resetWizard = () => {
    setWizardData({
      ...initialValues,
      paymentCycleDuration: "3 Months",
      flinks: process.env.NODE_ENV === "production" ? null : true,
    });
    navigation.goTo(0);
  };

  useEffect(() => {
    resetWizard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Steps current={index}>
        {steps.map((step) => (
          <Steps.Item title={size.width > 900  ? step.title : ""} />
        ))}
      </Steps>
      <div className="mb-4" />
      {id === "Step 1" && <Welcome {...props} />}
      {id === "Step 2" && <BasicInfo {...props} />}
      {id === "Step 3" && <Terms {...props} />}
      {id === "Step 4" && <CardUpload {...props} />}
      {id === "Step 5" && <FlinksConnect {...props} />}
      {id === "Step 6" && <Result {...props} />}
    </>
  );
};

export default Wizard;
