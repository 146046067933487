import React from 'react';
import moment from 'moment';

import ChartistGraph from 'react-chartist';

const chart = {
  height: '100%',
  fullWidth: true,
  chartPadding: {
    right: 8,
    left: -10,
    top: 14,
    bottom: -14,
  },
};

const defaultSeries = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0],
];

const labels = [
  '',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
];

const calculateFundsFromDate = (fund, date) =>
  fund
    .filter((fund) => moment(fund.createdAt).format('MM YYYY') === date)
    .reduce(function (prev, current) {
      return parseFloat(prev) + parseFloat(current.loanAmount);
    }, 0);

const FundsChart = ({ funds }) => {
  const sept = calculateFundsFromDate(funds, '08 2020');
  const oct = calculateFundsFromDate(funds, '09 2020');
  const nov = calculateFundsFromDate(funds, '10 2020');
  const dec = calculateFundsFromDate(funds, '11 2020');
  const jan = calculateFundsFromDate(funds, '12 2020');
  const feb = calculateFundsFromDate(funds, '01 2020');
  const mar = calculateFundsFromDate(funds, '02 2020');
  const apr = calculateFundsFromDate(funds, '03 2020');
  const may = calculateFundsFromDate(funds, '04 2020');
  const jun = calculateFundsFromDate(funds, '05 2020');
  const jul = calculateFundsFromDate(funds, '06 2020');
  const aug = calculateFundsFromDate(funds, '07 2020');

  const series =
    funds.length > 0
      ? [
          [sept, oct, nov, dec, jan, feb, mar, apr, may, jun, jul, aug, 0],
          [0, 0, 0, 0],
        ]
      : defaultSeries;

  function drawChart(data) {
    if (data.type === 'line' || data.type === 'area') {
      data.element.animate({
        d: {
          begin: 2000 * data.index,
          dur: 2000,
          from: data.path
            .clone()
            .scale(1, 0)
            .translate(0, data.chartRect.height())
            .stringify(),
          to: data.path.clone().stringify(),
        },
      });
    }
  }

  return (
    <div className="card">
      <div className="card-header b-l-primary">
        <h5>FUNDS STATISTICS</h5>
      </div>
      <div className="card-body">
        <div className="show-value-top d-flex">
          <div className="value-left d-inline-block">
            <div className="square bg-primary d-inline-block" />
            <span>Total Funded</span>
          </div>
          <div className="value-right d-inline-block">
            <div className="square d-inline-block bg-secondary" />
            <span>Lendle Score</span>
          </div>
        </div>
        <div className="smooth-chart flot-chart-container">
          <ChartistGraph
            className="ct-chart-line"
            data={{
              labels,
              series,
            }}
            key="1"
            listener={{
              draw: drawChart,
            }}
            options={chart}
            type={'Line'}
          />
        </div>
      </div>
    </div>
  );
};

export default FundsChart;
