/* eslint-disable no-undef */
import React, { Fragment } from "react";

import { useQuery } from "@apollo/client";

import { MARKETING_LEAD_QUERY } from "../../../graphql";
import currency from "currency.js";
import moment from "moment";

import { Navigation, DollarSign } from "react-feather";

import Breadcrumb from "../../../components/common/breadcrumb";
import CountUp from "react-countup";
var images = require.context("../../../assets/images", true);

const LeadItem = (props) => {
  const id = props.match.params.id;
  const { data, loading } = useQuery(MARKETING_LEAD_QUERY, {
    variables: {
      id: id,
    },
  });

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <Fragment>
          <Breadcrumb parent="Leads" title="View Lead" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6 xl-100">
                <div
                  className="card cuanimated bounceInLeft"
                  data-intro="This is University Earning Chart"
                  id="animated-example"
                >
                  <div className="card-header b-l-primary">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5>Lead Details</h5>
                      </div>
                      <div className="col-sm-6 text-right pull-right">
                        <small>
                          Application ID:{" "}
                          <strong>#{data.lead.id.toUpperCase()}</strong>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <div className="form-group">
                            <label className="form-label">
                              <strong>Name</strong>
                            </label>
                            <br />
                            <span
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {data.lead.name}
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Email Address</strong>
                          </label>
                          <br />
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {data.lead.email && data.lead.email.toLowerCase()}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Phone Number</strong>
                          </label>
                          <br />
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {data.lead.phone.toLowerCase()}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Gender</strong>
                          </label>
                          <br />
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {data.lead.gender.toLowerCase()}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Address</strong>
                          </label>
                          <br />
                          {data.lead.residentialAddress !== null && (
                            <span
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {data.lead.residentialAddress.street},{" "}
                              {data.lead.residentialAddress.city},{" "}
                              {data.lead.residentialAddress.suite},{" "}
                              {data.lead.residentialAddress.province},{" "}
                              {data.lead.residentialAddress.country}
                            </span>
                          )}
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Purpose Of Borrow</strong>
                          </label>
                          <br />
                          <span>{data.lead.purposeOfBorrow}</span>
                          <br />
                        </div>
                      </div>
                      <div className="col-sm-8 col-md-8">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Reason / Explanation</strong>
                          </label>
                          <br />
                          <span>{data.lead.borrowReason}</span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Verification URL</strong>
                          </label>
                          <br />
                          <span>
                            {" "}
                            https://lendle.com/app/verifyapp?lid=
                            {data.lead.id}&email={data.lead.email}&hid=
                            {data.lead.hash}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 xl-100 cuanimated bounceInRight"
                id="animated-example"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="media feather-main">
                          <div className="feather-icon-block">
                            <Navigation />
                          </div>
                          <div className="media-body align-self-center">
                            <h6>{data.lead.paymentCycleDuration}</h6>
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "black",
                              }}
                            >
                              Term - Ends:{" "}
                              <b>
                                {moment(data.lead.paymentCycleEndDate).format(
                                  "LL"
                                )}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="media chart-university">
                          <div className="media-body">
                            <h3 className="mb-0">
                              <DollarSign />
                              <span className="counter">
                                <CountUp
                                  decimals={2}
                                  end={currency(data.lead.loanAmount).add(
                                    data.lead.loanFee
                                  )}
                                />
                              </span>
                            </h3>
                            <p>Amount Payback</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="media chart-university">
                          <div className="media-body">
                            <h3 className="mb-0">
                              <DollarSign />
                              <span className="counter">
                                <CountUp
                                  decimals={2}
                                  end={data.lead.loanAmount}
                                />
                              </span>
                            </h3>
                            <p>Amount Requested</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="media chart-university">
                          <div className="media-body">
                            <h3 className="mb-0">
                              <DollarSign />
                              <span className="counter">
                                <CountUp
                                  decimals={2}
                                  end={data.lead.paymentCycleAmount}
                                />
                              </span>
                            </h3>
                            <p
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {data.lead.paymentCycleFrequency} - starts:{" "}
                              <b>
                                {moment(data.lead.paymentCycleStartDate).format(
                                  "LL"
                                )}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="media chart-university">
                          <div className="media-body">
                            <h3 className="mb-0">
                              <DollarSign />
                              <span className="counter">
                                <CountUp
                                  decimals={2}
                                  end={currency(data.lead.loanFee).value}
                                  start={0}
                                />
                              </span>
                            </h3>
                            <p>Admin Fee (tax incl.)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 xl-100">
                <div
                  className="card height-equal cuanimated bounceInRight"
                  id="animated-example"
                >
                  <div className="card-header b-l-primary">
                    <h5>Documents</h5>
                  </div>
                  <div className="card-body">
                    <div className="new-users">
                      <div className="media">
                        {data.lead.documents &&
                          data.lead.documents.bankStatement.length > 0 &&
                          data.lead.documents.bankStatement[0]
                            .split(".")
                            .pop()
                            .toLowerCase() !== "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.lead.documents.bankStatement[0]}`}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          data.lead.documents.bankStatement.length > 0 &&
                          data.lead.documents.bankStatement[0]
                            .split(".")
                            .pop()
                            .toLowerCase() === "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={images("./pdf.png")}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          data.lead.documents.bankStatement.length === 0 && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={
                                "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=B"
                              }
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        <div className="media-body">
                          <h6 className="mb-0 f-w-700">Bank Statements</h6>
                        </div>
                        <span className="pull-right">
                          {data.lead.documents &&
                          data.lead.documents.bankStatement.length > 0 ? (
                            <button
                              className="btn btn-pill btn-outline-light"
                              onClick={() => {
                                if (
                                  data.lead.documents.bankStatement[0]
                                    .split(".")
                                    .pop()
                                    .toLowerCase() === "pdf"
                                ) {
                                  window.location.href = `${
                                    process.env.REACT_APP_BASE_URL_PROD
                                  }/download?file=${encodeURI(
                                    data.lead.documents.bankStatement[0].replace(
                                      "uploads/",
                                      ""
                                    )
                                  )}`;
                                } else {
                                  toggle(
                                    data.lead.documents.bankStatement,
                                    "Bank Statement"
                                  );
                                }
                              }}
                            >
                              View Doc
                            </button>
                          ) : (
                            <button className="btn btn-pill btn-outline-light">
                              Request / Upload
                            </button>
                          )}
                        </span>
                      </div>

                      <div className="media">
                        {data.lead.documents &&
                          data.lead.documents.govIssuedId.length > 0 &&
                          data.lead.documents.govIssuedId[0]
                            .split(".")
                            .pop()
                            .toLowerCase() !== "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.lead.documents.govIssuedId[0]}`}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          data.lead.documents.govIssuedId.length > 0 &&
                          data.lead.documents.govIssuedId[0]
                            .split(".")
                            .pop()
                            .toLowerCase() === "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={images("./pdf.png")}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          !data.lead.documents.govIssuedId.length > 0 && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={
                                "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=G"
                              }
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        <div className="media-body">
                          <h6 className="mb-0 f-w-700">Gov Issued ID</h6>
                        </div>
                        <span className="pull-right">
                          {data.lead.documents &&
                          data.lead.documents.govIssuedId.length > 0 ? (
                            <button
                              className="btn btn-pill btn-outline-light"
                              onClick={() => {
                                if (
                                  data.lead.documents.govIssuedId[0]
                                    .split(".")
                                    .pop()
                                    .toLowerCase() === "pdf"
                                ) {
                                  window.location.href = `${
                                    process.env.REACT_APP_BASE_URL_PROD
                                  }/download?file=${encodeURI(
                                    data.lead.documents.govIssuedId[0].replace(
                                      "uploads/",
                                      ""
                                    )
                                  )}`;
                                } else {
                                  toggle(
                                    data.lead.documents.govIssuedId,
                                    "Gov Issued ID"
                                  );
                                }
                              }}
                            >
                              View Doc
                            </button>
                          ) : (
                            <button className="btn btn-pill btn-outline-light">
                              Request / Upload
                            </button>
                          )}
                        </span>
                      </div>

                      <div className="media">
                        {data.lead.documents &&
                          data.lead.documents.proofOfResidence.length > 0 &&
                          data.lead.documents.proofOfResidence[0]
                            .split(".")
                            .pop()
                            .toLowerCase() !== "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.lead.documents.proofOfResidence[0]}`}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          data.lead.documents.proofOfResidence.length > 0 &&
                          data.lead.documents.proofOfResidence[0]
                            .split(".")
                            .pop()
                            .toLowerCase() === "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={images("./pdf.png")}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          !data.lead.documents.proofOfResidence.length > 0 && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={
                                "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=R"
                              }
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        <div className="media-body">
                          <h6 className="mb-0 f-w-700">Proof Of Residence</h6>
                        </div>
                        <span className="pull-right">
                          {data.lead.documents &&
                          data.lead.documents.proofOfResidence.length > 0 ? (
                            <button
                              className="btn btn-pill btn-outline-light"
                              onClick={() => {
                                if (
                                  data.lead.documents.proofOfResidence[0]
                                    .split(".")
                                    .pop()
                                    .toLowerCase() === "pdf"
                                ) {
                                  window.location.href = `${
                                    process.env.REACT_APP_BASE_URL_PROD
                                  }/download?file=${encodeURI(
                                    data.lead.documents.proofOfResidence[0].replace(
                                      "uploads/",
                                      ""
                                    )
                                  )}`;
                                } else {
                                  toggle(
                                    data.lead.documents.proofOfResidence,
                                    "Proof Of Residence"
                                  );
                                }
                              }}
                            >
                              View Doc
                            </button>
                          ) : (
                            <button className="btn btn-pill btn-outline-light">
                              Request / Upload
                            </button>
                          )}
                        </span>
                      </div>

                      <div className="media">
                        {data.lead.documents &&
                          data.lead.documents.directDepositForm.length > 0 &&
                          data.lead.documents.directDepositForm[0]
                            .split(".")
                            .pop()
                            .toLowerCase() !== "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={`${process.env.REACT_APP_BASE_URL_PROD}/${data.lead.documents.directDepositForm[0]}`}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          data.lead.documents.directDepositForm.length > 0 &&
                          data.lead.documents.directDepositForm[0]
                            .split(".")
                            .pop()
                            .toLowerCase() === "pdf" && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={images("./pdf.png")}
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        {data.lead.documents &&
                          !data.lead.documents.directDepositForm.length > 0 && (
                            <img
                              alt=""
                              className="rounded-circle image-radius m-r-15"
                              src={
                                "https://ui-avatars.com/api/?uppercase=false&color=1b29f2&font-size=0.33&name=D"
                              }
                              style={{
                                height: "58px",
                              }}
                            />
                          )}
                        <div className="media-body">
                          <h6 className="mb-0 f-w-700">Direct Deposit Form</h6>
                        </div>
                        <span className="pull-right">
                          {data.lead.documents &&
                          data.lead.documents.directDepositForm.length > 0 ? (
                            <button
                              className="btn btn-pill btn-outline-light"
                              onClick={() => {
                                if (
                                  data.lead.documents.directDepositForm[0]
                                    .split(".")
                                    .pop()
                                    .toLowerCase() === "pdf"
                                ) {
                                  window.location.href = `${
                                    process.env.REACT_APP_BASE_URL_PROD
                                  }/download?file=${encodeURI(
                                    data.lead.documents.directDepositForm[0].replace(
                                      "uploads/",
                                      ""
                                    )
                                  )}`;
                                } else {
                                  toggle(
                                    data.lead.documents.directDepositForm,
                                    "Direct Deposit Form"
                                  );
                                }
                              }}
                            >
                              View Doc
                            </button>
                          ) : (
                            <button className="btn btn-pill btn-outline-light">
                              Request / Upload
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default LeadItem;
