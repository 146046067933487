import React from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import Datatable from "./datatable";

import { REFERRALS_BY_EMAIL } from "../graphql";
var images = require.context("../assets/images", true);

const UserReferrals = ({ email, campaign, hideAction = true }) => {
    const {data, loading} = useQuery(REFERRALS_BY_EMAIL, {
        variables: {
            email: email,
            campaign: campaign
        },
        fetchPolicy: "no-cache"
    });

    if(loading){
        return "...";
    }

    return (
        <>
         {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: "inherit" }}
              >
                <span className="rotate dotted" />
              </div>
            ) : data.referralsByUserEmail.length > 0 ? (
                <Datatable
                class="-striped -highlight"
                myData={data.referralsByUserEmail.map((ref) => {
                    let row = {};
                    row["ID"] = !hideAction ? ref.ref_id : ref.id;
                    row["Name"] = ref.ref_name;
                    row["Referred Email"] = ref.ref_email;
                    row["Registered Date"] = moment(ref.createdAt).format("LL");
                    return row;
                })}
                pageSize={15}
                pagination
                hideSearch
                hideAction={hideAction}
                routeUrl="users"
            />
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: "300px", marginTop: 0 }}
              >
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images("./search-not-found.png")}
                    style={{ marginTop: "-40px" }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
        </>
       
    )
}

export default UserReferrals;