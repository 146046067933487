import {
  ADD_COSTOMIZER,
  ADD_LAYOUT,
  ADD_SIDEBAR_TYPES,
  ADD_SIDEBAR_SETTINGS,
  ADD_COLOR,
  ADD_MIXlAYOUT,
} from '../../constant/actionTypes';
import ConfigDB from '../../config';

const initial_state = {
  customizer: ConfigDB.data,
  configData: {},
  layout: 'ltr',
  sidebar_types: {},
  settings: '',
  color: {},
  mix_layout: '',
};

export default (state = initial_state, action) => {
  const colors = action.payload;
  const mix_layout = action.payload;

  switch (action.type) {
    case ADD_COSTOMIZER:
      return { ...state, loading: false, customizer: ConfigDB.data };

    case ADD_LAYOUT:
      state.customizer.settings.layout_type = action.payload;
      return { ...state, loading: true, layout: state.customizer.settings.layout_type };

    case ADD_SIDEBAR_TYPES:
      state.customizer.settings.sidebar = action.payload;
      return { ...state, loading: true, sidebar_types: state.customizer.settings.sidebar };

    case ADD_SIDEBAR_SETTINGS:
      state.customizer.settings.sidebar_setting = action.payload;
      return { ...state, loading: true, settings: state.customizer.settings.sidebar_setting };

    case ADD_COLOR:
      state.customizer.color.primary_color = colors.primary_color;
      state.customizer.color.secondary_color = colors.secondary_color;
      state.customizer.color.theme_color = colors.theme_color;
      state.customizer.color.layout_version = colors.layout_version;

      return { ...state, color: colors, loading: true };

    case ADD_MIXlAYOUT:
      state.customizer.color.mix_layout = mix_layout;
      return { ...state, mix_layout: mix_layout, loading: true };

    default:
      return { ...state };
  }
};
