/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';

import {
  UpcomingPayments,
  Metrics,
  LendleScore,
  UserActivity,
} from '../../../components';

const CustomerDash = ({ metrics, loansApproved }) => {
  const payments =
    loansApproved.length > 0 ? loansApproved[0].paymentCycleSchedule : [];
  const loanFee = loansApproved.length > 0 ? loansApproved[0].loanFee : 0;
  const loanFeeTax =
    loansApproved.length > 0 ? loansApproved[0].loanFeeTax : 0;

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-xl-8 xl-100 cuanimated bounceInLeft"
            id="animated-example">
            <div className="row">
              <Metrics
                loanFee={loanFee}
                loanFeeTax={loanFeeTax}
                loansApproved={loansApproved}
                metrics={metrics}
              />
            </div>
            <UpcomingPayments payments={payments} />
          </div>
          <div
            className="col-xl-4 xl-100 cuanimated bounceInRight"
            id="animated-example">
            <LendleScore />
            <UserActivity />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerDash;
