import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  LOAN_CREATE_COMMENT,
  LOAN_COMMENTS,
} from '../graphql';

export default function Comments({ id, userid }) {
  const [
    addComment,
    { loading: addCommentLoading },
  ] = useMutation(LOAN_CREATE_COMMENT);
  const { register, handleSubmit, errors } = useForm(); // initialise the hook.
  const [validateClass, setValidateClass] = useState(false);

  const handleAddComment = async (formValues) => {
    if (formValues !== '') {
      try {
        await addComment({
          variables: {
            title: formValues.title,
            message: formValues.message,
            user: userid,
            loan: id,
          },
          refetchQueries: [
            {
              query: LOAN_COMMENTS,
              variables: {
                id: id,
              },
            },
          ],
        });
        toast.success('Successfully sent notifications');
      } catch (error) {
        toast.error(
          error.message.replace('GraphQL error: ', '')
        );
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <div className="card">
      <div className="card-header b-l-primary">
        <h5>Add Comment</h5>
      </div>
      <div className="card-body activity-scroll">
        {addCommentLoading && (
          <div className="loader-box loader-form-wrapper">
            <span className="rotate dotted" />
          </div>
        )}
        <form
          className={`theme-form needs-validation tooltip-validation ${
            validateClass ? 'validateClass' : ''
          }`}
          noValidate=""
          onSubmit={handleSubmit(handleAddComment)}>
          <fieldset
            aria-busy={addCommentLoading}
            disabled={addCommentLoading}>
            <div className="form-group">
              <label
                className="col-form-label"
                htmlFor="recipient-name">
                Title
              </label>
              <input
                className="form-control"
                name="title"
                ref={register({ required: true })}
                type="text"
              />
              {errors.title && (
                <span>Title is required</span>
              )}
            </div>
            <div className="form-group">
              <label
                className="col-form-label"
                htmlFor="recipient-name">
                Message
              </label>
              <textarea
                className="form-control"
                name="message"
                placeholder="Enter your message"
                ref={register({ required: true })}
                rows="3"
              />
              {errors.message && (
                <span>Message is required</span>
              )}
            </div>
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() =>
                  setValidateClass(!validateClass)
                }>
                Add Comment
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}
