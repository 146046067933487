import React from "react";

import {
  AddLoanPayment,
  EditLoanInformation,
  EditLoanTerms,
  UpdateLoanStatus,
  AssignLoanAdjudicator,
  UnAssignLoanAdjudicator,
} from "../../components";

export default function AdminMenu({ data, meId }) {
  return (
    <>
      {data.status === "INREVIEW" && data.status !== "ARCHIVE" && (
        <UpdateLoanStatus loan={data} status="APPROVED" title="Approve Loan" />
      )}

      {data.status !== "APPROVED" &&
        data.status !== "DENIED" &&
        data.status !== "ARCHIVE" && (
          <UpdateLoanStatus loan={data} status="DENIED" title="Deny Loan" />
        )}

      {data.status !== "INREVIEW" && data.status !== "APPROVED" && (
        <UpdateLoanStatus loan={data} status="INREVIEW" title="In Review" />
      )}
      {data.status === "APPROVED" && data.status !== "ARCHIVE" && (
        <>
        <AddLoanPayment loan={data} />
        <UpdateLoanStatus loan={data} status="DEFAULTED" title="Defaulted Loan" />
        </>
      )}

      {data.status !== "PENDING" &&
        data.status !== "WITHDRAW" &&
        data.status !== "ARCHIVE" &&
        data.status !== "DENIED" &&
        data.status !== "APPROVED" &&
        data.adj === null && (
          <>
            <AssignLoanAdjudicator meId={meId} loanId={data.id} />
          </>
        )}

      {data.status !== "PENDING" &&
        data.status !== "WITHDRAW" &&
        data.status !== "ARCHIVE" &&
        data.status !== "DENIED" &&
        data.status !== "APPROVED" &&
        data.adj !== null &&
        data.adj.id === meId && (
          <>
            <UnAssignLoanAdjudicator meId={meId} loanId={data.id} />
          </>
        )}

      <EditLoanInformation loan={data} />
      <EditLoanTerms noMaxDate loan={data} />

      {data.status !== "WITHDRAW" && data.status !== "ARCHIVE" && (
        <UpdateLoanStatus loan={data} status="WITHDRAW" title="Withdraw Loan" />
      )}
      {data.status === "WITHDRAW" && (
        <UpdateLoanStatus loan={data} status="ARCHIVE" title="Archive Loan" />
      )}
    </>
  );
}
