/* eslint-disable indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import Datatable from '../../../components/datatable';
import SearchDatatable from "../../../components/searchDatatable";

import Breadcrumb from '../../../components/common/breadcrumb';
import { MARKETING_LEADS_QUERY } from '../../../graphql';

var images = require.context(
  '../../../assets/images',
  true
);

const LeadsList = () => {
  const { loading, data } = useQuery(
    MARKETING_LEADS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="LEads" />
      <div className="container-fluid">
        <div
          className="email-top"
          style={{
            top: '25px',
            position: 'absolute',
            right: 0,
            width: 'auto',
            float: 'right',
          }}
        />
        <div
          className="card cuanimated bounceInLeft"
          id="animated-example">
          <div className="card-header b-l-primary">
            <h5>
              {!loading && data.leads
                ? 'Found ' +
                  data.leads.length +
                  ' Record(s)'
                : 'Loading...'}{' '}
            </h5>
          </div>
          <div className="card-body">
            {loading ? (
              <div
                className="loader-box loader-form-wrapper-static"
                style={{ position: 'inherit' }}>
                <span className="rotate dotted" />
              </div>
            ) : data.leads.length > 0 ? (
              <Datatable
                class="-striped -highlight"
                myData={data.leads.map((lead) => {
                  let row = {};
                  row['ID'] = lead.id;
                  row['Name'] = lead.name;
                  row['Email'] = lead.email;
                  row['Amount'] = '$' + lead.loanAmount;
                  row['Term'] = lead.paymentCycleDuration;
                  row['Campaign'] = lead.campaign;

                  row['Registered'] = moment(
                    lead.createdAt
                  ).format('LL');
                  return row;
                })}
                pageSize={15}
                pagination
                searchComponent={(props) =>{
                  return <SearchDatatable
                  columns={["ID","Name","Email","Amount","Term","Registered"]}
                  {...props}
                  />
                }}
                routeUrl="leads"
              />
            ) : (
              <div
                className="search-not-found text-center ng-star-inserted"
                style={{ height: '300px', marginTop: 0 }}>
                <div className="">
                  <img
                    alt=""
                    className="second-search"
                    src={images('./search-not-found.png')}
                    style={{ marginTop: '-50px' }}
                  />
                  <p className="mb-0">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LeadsList;
