/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import stepTwo from "../assets/images/completed.png";

import logo from "../assets/images/logo-3.png";

import { toast } from "react-toastify";
import { withRouter, Link } from "react-router-dom";

// import 'react-google-places-autocomplete/dist/assets/index.css';

import {
  VERIFY_LEAD_MUTATION,
  USER_PROFILE_QUERY,
  USER_LOAN_QUERY,
} from "../graphql";

const FLINKS_LINK =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FLINKS_PROD
    : process.env.REACT_APP_FLINKS_DEV;

const IS_DEMO = process.env.NODE_ENV !== "production";

const VerifyApp = ({ location, history }) => {
  const [formState, setFormState] = useState({});
  const tokens = location.search.split("?");
  const httparams = tokens[1].split("&");
  const lid = httparams[0].split("=")[1];
  const hid = httparams[2].split("=")[1];
  const email = httparams[1].split("=")[1];
  const [verifyLead, { loading, error }] = useMutation(VERIFY_LEAD_MUTATION);
  const [leadId, setLeadId] = useState();
  const [flinks, setFlinks] = useState(null);

  const [loadingFlinksIframe, setLoadingFlinksIframe] = useState(true);
  //added two different steps to choose from. redirect step happens after account selected step
  useEffect(() => {
    async function checkLead() {
      try {
        const data = await verifyLead({
          variables: {
            hid,
            lid,
            email,
          },
          refetchQueries: [
            { query: USER_PROFILE_QUERY },
            { query: USER_LOAN_QUERY },
          ],
          fetchPolicy: "no-cache",
        });
        setFormState({});
        setLeadId(data.data.verifyLead.message);
      } catch (e) {
        toast.error(e.message.replace("GraphQL error: ", ""));
      }
    }

    const flinksOnConnect = (e) => {
      const { data } = e;
      switch (data.step) {
        case "REDIRECT":
        case "ACCOUNT_SELECTED":
          setFlinks(true);
          checkLead();
          break;
        case "DISABLED_LOGIN":
          break;
        case "RETRY_LATER":
          break;
        case "OPERATION_DISPATCHED":
          break;
        case "OPERATION_PENDING":
          break;
        default:
          return null;
      }
    };
    window.addEventListener("message", flinksOnConnect);
    return () => window.removeEventListener("message", flinksOnConnect);
  }, [email, hid, lid, verifyLead]);

  const hideSpinner = () => {
    setLoadingFlinksIframe(false);
  };
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="auth-bg">
          <div
            style={{
              margin: "0 auto",
              zIndex: 2,
              width: "800px",
            }}
          >
            <div className="text-center">
              <Link to="/login">
                <img alt="" src={logo} />
              </Link>
            </div>
            <div />
            <div className="card mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h3>
                    Almost Done! <br />
                    {flinks === null ? (
                      <small className="text-muted">
                        The final step is to connect and authorize securely with
                        your bank institution.
                      </small>
                    ) : error ? (
                      error.message.replace("GraphQL error: ", "")
                    ) : (
                      <small className="text-muted">
                        Congratulations, we have all that we need form you.{" "}
                        <br />
                        Click the link below to get started with Lendle
                      </small>
                    )}
                  </h3>
                  <h6>
                    {loading && "Please wait while we verify your application"}
                  </h6>
                </div>
                {loadingFlinksIframe && (
                  <div className="loader-box loader-form-wrapper">
                    <span className="rotate dotted" />
                  </div>
                )}
                {flinks === null ? (
                  <iframe
                    title="Flinks Connect"
                    class="flinksconnect"
                    height="720"
                    scrolling="no"
                    frameBorder={0}
                    onLoad={hideSpinner}
                    src={`${FLINKS_LINK}?desktopLayout=true&institutionFilterEnable=true&backgroundColor=f8f8f8&foregroundColor1=000000&foregroundColor2=000000&daysOfTransactions=Days365&demo=${IS_DEMO}`}
                  ></iframe>
                ) : loading ? (
                  <div className="loader-box loader-form-wrapper">
                    <span className="rotate dotted" />
                  </div>
                ) : error ? (
                  error.message.replace("GraphQL error: ", "")
                ) : (
                  <div className="d-flex align-items-center flex-column p-2">
                    <img
                      alt="Verify Your Email"
                      className="mt-3"
                      src={stepTwo}
                      style={{
                        width: "90%",
                      }}
                    />
                    <div className="typography text-center mt-4" />
                    <div className="row">
                      <div className="col-sm-12 text-right mt-3 mb-3">
                        <button
                          className="btn btn-success btn-lg ml-auto"
                          onClick={() => {
                            history.push(
                              `${process.env.PUBLIC_URL}/dashboard/applications/${leadId}`
                            );
                          }}
                          type="button"
                        >
                          View My Application
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(VerifyApp);
