import React from "react";

import {
  AddLoanPayment,
  AssignLoanAdjudicator,
  UnAssignLoanAdjudicator,
  EditLoanInformation,
  EditLoanTerms,
  UpdateLoanStatus,
} from "../../components";

export default function StaffMenu({ data, meId }) {
  return (
    <>
      {data.status === "INREVIEW" &&
        data.status !== "ARCHIVE" &&
        data.adj !== null &&
        data.adj.id === meId && (
          <UpdateLoanStatus
            loan={data}
            status="APPROVED"
            title="Approve Loan"
          />
        )}

      {data.status !== "APPROVED" &&
        data.status !== "ARCHIVE" &&
        data.status !== "DENIED" &&
        data.adj !== null &&
        data.adj.id === meId && (
          <UpdateLoanStatus loan={data} status="DENIED" title="Deny Loan" />
        )}

      {data.status === "APPROVED" &&
        data.adj !== null &&
        data.adj.id === meId &&
        data.status !== "ARCHIVE" && <AddLoanPayment loan={data} />}

      {data.status !== "PENDING" &&
        data.status !== "WITHDRAW" &&
        data.status !== "ARCHIVE" &&
        data.status !== "DENIED" &&
        data.status !== "APPROVED" &&
        data.adj === null && (
          <>
            <AssignLoanAdjudicator meId={meId} loanId={data.id} />
          </>
        )}

      {data.status !== "PENDING" &&
        data.status !== "WITHDRAW" &&
        data.status !== "ARCHIVE" &&
        data.status !== "DENIED" &&
        data.status !== "APPROVED" &&
        data.adj !== null &&
        data.adj.id === meId && (
          <>
            <UnAssignLoanAdjudicator meId={meId} loanId={data.id} />
          </>
        )}

      <EditLoanTerms loan={data} />
      <EditLoanInformation loan={data} />

      {data.status !== "WITHDRAW" && data.status !== "ARCHIVE" && (
        <UpdateLoanStatus loan={data} status="WITHDRAW" title="Withdraw Loan" />
      )}
      {data.status === "WITHDRAW" && (
        <UpdateLoanStatus loan={data} status="ARCHIVE" title="Archive Loan" />
      )}
    </>
  );
}
