import React, { useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { useUser } from "../hooks"

import {
  FormFancyCheckbox,
  FormBorrowPurposeSelect,
  ReactDatePicker,
  RangeSlider,
  FormEmploymentIncome,
} from "../components";

import {
  ADMIN_UPDATE_LOAN_TERMS,
  USER_LOAN_QUERY,
  USER_LOAN_QUERY_BYID,
} from "../graphql";

import loanCalc from "../services/loanCalc";
import { termOptions } from "../constant/constants";

const EditLoanTerms = ({ loan, noMaxDate = false }) => {
  const me = useUser();

  const [modal, toggle] = useState(false);
  const [updateLoan, { loading }] = useMutation(ADMIN_UPDATE_LOAN_TERMS);

  const isAdmin = me.permissions.includes("ADMIN");
  const isAdjudicator = me.permissions.includes("ADJUDICATOR");

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      ...loan,
      paymentCycleStartDate: moment(loan.paymentCycleStartDate).toDate(),
      purposeOfBorrow: {
        value: loan.purposeOfBorrow,
        label: loan.purposeOfBorrow,
      },
      employmentAndIncome: loan.employmentAndIncome !== null && loan.employmentAndIncome.length > 0 ? loan.employmentAndIncome.map((emp) => ({
        type: emp.title,
        desc: emp.desc,
        name: emp.meta,
      })) : [{ type: "placeholder", desc: "", name: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employmentAndIncome",
  });

  const toggleModal = () => toggle(!modal);

  const onSubmit = async (data) => {
    try {
      const loanData = loanCalc(
        data.loanAmount,
        parseInt(data.paymentCycleDuration),
        data.paymentCycleFrequency,
        data.paymentCycleStartDate.toString()
      );

      await updateLoan({
        variables: {
          id: loan.id,
          userId: loan.user.id,
          ...data,
          ...loanData,
          employmentAndIncome: data.employmentAndIncome.map((emp) => ({
            title: emp.type,
            desc: emp.desc,
            meta: emp.name,
          })),
          purposeOfBorrow: data.purposeOfBorrow.value,
        },
        refetchQueries: [
          {
            query: USER_LOAN_QUERY,
          },
          {
            query: USER_LOAN_QUERY_BYID,
            variables: {
              id: loan.id,
            },
          },
        ],
      });

      reset({
        ...data,
        ...loanData,
        paymentCycleStartDate: moment(loanData.paymentCycleStartDate).toDate(),
        loanAmount: parseInt(loanData.loanAmount),
      });
      toast.success("Successfully Updated Loan");
      toggleModal(!modal);
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  const handleLoanAmountChange = (e) => {
    let loanAmount = e.target.value;
    if (!isNaN(e.target.value)) {
      parseInt(loanAmount);
      if(isAdmin || isAdjudicator){
        setValue("loanAmount", loanAmount);
      } else {
        if (loanAmount > 2000) {
          loanAmount = 2000;
        }
        setValue("loanAmount", loanAmount);
      }
    }
  };

  const watchLoanAmount = watch("loanAmount", loan.loanAmount);
  const employmentValidation = watch("employmentAndIncome").every(function (
    val
  ) {
    return val.type !== "" && val.desc !== "";
  });

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} size="lg">
        <ModalHeader>Edit Terms</ModalHeader>
        <ModalBody>
          {loading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          <div className="card">
            <form
              className={" needs-validation tooltip-validation"}
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="card-body">
                <h6>
                  <strong>Borrow Amount</strong>
                </h6>
                <div className="form-group row">
                  <div className="col-md-12">
                    <div className="row align-items-center">
                      <label
                        className="col-md-auto col-form-label sm-left-text"
                        htmlFor="u-range-01"
                      >
                        Amount
                      </label>
                      <div className="col-md-8">
                        <RangeSlider
                          control={control}
                          defaultValue={1000}
                          name="loanAmount"
                          input="applyForm"
                          max={(isAdmin || isAdjudicator) ? 5000 : 2000}
                          min={50}
                          maxDate={
                            noMaxDate
                              ? moment().add("days", 365).toDate()
                              : moment().add("days", 30).toDate()
                          }
                          step={50}
                          rules={{
                            required: {
                              value: true,
                              message: "Loan amount required",
                            },
                            min: {
                              value: 50,
                              message: "Loan amount must be greater than $50",
                            },
                            max: {
                              value: (isAdmin || isAdjudicator) ? 5000 : 2000,
                              message: "Loan amount must be less than $2000",
                            },
                            pattern: {
                              value: /^[-+]?\d+$/,
                              message:
                                "Please enter a number without decimal point.",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex">
                    <div className="d-flex flex-row align-items-center">
                      <label
                        className="col-md-auto col-form-label sm-left-text"
                        htmlFor="u-range-01"
                      >
                        Or enter custom amount $
                      </label>
                      <div className="col-md-11">
                        <input
                          className="form-control"
                          type="number"
                          name="loanAmount"
                          value={watchLoanAmount}
                          onChange={handleLoanAmountChange}
                        />
                        {errors.loanAmount && (
                          <span> {errors.loanAmount.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h6>
                  <strong>Borrow Term</strong>
                </h6>
                <div className="row">
                  <FormFancyCheckbox
                    options={loan.campaign === "student_benefit_plan" ? [ {
                      id: 0,
                      label: "4 Months",
                      term: 4,
                      description: "This term will cover 1 semester.",
                    }] : termOptions}
                    loanAmount={watchLoanAmount}
                    setValue={setValue}
                    watch={watch}
                    input="paymentCycleDuration"
                    defaultValue={watch("paymentCycleDuration")}
                  />
                </div>

                <input
                  type="hidden"
                  ref={register}
                  name="paymentCycleDuration"
                />

                <h6>
                  <strong>Purpose Of Borrow</strong>
                </h6>
                <div className="form-group row">
                  <FormBorrowPurposeSelect
                    errors={errors}
                    control={control}
                    rules={{ required: true }}
                  />
                </div>
                <h6>
                  <strong>Payments Term</strong>
                </h6>
                <div className="form-group row">
                  <label
                    className="col-md-auto col-form-label"
                    htmlFor="inputPassword3"
                  >
                    When should payback payments begin?
                  </label>
                  <div className="col-sm-9">
                    <ReactDatePicker
                      control={control}
                      name="paymentCycleStartDate"
                      placeholderText={
                        "Select a date between today and 30 days in the future"
                      }
                      rules={{ required: true }}
                    />

                    {errors.paymentCycleStartDate && (
                      <span> Start date is required</span>
                    )}
                  </div>
                </div>
                <fieldset className="form-group">
                  <div className="row">
                    <label className="col-form-label col-sm-2 pt-0">
                      When do you want to pay?
                    </label>
                    <div className="col-sm-9">
                      <div className="radio radio-primary ml-2">
                        <input
                          ref={register}
                          id="biweekly"
                          name="paymentCycleFrequency"
                          type="radio"
                          value="biweekly"
                          defaultChecked
                        />
                        <label htmlFor="biweekly">Bi-Weekly</label>
                      </div>
                      <div className="radio radio-primary ml-2">
                        <input
                          ref={register}
                          id="monthly"
                          name="paymentCycleFrequency"
                          type="radio"
                          value="monthly"
                        />
                        <label htmlFor="monthly">Monthly</label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <h6>
                  <strong>Employment &amp; Income</strong>
                </h6>
                <div>
                  <FormEmploymentIncome
                    remove={remove}
                    register={register}
                    watch={watch}
                    fields={fields}
                    onAdd={() => append()}
                    showAddBtn={employmentValidation}
                  />

                  {errors.employmentAndIncome && (
                    <span style={{ color: "red" }}>
                      Employment information is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-prev btn-success btn-lg pull-right"
                  onClick={() => handleSubmit(onSubmit)}
                >
                  Save
                </button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <button onClick={toggleModal}>Edit Terms</button>
    </React.Fragment>
  );
};

export default EditLoanTerms;
