import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import Select from "react-select";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  CARD_ADD_PAYMENT,
  USER_CARDS_QUERY,
  CARD_REQUEST_BYID_QUERY,
} from "../graphql";

const AddCardPayment = ({ card, component: button }) => {
  const { errors, register, handleSubmit, control } = useForm({
    defaultValues: {
      card: card.cardApplication.id,
      user: card.cardApplication.user.id,
    },
  });

  const [modal, toggleModal] = useState(false);
  const [validateClass, setValidateClass] = useState(false);

  const [addPayment, { loading }] = useMutation(CARD_ADD_PAYMENT);

  const handleAddPayment = async (data) => {
    if (data !== "") {
      try {
        await addPayment({
          variables: {
            cardId: data.card,
            amount: data.amount,
            userId: data.user,
            desc: data.desc ? data.desc.value : "",
          },
          refetchQueries: [
            {
                query: USER_CARDS_QUERY,
            },
            {
                query: CARD_REQUEST_BYID_QUERY,
                variables: {
                    id: card.cardApplication.id,
                },
            },
          ],
        });
        toast.success("Successfully added a payment");
        toggleModal(!modal);
      } catch (error) {
        toast.error(error.message.replace("GraphQL error: ", ""));
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} toggle={toggleModal}>
        <form
          className={`theme-form needs-validation tooltip-validation ${
            validateClass ? "validateClass" : ""
          }`}
          noValidate=""
          onSubmit={handleSubmit(handleAddPayment)}
        >
          <ModalHeader toggle={toggleModal}>Add Payment</ModalHeader>
          <ModalBody>
            {loading && (
              <div className="loader-box loader-form-wrapper">
                <span className="rotate dotted" />
              </div>
            )}
            <fieldset aria-busy={loading} disabled={loading}>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  User ID:
                </label>
                <input
                  className="form-control"
                  disabled
                  name="user"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  Card App ID:
                </label>
                <input
                  className="form-control"
                  disabled
                  name="card"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  Amount:
                </label>
                <input
                  className="form-control"
                  name="amount"
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  What is the reason for this payment (Required):
                </label>
                <Controller
                    as={<Select placeholder="Select Reason" />}
                control={control}
                name="desc"
                rules={{
                  required: true
                }}
                options={[
                    {
                        label: "Activation Fee",
                        value: "Activation Fee"
                    },
                    {
                      label: "Balance Payment",
                      value: "Balance Payment"
                  },
                    {
                        label: "Collateral",
                        value: "Collateral"
                    },
                ]}
                placeholder="Select Reason"
                /> 
              </div>
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setValidateClass(!validateClass)}
              type="submit"
            >
              Submit Payment
            </Button>
            <Button color="secondary" onClick={() => toggleModal(!modal)}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <button onClick={() => toggleModal(!modal)}>Add Payment</button>
    </React.Fragment>
  );
};

export default AddCardPayment;
