import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ValidIdentificationsList = ({ button }) => {
  const [modal, setModal] = useState();

  const toggle = () => {
    setModal(!modal);
  };
  return (
    <>
      <Modal fade={false} isOpen={modal} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Acceptable valid identification includes
        </ModalHeader>
        <ModalBody>
          <ul style={{ padding: "10px" }}>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • passport (an international passport is acceptable if it includes
              the name, date of birth, photo and signature of the applicant and
              is accompanied by a professionally translated version if not in
              French or English)
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • driver's license
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • enhanced driver's license
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • Canadian military identification card
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • government-issued identification card
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • government-issued enhanced identification card
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • We do not accept health cards
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • Canadian citizenship card (issued before February 1, 2012)
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • Canadian permanent resident card
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • U.S. permanent resident card (green card)
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • Original documents or photocopies are accepted. Photocopies must
              be of the front and back of the document, signed and dated by a
              guarantor and accompanied by a guarantor declaration
            </li>
            <li
              style={{
                textTransform: "capitalize",
                display: "paragraph",
                marginBottom: "5px",
              }}
            >
              • Indigenous and Northern Affairs Canada (INAC) makes every
              possible effort to return original documents within one month
              after receiving an application.
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {button(toggle)}
    </>
  );
};

export default ValidIdentificationsList;
