import React, { useEffect, useState } from "react";

const FLINKS_LINK =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FLINKS_PROD
    : process.env.REACT_APP_FLINKS_DEV;

const IS_DEMO = process.env.NODE_ENV !== "production";

const FlinksConnect = ({
  setWizardData,
  wizardData,
  navigation: { next, previous },
}) => {
  const [showNext, setShowNext] = useState(false);
  const [loading, setLoading] = useState(true);
  //added two different steps to choose from. redirect step happens after account selected step
  useEffect(() => {
    const flinksOnConnect = (e) => {
      const { data } = e;
      switch (data.step) {
        case "REDIRECT":
        case "ACCOUNT_SELECTED":
          setShowNext(true);
          setWizardData((state) => ({
            ...state,
            flinks: true,
          }));
          break;
        case "DISABLED_LOGIN":
          alert("Bank Login Disabled");
          break;
        case "RETRY_LATER":
          alert(
            "There was an issue connecting to your Bank, please retry in a few minutes."
          );
          break;
        case "OPERATION_DISPATCHED":
          break;
        case "OPERATION_PENDING":
          break;
        default:
          return null;
      }
    };
    window.addEventListener("message", flinksOnConnect);
    return () => window.removeEventListener("message", flinksOnConnect);
  }, []);

  const hideSpinner = () => {
    setLoading(false);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>Step 5 - Securely Connect With Your Bank</h5>
      </div>
      {wizardData.flinks === null ? (
        <div className="card-body">
          {loading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          <div className="d-flex flex-row justify-content-between mb-2">
            <button
              onClick={previous}
              type="button"
              className="btn btn-prev btn-default btn-lg pull-left"
              id="next-button"
            >
              Previous
            </button>
            {showNext && (
              <button
                onClick={next}
                className="btn btn-prev btn-success btn-lg pull-right"
                id="next-button"
              >
                Next
              </button>
            )}
          </div>
          <iframe
            title="Flinks Connect"
            class="flinksconnect"
            height="720"
            scrolling="no"
            frameBorder={0}
            onLoad={hideSpinner}
            src={`${FLINKS_LINK}?desktopLayout=true&institutionFilterEnable=true&backgroundColor=f8f8f8&foregroundColor1=000000&foregroundColor2=000000&daysOfTransactions=Days365&demo=${IS_DEMO}`}
          ></iframe>
        </div>
      ) : (
        <div className="card-body">
          <span>Successfully Authorized With Your Banking Institution.</span>
        </div>
      )}
      <div className="card-footer">
        <button
          onClick={previous}
          type="button"
          className="btn btn-prev btn-default btn-lg pull-left"
          id="next-button"
        >
          Previous
        </button>
        {(showNext || wizardData.flinks !== null) && (
          <button
            onClick={next}
            className="btn btn-prev btn-success btn-lg pull-right"
            id="next-button"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default FlinksConnect;
