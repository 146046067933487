import React,{Fragment} from 'react';
import { useQuery } from '@apollo/client';
import { USER_NOTIFICATIONS_QUERY } from '../../../graphql';

import NotificationItem from './notificationItem';

const Notification = () => {  
  const { data, loading } = useQuery(
    USER_NOTIFICATIONS_QUERY,
    {
      variables: {
        returnOnlyNew: true
      },
      fetchPolicy: 'no-cache'
    }
  );
  return (
    <Fragment>
      {
        !loading && (
          <div>
            <ul className="notification-dropdown onhover-show-div p-0">
              <li>Notification <span className="badge badge-pill badge-primary pull-right">{data && data.userNotifications ?  data.userNotifications.length : 0}</span></li>
              {
                data.userNotifications && data.userNotifications.length > 0 && data.userNotifications.map((notification, index) => 
                  <NotificationItem
                    category={notification.category}
                    createdAt={notification.createdAt}
                    id={notification.id}
                    index={index}
                    key={index}
                    message={notification.message}
                    read={notification.read}
                    title={notification.title}
                  />)
              }
            </ul>
  
          </div> )
      }
      
    </Fragment>
  );
};

export default Notification;