import React, { Fragment, useState } from "react";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useUserById } from "../../hooks";

import {
  Breadcrumb,
  Reveal,
  UserActivity,
  UserLoans,
  FormEditPassword,
  FormEditProfile,
  ProfileMenu,
} from "../../components";

import {
  ADMIN_UPDATE_ACCOUNT_STATUS,
  ADMIN_VERIFY_ACCOUNT,
  AUTH_EMAIL_VERIFICATION
} from "../../graphql";

const Profile = ({ match }) => {
  const userById = useUserById(match.params.id);

  const [
    updateAccountStatus,
    { loading: updateAccountStatusLoading },
  ] = useMutation(ADMIN_UPDATE_ACCOUNT_STATUS);

  const [
    activateAccount,
    { loading: updateAccountActivationStatus },
  ] = useMutation(ADMIN_VERIFY_ACCOUNT);

  const [
    resendVerification,
    { loading: resentAccoutnVerificationStatus },
  ] = useMutation(AUTH_EMAIL_VERIFICATION);

  const [dropdownOpen, setOpen] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [alertBox2, setAlertBox2] = useState(false);
  const [alertBox3, setAlertBox3] = useState(false);
  const [alertBox4, setAlertBox4] = useState(false);

  const toggleDropDown = () => setOpen(!dropdownOpen);

  const handleUpdateAccountStatus = async (status) => {
    try {
      await updateAccountStatus({
        variables: {
          id: userById.id,
          status: status,
        },
      });
      // await refetchUserById();
      toast.success("Successfully Updated Account Status");
      resetAlertBoxes();
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  const handleActivateAccount = async () => {
    try {
      await activateAccount({
        variables: {
          id: userById.id,
        },
      });
      // await refetchUserById();
      toast.success("Successfully Verified Account");
      resetAlertBoxes();
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };
  const handleResendEmailVerification = async () => {
    try {
      await resendVerification({
        variables: {
          id: userById.id,
        },
      });
      // await refetchUserById();
      toast.success("Successfully Resent Verification Link");
      resetAlertBoxes();
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  const resetAlertBoxes = () => {
    setAlertBox(false);
    setAlertBox2(false);
    setAlertBox3(false);
    setAlertBox4(false);
  };

  if (!userById) {
    return "Loading...";
  }

  return (
    <Fragment>
      {alertBox && (
        <SweetAlert
          confirmBtnBsStyle={""}
          confirmBtnText="Continue"
          onCancel={() => setAlertBox(false)}
          onConfirm={() => handleUpdateAccountStatus("ARCHIVE")}
          showCancel
          title="Are you sure?"
          type={""}
        >
          {updateAccountStatusLoading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          You are about to delete this account
        </SweetAlert>
      )}
      {alertBox2 && (
        <SweetAlert
          confirmBtnBsStyle={""}
          confirmBtnText="Continue"
          onCancel={() => setAlertBox2(false)}
          onConfirm={() => handleUpdateAccountStatus("PENDING")}
          showCancel
          title="Are you sure?"
          type={""}
        >
          {updateAccountStatusLoading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          You are about to restore this account
        </SweetAlert>
      )}
      {alertBox3 && (
        <SweetAlert
          confirmBtnBsStyle={""}
          confirmBtnText="Continue"
          onCancel={() => setAlertBox3(false)}
          onConfirm={() => handleActivateAccount()}
          showCancel
          title="Are you sure?"
          type={""}
        >
          {updateAccountActivationStatus && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          You are about to manually verify this account
        </SweetAlert>
      )}
      {alertBox4 && (
        <SweetAlert
          confirmBtnBsStyle={""}
          confirmBtnText="Continue"
          onCancel={() => setAlertBox4(false)}
          onConfirm={() => handleResendEmailVerification()}
          showCancel
          title="Are you sure?"
          type={""}
        >
          {resentAccoutnVerificationStatus && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          You are about to resend the user's email verification link
        </SweetAlert>
      )}
      <Breadcrumb parent="User" title="Profile" />
      <div className="container-fluid">
        <div
          className="email-top"
          style={{
            top: "20px",
            position: "absolute",
            right: 0,
            width: "auto",
            float: "right",
          }}
        >
          <div className="col text-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
              <DropdownToggle caret className="dropbtn" color="primary">
                Menu
              </DropdownToggle>
              <DropdownMenu>
                <ProfileMenu
                  onClickDeleteAccount={() => {
                    setAlertBox(true);
                  }}
                  onClickRestoreAccount={() => {
                    setAlertBox2(true);
                  }}
                  onClickVerifyAccount={() => {
                    setAlertBox3(true);
                  }}
                  onClickResendVerifyLink={() => {
                    setAlertBox4(true);
                  }}
                  user={userById}
                />
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <div className="edit-profile">
          <div className="row">
            <div
              className="col-lg-4 cuanimated bounceInLeft"
              id="animated-example"
            >
              <div className="card">
                <div className="card-header b-l-primary">
                  <h4 className="card-title mb-0">My Profile</h4>
                  <Reveal
                    label="# Account Id"
                    secret={userById.id.toUpperCase()}
                  />
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up" />
                    </a>
                    <a
                      className="card-options-remove"
                      data-toggle="card-remove"
                      href="#javascript"
                    >
                      <i className="fe fe-x" />
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <FormEditPassword me={userById} viewingSelf={false} />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 cuanimated bounceInRight"
              id="animated-example"
            >
              <FormEditProfile me={userById} />
            </div>
            <div
              className="col-lg-4 cuanimated bounceInRight"
              id="animated-example"
            >
              <UserActivity id={userById.id} />
            </div>
          </div>
        </div>
      </div>
      <UserLoans userId={match.params.id} />
    </Fragment>
  );
};

export default withRouter(Profile);
