import React, { Fragment } from "react";

import { useUser } from "../../hooks";

import {
  Breadcrumb,
  Reveal,
  UserActivity,
  FormEditPassword,
  FormEditProfile,
} from "../../components";

const Me = ({ match }) => {
  const user = useUser();

  const isAdmin =
    user.permissions.includes("ADMIN") ||
    user.permissions.includes("ADJUDICATOR");

  if (!user) {
    return "Loading...";
  }
  return (
    <Fragment>
      <Breadcrumb parent="User" title="Profile" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div
              className="col-lg-4 cuanimated bounceInLeft"
              id="animated-example"
            >
              <div className="card">
                <div className="card-header b-l-primary">
                  <h4 className="card-title mb-0">My Profile</h4>
                  <Reveal label="# Account Id" secret={user.id.toUpperCase()} />
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up" />
                    </a>
                    <a
                      className="card-options-remove"
                      data-toggle="card-remove"
                      href="#javascript"
                    >
                      <i className="fe fe-x" />
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <FormEditPassword
                    isAdmin={isAdmin}
                    me={user}
                    viewingSelf={true}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 cuanimated bounceInRight"
              id="animated-example"
            >
              <FormEditProfile isAdmin={isAdmin} me={user} />
            </div>
            <div
              className="col-lg-4 cuanimated bounceInRight"
              id="animated-example"
            >
              <UserActivity />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Me;
