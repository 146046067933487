import React, { useState } from "react";
import {  useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";

import { FormCancelCodeSelect } from "../components";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  DC_CANCEL_CARD,
  USER_CARDS_QUERY,
  CARD_REQUEST_BYID_QUERY,
} from "../graphql";


const CancelCard = ({ card, component: button }) => {
  const { errors, register, handleSubmit, control } = useForm({
    defaultValues: {
      card: card.cardApplication.id,
      user: card.cardApplication.user.id,
    },
  });

  const [modal, toggleModal] = useState(false);
  const [validateClass, setValidateClass] = useState(false);

  const [dcCancelCard, { loading }] = useMutation(DC_CANCEL_CARD);

  const handleCancelCard = async (data) => {
    try {
      await dcCancelCard({
        variables: {
          applicationId: card.cardApplication.id,
          customerNumber: card.cardApplication.user.customerNumber,
          lastFourDigits: parseInt(card.cardApplication.lastFourDigits),
          cancelcode: data.cancelreason.value,
          desc: data.cancelreason.label
        },
        refetchQueries: [
          {
              query: USER_CARDS_QUERY,
          },
          {
              query: CARD_REQUEST_BYID_QUERY,
              variables: {
                  id: card.cardApplication.id,
              },
          },
        ],
      })
      toast.success("Successfully Cancelled Card");
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  }

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} toggle={toggleModal}>
        <form
          className={`theme-form needs-validation tooltip-validation ${
            validateClass ? "validateClass" : ""
          }`}
          noValidate=""
          onSubmit={handleSubmit(handleCancelCard)}
        >
          <ModalHeader toggle={toggleModal}>Cancel Card</ModalHeader>
          <ModalBody>
            {loading && (
              <div className="loader-box loader-form-wrapper">
                <span className="rotate dotted" />
              </div>
            )}
            <fieldset aria-busy={loading} disabled={loading}>
              <div className="form-group">
                <label className="col-form-label" htmlFor="recipient-name">
                  What is the reason for this cancellation:
                </label>
                <FormCancelCodeSelect
                        control={control}
                       rules={{
                      required: true,
                      maxLength: 10,
                      validate: (select) => select.value !== undefined,
                    }}
                  />
                {errors.cancelreason && <span>Reason is required</span>}
              </div>
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setValidateClass(!validateClass)}
              type="submit"
            >
              Submit Cancellation
            </Button>
            <Button color="secondary" onClick={() => toggleModal(!modal)}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <button onClick={() => toggleModal(!modal)}>Cancel Card</button>
    </React.Fragment>
  );
};

export default CancelCard;
