import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { isValidPhoneNumber } from "react-phone-number-input";
import { setReactSelect, getUserDetails } from "../util";

import {
  FormDobSelect,
  FormGenderSelect,
  FormPhoneInput,
  FormProvinceSelect,
} from "../components";

import {
  ADMIN_UPDATE_CARD_INFORMATION,
  USER_CARDS_QUERY,
  CARD_REQUEST_BYID_QUERY,
} from "../graphql";

const EditCardInformation = ({ card }) => {
  const [modal, toggle] = useState(false);

  const [updateCardInformation, { loading }] = useMutation(
    ADMIN_UPDATE_CARD_INFORMATION
  );

  const defaultValues = setReactSelect(
    ["province", "gender", "dobDay", "dobMonth", "dobYear"],
    {
      ...card,
      province: card.residentialAddress
        ? card.residentialAddress.province
        : null,
    }
  );

  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      ...getUserDetails({
        ...card,
      }),
      ...defaultValues,
      dobDay: {
        label: card.dob.split("-")[0],
        value: card.dob.split("-")[0],
      },
      dobMonth: {
        label: card.dob.split("-")[1],
        value: card.dob.split("-")[1],
    },
      dobYear: {
        label: card.dob.split("-")[2],
        value: card.dob.split("-")[2],
      },
    },
  });

  const handleSaveInformation = async (data) => {
    if (data === "") {
      errors.showMessages();
      return;
    }
    try {
      const payload = {
        id: card.id,
        userId: card.user.id,
        ...data,
        name: `${data.firstname} ${data.lastname}`,
        province: data.province.value,
        gender: data.gender.value,
        dob: `${data.dobDay.value}-${data.dobMonth.value}-${data.dobYear.value}`,
      };

      await updateCardInformation({
        variables: {
          ...payload,
        },
        refetchQueries: [
          {
            query: USER_CARDS_QUERY,
          },
          {
            query: CARD_REQUEST_BYID_QUERY,
            variables: {
              id: card.id,
            },
          },
        ],
      });

      reset({
        ...data,
      });
      toast.success("Successfully Updated card");
      toggleModal(!modal);
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };
  const toggleModal = () => toggle(!modal);

  return (
    <React.Fragment>
      <Modal fade={false} isOpen={modal} size="lg">
        <ModalHeader>Edit Information</ModalHeader>
        <ModalBody>
          {loading && (
            <div className="loader-box loader-form-wrapper">
              <span className="rotate dotted" />
            </div>
          )}
          <div className="card">
            <form
              className={" needs-validation tooltip-validation"}
              noValidate=""
              onSubmit={handleSubmit(handleSaveInformation)}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="validationTooltip01"
                      >
                        First Name
                      </label>
                      <input
                        className="form-control"
                        id="validationTooltip01"
                        name="firstname"
                        maxLength={50}
                        placeholder="Firstname"
                        ref={register({
                          required: true,
                        })}
                        type="text"
                      />
                      {errors.firstname && <span>First Name is required</span>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="validationTooltip02"
                      >
                        Last Name
                      </label>
                      <input
                        className="form-control"
                        id="validationTooltip02"
                        name="lastname"
                        maxLength={50}
                        placeholder="Lastname"
                        ref={register({
                          required: true,
                        })}
                        type="text"
                      />
                      {errors.lastname && <span>Last Name is required</span>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="validationTooltip04"
                      >
                        Phone Number
                      </label>{" "}
                      <FormPhoneInput
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) => isValidPhoneNumber(value),
                        }}
                      />
                      {errors.phone && (
                        <span>A valid phone number is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="validationTooltip04"
                      >
                        Gender
                      </label>
                      <FormGenderSelect
                        control={control}
                        rules={{
                          required: true,
                          validate: (select) => select.value !== undefined,
                        }}
                      />
                      {errors.gender && <span>Your gender is required</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      className="col-form-label"
                      htmlFor="validationTooltip04"
                    >
                      Date Of Birth
                    </label>
                    <div className="form-row">
                      <FormDobSelect
                        control={control}
                        rules={{
                          required: true,
                          validate: (select) => select.value !== undefined,
                        }}
                      />
                      {(errors.dobDay || errors.dobMonth || errors.dobYear) && (
                        <span>Your date of birth is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="validationTooltip05"
                      >
                        Address
                      </label>
                      <input
                        className="form-control"
                        id="validationTooltip05"
                        name="street"
                        maxLength={150}
                        placeholder="Home Address"
                        ref={register({ required: true })}
                        type="text"
                      />
                      {errors.street && <span>Address is required</span>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="validationTooltip06"
                      >
                        City
                      </label>
                      <input
                        className="form-control"
                        htmlFor="validationTooltip07"
                        id="validationTooltip06"
                        name="city"
                        maxLength={150}
                        placeholder="City"
                        ref={register({ required: true })}
                        type="text"
                      />
                      {errors.city && <span>City is required</span>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="validationTooltip07"
                      >
                        Province
                      </label>
                      <FormProvinceSelect
                        control={control}
                        rules={{
                          required: true,
                          maxLength: 10,
                          validate: (select) => select.value !== undefined,
                        }}
                      />
                      {errors.province && <span>Province is required</span>}
                    </div>
                  </div>

                  <div className="col-sm-2 col-md-2">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="validationTooltip08"
                      >
                        Postal Code
                      </label>
                      <input
                        className="form-control"
                        id="validationCustom04"
                        name="postal"
                        placeholder="1A2 B3C"
                        ref={register({
                          required: true,
                          validate: (value) => {
                            var regex = new RegExp(
                              /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                            );
                            return regex.test(value) || "Invalid postal code.";
                          },
                        })}
                        type="text"
                      />
                      {errors.postal && (
                        <span>
                          {errors.postal.message !== ""
                            ? errors.postal.message
                            : "Required"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="validationTooltip08"
                      >
                        Suite
                      </label>
                      <input
                        className="form-control"
                        id="validationTooltip08"
                        name="suite"
                        maxLength={10}
                        placeholder="Suite"
                        ref={register({ required: false })}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-prev btn-success btn-lg pull-right"
                >
                  Save Edit
                </button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <button disabled={loading} onClick={toggleModal}>
        Edit Information
      </button>
    </React.Fragment>
  );
};

export default withRouter(EditCardInformation);
