import React from "react";
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import

import {
 CARD_ADD_DISCOUNT,
  USER_CARDS_QUERY,
  CARD_REQUEST_BYID_QUERY,
} from "../graphql";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const UpdateCreditCardDiscount = ({ card, discountType, title, component: button }) => {
  const [addDiscount, { loading }] = useMutation(CARD_ADD_DISCOUNT);
  const handleAddDiscount = async (discountType) => {
    try {
      await addDiscount({
        variables: {
          id: card.cardApplication.id,
          discountType: discountType,
        },
        refetchQueries: [
          {
            query: USER_CARDS_QUERY,
          },
          {
            query: CARD_REQUEST_BYID_QUERY,
            variables: {
              id: card.cardApplication.id,
            },
          },
        ],
      });
      if(discountType === "0"){
        toast.error("Discount removed");
      }else {
        toast.success(`${discountType === "2" ? "50%" : "20%"} Added Discount`);
      }
    } catch (error) {
      toast.error(error.message.replace("GraphQL error: ", ""));
    }
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleAddDiscount(discountType),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  return <button onClick={submit}>{title}</button>;
};

export default UpdateCreditCardDiscount;
